/*!

 =========================================================
 * Kaiadmin Bootstrap 5 Admin Dashboard (Bootstrap 5)
 =========================================================

 * Product Page: http://www.themekita.com/
 * Copyright 2019 Theme Kita (http://www.themekita.com/)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

[Table of contents]

* Background
* Typography
* Layouts
   + Body & Wrapper
   + Main Header
   + Sidebar
* Layouts Color
* Components
   + Breadcrumbs
   + Cards
     - Card Stats
     - Card Task
     - Card States
     - Card Posts
     - Card Pricing
     - Card Annoucement
     - Card Profile
     - Accordion
   + Inputs
   + Tables
   + Navbars
   + Navsearch
   + Badges
   + Dropdowns
   + Charts
   + Alerts
   + Buttons
   + Navtabs
   + Popovers
   + Progress
   + Paginations
   + Sliders
   + Modals
   + Timeline
   + Maps
   + Invoice
   + Messages
   + Tasks
   + Settings
* Plugins
   + jQueryUI
   + jQuery Scrollbar
   + Toggle
   + Css Animate
   + Full Calendar
   + SweetAlert
   + Datatables
   + DateTimePicker
   + Select2
   + Tagsinput
   + Dropzone
   + Summernote
* Responsive
* 404
* Login & Register

# [Color codes]

body-text-color: #575962
white-color: #ffffff
black-color: #191919
transparent-bg : transparent
default-color : #282a3c
primary-color : #177dff
secondary-color : #716aca
info-color : #36a3f7
success-color : #35cd3a
warning-color : #ffa534
danger-color : #f3545d

-------------------------------------------------------------------*/
.alert,
.brand,
.btn-simple,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.navbar,
.td-name,
a,
body,
button.close,
h1,
h2,
h3,
h4,
h5,
h6,
p,
td {
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   font-family: 'Public Sans', sans-serif
}

body,
html {
   font-size: 14px;
   color: #2a2f5b
}

a {
   color: #0033CC;
   text-decoration: none !important
}

a:focus,
a:hover {
   color: #1269db
}

.h1,
h1 {
   font-size: 2.15rem
}

.h2,
h2 {
   font-size: 1.85rem
}

.h3,
h3 {
   font-size: 1.64rem
}

.h4,
h4 {
   font-size: 1.48rem
}

.h5,
h5 {
   font-size: 1.3rem;
   font-weight: 400
}

.h6,
h6 {
   font-size: 1.07rem;
   font-weight: 400
}

p {
   font-size: 1.07rem;
   line-height: 1.82;
   margin-bottom: 1rem;
   word-break: break-word
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
   line-height: 1.5
}

.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a,
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
   color: inherit
}

.small,
small {
   font-size: 13px
}

.b,
.strong,
b,
strong {
   font-weight: 600
}

.page-pretitle {
   letter-spacing: .08em;
   text-transform: uppercase;
   color: #95aac9
}

.page-title {
   font-size: 23px;
   font-weight: 600;
   color: #444;
   line-height: 30px;
   margin-bottom: 20px
}

.page-category {
   color: #444;
   line-height: 1.8;
   margin-bottom: 25px
}

.text-primary,
.text-primary a {
   color: #0033CC !important
}

.text-primary a:hover,
.text-primary:hover {
   color: #0033CC !important
}

.text-secondary,
.text-secondary a {
   color: #6861ce !important
}

.text-secondary a:hover,
.text-secondary:hover {
   color: #6861ce !important
}

.text-info,
.text-info a {
   color: #48abf7 !important
}

.text-info a:hover,
.text-info:hover {
   color: #48abf7 !important
}

.text-success,
.text-success a {
   color: #196c1b !important
}

.text-success a:hover,
.text-success:hover {
   color: #196c1b !important
}

.text-warning,
.text-warning a {
   color: #ffad46 !important
}

.text-warning a:hover,
.text-warning:hover {
   color: #ffad46 !important
}

.text-danger,
.text-danger a {
   color: #f25961 !important
}

.text-danger a:hover,
.text-danger:hover {
   color: #f25961 !important
}

label {
   color: #495057 !important;
   font-size: 14px !important
}

.text-small {
   font-size: 11px
}

.metric-value {
   margin-bottom: 5px;
   line-height: 1;
   white-space: nowrap
}

.metric-label {
   font-size: .975rem;
   font-weight: 500;
   color: #686f76;
   white-space: nowrap;
   margin-bottom: 0
}

.fw-light {
   font-weight: 300 !important
}

.fw-normal {
   font-weight: 400 !important
}

.fw-mediumbold {
   font-weight: 500 !important
}

.fw-bold {
   font-weight: 600 !important
}

.fw-extrabold {
   font-weight: 700 !important
}

.op-9 {
   opacity: .9 !important
}

.op-8 {
   opacity: .8 !important
}

.op-7 {
   opacity: .7 !important
}

.op-6 {
   opacity: .6 !important
}

.op-5 {
   opacity: .5 !important
}

.op-4 {
   opacity: .4 !important
}

.op-3 {
   opacity: .3 !important
}

body {
   min-height: 100vh;
   position: relative;
   background: #f5f7fd;
   background-size: cover;
   background-attachment: fixed;
   background-repeat: no-repeat
}

.body-overlay {
   position: absolute;
   width: 100%;
   height: 100%;
   background: #000;
   opacity: .15;
   z-index: 2000;
   top: 0;
   left: 0
}

:focus {
   outline: 0 !important;
   -webkit-box-shadow: none !important;
   box-shadow: none !important
}

.border-bottom,
.border-left,
.border-right,
.border-top {
   border-color: #f0f1f3 !important
}

.no-box-shadow {
   box-shadow: none !important
}

.mt--5,
.my--5 {
   margin-top: -3rem !important
}

.mt--4,
.my--4 {
   margin-top: -1.5rem !important
}

.mt--3,
.my--3 {
   margin-top: -1rem !important
}

.mt--2,
.my--2 {
   margin-top: -.5rem !important
}

.mt--1,
.my--1 {
   margin-top: -.25rem !important
}

.pull-right {
   float: right
}

.pull-left {
   float: left
}

.wrapper {
   min-height: 100vh;
   position: relative;
   top: 0;
   height: 100vh
}

.flex-1 {
   -ms-flex: 1;
   flex: 1
}

.metric {
   display: flex;
   padding: 1rem;
   flex-direction: column
}

.no-box-shadow-style * {
   -webkit-box-shadow: none !important;
   -moz-box-shadow: none !important;
   box-shadow: none !important
}

.no-box-shadow-style .card,
.no-box-shadow-style .row-card-no-pd {
   border: 1px solid #eaeaea
}

.main-header {
   background: #fff;
   min-height: 60px;
   width: calc(100% - 250px);
   position: fixed;
   z-index: 1001
}

.main-header .navbar-header {
   min-height: 70px
}

.main-header .navbar-header .btn-toggle {
   margin-right: 30px;
   margin-left: 20px
}

#search-nav {
   flex: 1;
   max-width: 300px;
   transition: all .4s
}

#search-nav.focus {
   max-width: 400px
}

.main-panel {
   position: relative;
   width: calc(100% - 265px);
   height: 100vh;
   min-height: 100%;
   float: right;
   transition: all .3s
}

.main-panel>.container {
   min-height: calc(100% - 123px);
   margin-top: 69px;
   overflow: hidden;
   width: 100%;
   max-width: unset;
   padding: 0 !important
}

.main-panel>.container-full {
   padding: 0 !important;
   min-height: calc(100% - 123px);
   margin-top: 70px;
   overflow: hidden;
   width: 100%;
   max-width: unset
}

.main-panel .row {
   --bs-gutter-x: 30px
}

.main-panel .page-header {
   display: flex;
   align-items: center;
   margin-bottom: 20px
}

.main-panel .page-header .page-title {
   margin-bottom: 0
}

.main-panel .page-header .btn-page-header-dropdown {
   width: 35px;
   height: 35px;
   font-size: 1rem;
   padding: 0;
   color: #6b6b6b;
   box-shadow: 0 2px 14px 0 rgba(144, 116, 212, .1) !important;
   border: 0
}

.main-panel .page-header .btn-page-header-dropdown:after {
   display: none
}

.main-panel .page-header .dropdown-menu {
   margin-top: 15px;
   top: 0 !important
}

.main-panel .page-header .dropdown-menu:after {
   width: 0;
   height: 0;
   border-left: 8px solid transparent;
   border-right: 8px solid transparent;
   border-bottom: 8px solid #fff;
   position: absolute;
   top: -8px;
   right: 32px;
   content: ''
}

.main-panel .page-divider {
   height: 0;
   margin: .3rem 0 1rem;
   overflow: hidden;
   border-top: 1px solid #ebecec
}

.page-wrapper {
   min-height: calc(100vh - 57px);
   position: relative
}

.page-wrapper.has-sidebar .page-inner {
   margin-left: 22.5rem
}

.page-navs {
   position: relative;
   display: block;
   padding-right: 1rem;
   padding-left: 1rem;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .07);
   z-index: 1
}

.page-navs .nav .nav-link {
   padding: 1rem !important
}

.page-navs .nav-line {
   border: 0 !important
}

.page-navs .nav-line .nav-link {
   border-bottom-width: 3px !important
}

.nav-scroller {
   overflow-x: auto;
   overflow-y: hidden
}

.nav-scroller .nav {
   flex-wrap: nowrap;
   white-space: nowrap
}

@media (min-width:991px) {
   .main-panel>.container-full {
      padding: 24px !important
   }

   .page-wrapper {
      overflow: hidden
   }

   .page-navs {
      padding-right: 2rem;
      padding-left: 2rem
   }
}

.page-inner {
   padding: 24px 0
}

@media (min-width:576px) {
   .page-inner {
      padding-right: 30px;
      padding-left: 30px
   }
}

.page-inner-fill {
   padding: 0;
   height: calc(100% - 57px);
   display: flex;
   flex-direction: column
}

.page-sidebar {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   display: flex;
   flex-direction: column;
   max-width: 22.5rem;
   box-shadow: none;
   transform: translate3d(100%, 0, 0);
   overflow: auto;
   z-index: 2;
   transition: transform .2s ease-in-out;
   border-right: 1px solid rgba(61, 70, 79, .125) !important
}

.page-sidebar .back {
   width: 100%;
   display: flex;
   align-items: center;
   padding: 1rem;
   box-shadow: 0 0 0 1px rgba(61, 70, 79, .05), 0 1px 3px 0 rgba(61, 70, 79, .15);
   font-size: 15px
}

.page-sidebar-section {
   flex: 1;
   overflow-y: auto
}

@media (min-width:1200.5px) {
   .page-sidebar {
      transform: translateZ(0);
      left: 0
   }
}

@media (max-width:1200.5px) {
   .page-wrapper.has-sidebar .page-inner {
      margin-left: 0
   }

   .pagesidebar_open .page-sidebar {
      transform: translate3d(0, 0, 0) !important;
      max-width: unset
   }
}

.page-with-aside {
   display: flex
}

.page-with-aside .page-aside {
   width: 280px;
   min-height: 100vh;
   border-right: 1px solid #f1f1f1;
   padding: 15px 0
}

.page-with-aside .page-aside .aside-header {
   padding: 15px 22px
}

.page-with-aside .page-aside .aside-header .title {
   font-size: 24px
}

.page-with-aside .page-aside .aside-header .description {
   font-size: 12px
}

.page-with-aside .page-aside .aside-nav .nav {
   flex-direction: column
}

.page-with-aside .page-aside .aside-nav .nav>li {
   padding: 8px 22px;
   margin-bottom: 5px
}

.page-with-aside .page-aside .aside-nav .nav>li.active,
.page-with-aside .page-aside .aside-nav .nav>li:focus,
.page-with-aside .page-aside .aside-nav .nav>li:hover {
   background: rgba(51, 51, 51, .08)
}

.page-with-aside .page-aside .aside-nav .nav>li.active {
   padding: 12px 22px;
   font-weight: 600
}

.page-with-aside .page-aside .aside-nav .nav>li.active>a {
   color: #575962 !important
}

.page-with-aside .page-aside .aside-nav .nav>li>a {
   color: #83848a;
   display: flex;
   align-items: center;
   font-size: 12px
}

.page-with-aside .page-aside .aside-nav .nav>li>a:focus,
.page-with-aside .page-aside .aside-nav .nav>li>a:hover {
   text-decoration: none
}

.page-with-aside .page-aside .aside-nav .nav>li>a i {
   font-size: 20px;
   margin-right: 15px;
   color: #a1a2a6
}

.page-with-aside .page-aside .aside-nav .label {
   padding: 5px 22px;
   margin-top: 22px;
   margin-bottom: 5px;
   display: block
}

.page-with-aside .page-aside .aside-compose {
   padding: 25px 22px
}

.page-with-aside .page-content {
   width: calc(100% - 280px)
}

.footer {
   border-top: 1px solid #eee;
   padding: 15px;
   background: #fff;
   position: absolute;
   width: 100%
}

.footer .container,
.footer .container-fluid {
   display: flex;
   align-items: center
}

.sidebar,
.sidebar[data-background-color=white] {
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   width: 265px;
   display: block;
   z-index: 1002;
   color: #fff;
   font-weight: 200;
   background: #fff;
   -webkit-box-shadow: 4px 4px 10px rgba(69, 65, 78, .06);
   -moz-box-shadow: 4px 4px 10px rgba(69, 65, 78, .06);
   box-shadow: 4px 4px 10px rgba(69, 65, 78, .06);
   transition: all .3s
}

.sidebar .user,
.sidebar[data-background-color=white] .user {
   padding-top: 15px;
   padding-left: 15px;
   padding-right: 15px;
   padding-bottom: 15px;
   border-top: 1px solid #f1f1f1;
   border-bottom: 1px solid #f1f1f1;
   display: block;
   margin-left: 15px;
   margin-right: 15px
}

.sidebar .user .info a,
.sidebar[data-background-color=white] .user .info a {
   white-space: nowrap;
   display: block;
   position: relative
}

.sidebar .user .info a:focus,
.sidebar .user .info a:hover,
.sidebar[data-background-color=white] .user .info a:focus,
.sidebar[data-background-color=white] .user .info a:hover {
   text-decoration: none
}

.sidebar .user .info a>span,
.sidebar[data-background-color=white] .user .info a>span {
   font-size: 1rem;
   font-weight: 400;
   color: #777;
   display: flex;
   flex-direction: column
}

.sidebar .user .info a>span .user-level,
.sidebar[data-background-color=white] .user .info a>span .user-level {
   color: #555;
   font-weight: 600;
   font-size: 12px;
   margin-top: 5px
}

.sidebar .user .info a .link-collapse,
.sidebar[data-background-color=white] .user .info a .link-collapse {
   padding: 7px 0
}

.sidebar .user .info .caret,
.sidebar[data-background-color=white] .user .info .caret {
   position: absolute;
   top: 17px;
   right: 0;
   border-top-color: #777
}

.sidebar .sidebar-wrapper,
.sidebar[data-background-color=white] .sidebar-wrapper {
   position: relative;
   max-height: calc(100vh - 75px);
   min-height: 100%;
   overflow: hidden;
   width: 100%;
   z-index: 4;
   transition: all .3s
}

.sidebar .sidebar-wrapper .sidebar-content,
.sidebar[data-background-color=white] .sidebar-wrapper .sidebar-content {
   padding-top: 0;
   padding-bottom: 0px
}

.sidebar .sidebar-wrapper .scroll-element.scroll-y,
.sidebar[data-background-color=white] .sidebar-wrapper .scroll-element.scroll-y {
   top: 5px !important
}

.sidebar .nav,
.sidebar[data-background-color=white] .nav {
   display: block;
   float: none;
   margin-top: 20px
}

.sidebar .nav .nav-section,
.sidebar[data-background-color=white] .nav .nav-section {
   margin: 15px 0 0 0
}

.sidebar .nav .nav-section .sidebar-mini-icon,
.sidebar[data-background-color=white] .nav .nav-section .sidebar-mini-icon {
   text-align: center;
   font-size: 15px;
   color: #909093;
   display: none
}

.sidebar .nav .nav-section .text-section,
.sidebar[data-background-color=white] .nav .nav-section .text-section {
   padding: 2px 30px;
   font-size: 12px;
   color: #727275;
   font-weight: 600;
   text-transform: uppercase;
   letter-spacing: .5px;
   margin-bottom: 12px;
   margin-top: 20px
}

.sidebar .nav>.nav-item,
.sidebar[data-background-color=white] .nav>.nav-item {
   display: list-item
}

.sidebar .nav>.nav-item.active>a,
.sidebar[data-background-color=white] .nav>.nav-item.active>a {
   background: rgba(0, 0, 0, .03);
   color: #2a2f5b !important
}

.sidebar .nav>.nav-item.active>a:before,
.sidebar[data-background-color=white] .nav>.nav-item.active>a:before {
   background: #1d7af3
}

.sidebar .nav>.nav-item.active>a p,
.sidebar[data-background-color=white] .nav>.nav-item.active>a p {
   color: #2a2f5b !important;
   font-weight: 600
}

.sidebar .nav>.nav-item.active:hover>a:before,
.sidebar[data-background-color=white] .nav>.nav-item.active:hover>a:before {
   background: #1d7af3
}

.sidebar .nav>.nav-item.active a i,
.sidebar[data-background-color=white] .nav>.nav-item.active a i {
   color: #4d7cfe
}

.sidebar .nav>.nav-item.submenu,
.sidebar[data-background-color=white] .nav>.nav-item.submenu {
   background: rgba(0, 0, 0, .03)
}

.sidebar .nav>.nav-item.submenu>li>a i,
.sidebar[data-background-color=white] .nav>.nav-item.submenu>li>a i {
   color: rgba(23, 125, 255, .76)
}

.sidebar .nav>.nav-item a,
.sidebar[data-background-color=white] .nav>.nav-item a {
   display: flex;
   align-items: center;
   color: #575962;
   padding: 3px 25px;
   width: 100%;
   font-size: 1rem;
   font-weight: 400;
   position: relative;
   margin-bottom: 3px;
   text-decoration: none !important
}

.sidebar .nav>.nav-item a:focus,
.sidebar .nav>.nav-item a:hover,
.sidebar[data-background-color=white] .nav>.nav-item a:focus,
.sidebar[data-background-color=white] .nav>.nav-item a:hover {
   text-decoration: none
}

.sidebar .nav>.nav-item a:focus p,
.sidebar .nav>.nav-item a:hover p,
.sidebar[data-background-color=white] .nav>.nav-item a:focus p,
.sidebar[data-background-color=white] .nav>.nav-item a:hover p {
   color: #575962 !important;
   font-weight: 600
}

.sidebar .nav>.nav-item a:focus i,
.sidebar .nav>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav>.nav-item a:hover i {
   color: #4d7cfe !important
}

.sidebar .nav>.nav-item a .letter-icon,
.sidebar[data-background-color=white] .nav>.nav-item a .letter-icon {
   color: #a1a2a6;
   margin-right: 15px;
   width: 25px;
   text-align: center;
   vertical-align: middle;
   float: left;
   font-size: 20px;
   font-weight: 200
}

.sidebar .nav>.nav-item a i,
.sidebar[data-background-color=white] .nav>.nav-item a i {
   color: #8d9498;
   margin-right: 15px;
   width: 25px;
   text-align: center;
   vertical-align: middle;
   float: left;
   font-size: 16px;
   line-height: 30px
}

.sidebar .nav>.nav-item a i[class^=flaticon-],
.sidebar[data-background-color=white] .nav>.nav-item a i[class^=flaticon-] {
   font-size: 20px
}

.sidebar .nav>.nav-item a p,
.sidebar[data-background-color=white] .nav>.nav-item a p {
   font-size: 1rem;
   margin-bottom: 0;
   margin-right: 5px;
   white-space: nowrap;
   font-weight: 600
}

.sidebar .nav>.nav-item a .caret,
.sidebar[data-background-color=white] .nav>.nav-item a .caret {
   margin-left: auto;
   margin-right: 6px;
   transition: all .5s;
   color: #8d9498
}

.sidebar .nav>.nav-item a[data-toggle=collapse][aria-expanded=true],
.sidebar[data-background-color=white] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] {
   background: 0 0
}

.sidebar .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] p,
.sidebar[data-background-color=white] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] p {
   color: #575962
}

.sidebar .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #4d7cfe
}

.sidebar .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] .caret,
.sidebar[data-background-color=white] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] .caret {
   -webkit-transform: rotate(-180deg);
   transform: rotate(-180deg)
}

.sidebar .nav>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #1d7af3
}

.sidebar .nav.nav-primary>.nav-item a:focus i,
.sidebar .nav.nav-primary>.nav-item a:hover i,
.sidebar .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #0033CC !important
}

.sidebar .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #0033CC !important
}

.sidebar .nav.nav-primary>.nav-item.active a:before,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item.active a:before {
   background: #0033CC !important
}

.sidebar .nav.nav-primary>.nav-item.active a i,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item.active a i {
   color: #0033CC !important
}

.sidebar .nav.nav-primary>.nav-item .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav.nav-primary>.nav-item .nav-collapse li.active>a {
   color: #0033CC !important
}

.sidebar .nav.nav-secondary>.nav-item a:focus i,
.sidebar .nav.nav-secondary>.nav-item a:hover i,
.sidebar .nav.nav-secondary>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #6861ce !important
}

.sidebar .nav.nav-secondary>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #6861ce !important
}

.sidebar .nav.nav-secondary>.nav-item.active a:before,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item.active a:before {
   background: #6861ce !important
}

.sidebar .nav.nav-secondary>.nav-item.active a i,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item.active a i {
   color: #6861ce !important
}

.sidebar .nav.nav-secondary>.nav-item .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav.nav-secondary>.nav-item .nav-collapse li.active>a {
   color: #6861ce !important
}

.sidebar .nav.nav-info>.nav-item a:focus i,
.sidebar .nav.nav-info>.nav-item a:hover i,
.sidebar .nav.nav-info>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #48abf7 !important
}

.sidebar .nav.nav-info>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #48abf7 !important
}

.sidebar .nav.nav-info>.nav-item.active a:before,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item.active a:before {
   background: #48abf7 !important
}

.sidebar .nav.nav-info>.nav-item.active a i,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item.active a i {
   color: #48abf7 !important
}

.sidebar .nav.nav-info>.nav-item .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav.nav-info>.nav-item .nav-collapse li.active>a {
   color: #48abf7 !important
}

.sidebar .nav.nav-success>.nav-item a:focus i,
.sidebar .nav.nav-success>.nav-item a:hover i,
.sidebar .nav.nav-success>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #196c1b !important
}

.sidebar .nav.nav-success>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #196c1b !important
}

.sidebar .nav.nav-success>.nav-item.active a:before,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item.active a:before {
   background: #196c1b !important
}

.sidebar .nav.nav-success>.nav-item.active a i,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item.active a i {
   color: #196c1b !important
}

.sidebar .nav.nav-success>.nav-item .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav.nav-success>.nav-item .nav-collapse li.active>a {
   color: #196c1b !important
}

.sidebar .nav.nav-warning>.nav-item a:focus i,
.sidebar .nav.nav-warning>.nav-item a:hover i,
.sidebar .nav.nav-warning>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #ffad46 !important
}

.sidebar .nav.nav-warning>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #ffad46 !important
}

.sidebar .nav.nav-warning>.nav-item.active a:before,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item.active a:before {
   background: #ffad46 !important
}

.sidebar .nav.nav-warning>.nav-item.active a i,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item.active a i {
   color: #ffad46 !important
}

.sidebar .nav.nav-warning>.nav-item .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav.nav-warning>.nav-item .nav-collapse li.active>a {
   color: #ffad46 !important
}

.sidebar .nav.nav-danger>.nav-item a:focus i,
.sidebar .nav.nav-danger>.nav-item a:hover i,
.sidebar .nav.nav-danger>.nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item a:focus i,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item a:hover i,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item a[data-toggle=collapse][aria-expanded=true] i {
   color: #f25961 !important
}

.sidebar .nav.nav-danger>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: #f25961 !important
}

.sidebar .nav.nav-danger>.nav-item.active a:before,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item.active a:before {
   background: #f25961 !important
}

.sidebar .nav.nav-danger>.nav-item.active a i,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item.active a i {
   color: #f25961 !important
}

.sidebar .nav.nav-danger>.nav-item .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav.nav-danger>.nav-item .nav-collapse li.active>a {
   color: #f25961 !important
}

.sidebar .nav>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar .nav>.nav-item.active:hover>a:before,
.sidebar .nav>.nav-item.active>a:before,
.sidebar[data-background-color=white] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true]:before,
.sidebar[data-background-color=white] .nav>.nav-item.active:hover>a:before,
.sidebar[data-background-color=white] .nav>.nav-item.active>a:before {
   opacity: 1 !important;
   position: absolute;
   z-index: 1;
   width: 2px;
   height: 100%;
   content: '';
   left: 0;
   top: 0
}

.sidebar .nav-collapse,
.sidebar[data-background-color=white] .nav-collapse {
   margin-top: 0;
   margin-bottom: 15px;
   padding-bottom: 15px;
   padding-top: 10px
}

.sidebar .nav-collapse li.active>a,
.sidebar[data-background-color=white] .nav-collapse li.active>a {
   font-weight: 600
}

.sidebar .nav-collapse li a:before,
.sidebar .nav-collapse li a:hover:before,
.sidebar[data-background-color=white] .nav-collapse li a:before,
.sidebar[data-background-color=white] .nav-collapse li a:hover:before {
   opacity: 0 !important
}

.sidebar .nav-collapse li a,
.sidebar[data-background-color=white] .nav-collapse li a {
   margin-bottom: 3px !important;
   padding: 10px 25px !important
}

.sidebar .nav-collapse li a .sub-item,
.sidebar[data-background-color=white] .nav-collapse li a .sub-item {
   font-size: 1rem;
   position: relative;
   margin-left: 25px;
   opacity: .85
}

.sidebar .nav-collapse li a .sub-item:before,
.sidebar[data-background-color=white] .nav-collapse li a .sub-item:before {
   content: '';
   height: 4px;
   width: 4px;
   background: rgba(131, 132, 138, .89);
   position: absolute;
   left: -15px;
   top: 50%;
   transform: translateY(-50%);
   border-radius: 100%
}

.sidebar .nav-collapse li a:hover .sub-item,
.sidebar[data-background-color=white] .nav-collapse li a:hover .sub-item {
   opacity: 1
}

.sidebar .nav-collapse li a .sidebar-mini-icon,
.sidebar[data-background-color=white] .nav-collapse li a .sidebar-mini-icon {
   font-size: 16px;
   color: #c3c5ca;
   margin-right: 15px;
   width: 25px;
   text-align: center;
   vertical-align: middle;
   float: left;
   font-weight: 300 !important
}

.sidebar .nav-collapse.subnav,
.sidebar[data-background-color=white] .nav-collapse.subnav {
   padding-bottom: 10px;
   margin-bottom: 0
}

.sidebar .nav-collapse.subnav li a,
.sidebar[data-background-color=white] .nav-collapse.subnav li a {
   padding-left: 40px !important
}

.sidebar .profile-section .border-bottom,
.sidebar .profile-section .border-left,
.sidebar .profile-section .border-right,
.sidebar .profile-section .border-top,
.sidebar[data-background-color=white] .profile-section .border-bottom,
.sidebar[data-background-color=white] .profile-section .border-left,
.sidebar[data-background-color=white] .profile-section .border-right,
.sidebar[data-background-color=white] .profile-section .border-top {
   border-color: rgba(255, 255, 255, .3) !important
}

.sidebar .user-profile .user-name,
.sidebar[data-background-color=white] .user-profile .user-name {
   font-size: 16px
}

.sidebar .user-profile .user-level,
.sidebar[data-background-color=white] .user-profile .user-level {
   font-size: 13px
}

.sidebar .menubar,
.sidebar[data-background-color=white] .menubar {
   color: #fff;
   line-height: 80px;
   font-size: 18px;
   opacity: .9
}

.sidebar[data-background-color=dark2] .nav .nav-item.submenu,
.sidebar[data-background-color=dark2] .nav .nav-item>a:focus,
.sidebar[data-background-color=dark2] .nav .nav-item>a:hover,
.sidebar[data-background-color=dark] .nav .nav-item.submenu,
.sidebar[data-background-color=dark] .nav .nav-item>a:focus,
.sidebar[data-background-color=dark] .nav .nav-item>a:hover {
   background: rgba(0, 0, 0, .16)
}

.sidebar[data-background-color=dark2] .nav .nav-item.submenu>a,
.sidebar[data-background-color=dark] .nav .nav-item.submenu>a {
   background: 0 0 !important
}

.sidebar[data-background-color=dark2] .nav .nav-item.active>a,
.sidebar[data-background-color=dark] .nav .nav-item.active>a {
   background: rgba(0, 0, 0, .16)
}

.logo-header {
   float: left;
   width: 265px;
   height: 70px;
   line-height: 60px;
   color: #333;
   z-index: 1001;
   font-size: 17px;
   font-weight: 400;
   padding-left: 25px;
   padding-right: 25px;
   z-index: 1001;
   display: flex;
   align-items: center;
   position: relative;
   transition: all .3s
}

.logo-header .big-logo {
   margin-right: 8px
}

.logo-header .big-logo:hover {
   text-decoration: none
}

.logo-header .big-logo .logo-img {
   width: 35px;
   height: 35px
}

.logo-header .logo {
   color: #2a2f5b;
   opacity: 1;
   position: relative;
   height: 100%;
   display: flex;
   align-items: center
}

.logo-header .logo:hover {
   text-decoration: none
}

.logo-header .logo .navbar-brand {
   padding-top: 0;
   padding-bottom: 0;
   margin-right: 0
}

.logo-header .nav-toggle {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100%;
   position: absolute;
   top: 0;
   right: 18px;
   z-index: 5
}

.logo-header .navbar-toggler {
   padding-left: 0;
   padding-right: 0;
   opacity: 0;
   display: none
}

.logo-header .navbar-toggler .navbar-toggler-icon {
   height: unset;
   width: unset;
   display: block;
   color: #545454;
   font-size: 22px
}

.logo-header .more {
   background: 0 0;
   border: 0;
   font-size: 22px;
   padding: 0;
   opacity: 0;
   width: 0;
   display: none
}

.btn-toggle {
   font-size: 20px !important;
   line-height: 20px;
   padding: 0 !important;
   background: 0 0 !important;
   color: #2a2f5b !important
}

.btn-toggle:focus,
.btn-toggle:hover {
   opacity: 1
}

.sidebar.sidebar-style-2 .nav .nav-item {
   padding: 0 15px
}

.sidebar.sidebar-style-2 .nav .nav-item a {
   padding: 8px 10px;
   border-radius: 10px
}

.sidebar.sidebar-style-2 .nav .nav-item a:focus,
.sidebar.sidebar-style-2 .nav .nav-item a:hover,
.sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] {
   background: rgba(199, 199, 199, .2)
}

.sidebar.sidebar-style-2 .nav .nav-item a:focus i,
.sidebar.sidebar-style-2 .nav .nav-item a:focus p,
.sidebar.sidebar-style-2 .nav .nav-item a:hover i,
.sidebar.sidebar-style-2 .nav .nav-item a:hover p,
.sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p {
   color: #575962 !important
}

.sidebar.sidebar-style-2 .nav .nav-item.active a:before {
   background: 0 0
}

.sidebar.sidebar-style-2 .nav .nav-item .active a {
   background: rgba(199, 199, 199, .2)
}

.sidebar.sidebar-style-2 .nav .nav-item .active a i,
.sidebar.sidebar-style-2 .nav .nav-item .active a p {
   color: #575962 !important
}

.sidebar.sidebar-style-2 .nav .nav-item.submenu {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav .nav-item a[data-toggle=collapse][aria-expanded=true]:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a {
   background: #0033CC !important;
   box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .1), 4px 4px 15px -5px rgba(21, 114, 232, .4)
}

.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a .caret,
.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a i,
.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a p,
.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a span {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-primary>.nav-item.active>a[data-toggle=collapse][aria-expanded=true] i {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a {
   background: #6861ce !important;
   box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .1), 4px 4px 15px -5px rgba(104, 97, 206, .4)
}

.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a .caret,
.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a i,
.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a p,
.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a span {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-secondary>.nav-item.active>a[data-toggle=collapse][aria-expanded=true] i {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a {
   background: #48abf7 !important;
   box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .1), 4px 4px 15px -5px rgba(72, 171, 247, .4)
}

.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a .caret,
.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a i,
.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a p,
.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a span {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-info>.nav-item.active>a[data-toggle=collapse][aria-expanded=true] i {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a {
   background: #196c1b !important;
   box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .1), 4px 4px 15px -5px rgba(49, 206, 54, .4)
}

.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a .caret,
.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a i,
.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a p,
.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a span {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-success>.nav-item.active>a[data-toggle=collapse][aria-expanded=true] i {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a {
   background: #ffad46 !important;
   box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .1), 4px 4px 15px -5px rgba(255, 173, 70, .4)
}

.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a .caret,
.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a i,
.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a p,
.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a span {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-warning>.nav-item.active>a[data-toggle=collapse][aria-expanded=true] i {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a {
   background: #f25961 !important;
   box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, .1), 4px 4px 15px -5px rgba(242, 89, 97, .4)
}

.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a:before {
   background: 0 0 !important
}

.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a .caret,
.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a i,
.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a p,
.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a span {
   color: #fff !important
}

.sidebar.sidebar-style-2 .nav.nav-danger>.nav-item.active>a[data-toggle=collapse][aria-expanded=true] i {
   color: #fff !important
}

.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item a:focus i,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item a:focus p,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item a:hover i,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item a:hover p,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item a:focus i,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item a:focus p,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item a:hover i,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item a:hover p,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] i,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item a[data-toggle=collapse][aria-expanded=true] p {
   color: #b9babf !important
}

.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a {
   color: #fff
}

.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a .caret,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a i,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a p,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a span,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a .caret,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a i,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a p,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a span {
   color: #fff
}

.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] .caret,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] i,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] p,
.sidebar.sidebar-style-2[data-background-color=dark2] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] span,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] .caret,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] i,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] p,
.sidebar.sidebar-style-2[data-background-color=dark] .nav .nav-item.active a[data-toggle=collapse][aria-expanded=true] span {
   color: #fff
}

@media screen and (min-width:992px) {

   .sidebar_minimize .main-header,
   .sidebar_minimize .main-panel {
      width: calc(100% - 75px);
      transition: all .3s
   }

   .sidebar_minimize .logo-header {
      width: 75px;
      transition: all .3s;
      padding: 0;
      text-align: center
   }

   .sidebar_minimize .logo-header .big-logo {
      margin-right: 0
   }

   .sidebar_minimize .logo-header .logo {
      position: absolute;
      transform: translate3d(25px, 0, 0);
      opacity: 0
   }

   .sidebar_minimize .logo-header .logo img {
      display: none
   }

   .sidebar_minimize .logo-header .nav-toggle {
      position: absolute;
      left: 50% !important;
      transform: translateX(-50%);
      height: 100%;
      right: 0 !important
   }

   .sidebar_minimize .sidebar {
      width: 75px;
      transition: all .3s
   }

   .sidebar_minimize .sidebar .sidebar-wrapper {
      width: 75px;
      transition: all .3s
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .user {
      padding-left: 0;
      padding-right: 0
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .user [class^=avatar-] {
      float: none !important
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .user .info {
      display: none
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .user .info span {
      display: none
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item {
      position: relative
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .letter-icon {
      display: block !important
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a i {
      margin-right: unset
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .badge,
   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .caret,
   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a p,
   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a span {
      display: none;
      transition: all .3s
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item a .sidebar-mini-icon {
      display: block !important;
      margin-right: 0
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item.active .nav-collapse,
   .sidebar_minimize .sidebar .sidebar-wrapper .nav-item.submenu .nav-collapse {
      display: none
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-section .text-section {
      display: none
   }

   .sidebar_minimize .sidebar .sidebar-wrapper .nav-section .sidebar-mini-icon {
      display: block
   }

   .sidebar_minimize .sidebar .user-profile .user-level,
   .sidebar_minimize .sidebar .user-profile .user-name {
      display: none
   }

   .sidebar_minimize .sidebar .user-profile .avatar {
      display: none
   }

   .sidebar_minimize .sidebar .user-profile .avatar.avatar-minimize {
      display: block !important
   }

   .sidebar_minimize .sidebar .menubars {
      display: none
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar {
      width: 265px;
      position: fixed;
      height: 100vh;
      top: 0
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .logo-header {
      width: 265px;
      padding-left: 25px;
      padding-right: 25px;
      text-align: left
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .logo-header .logo {
      opacity: 1 !important;
      transform: translate3d(0, 0, 0) !important;
      position: relative !important
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .logo-header .logo img {
      display: inline-block !important
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .logo-header .nav-toggle {
      right: 18px !important;
      transform: translateX(0) !important;
      left: unset !important
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper {
      width: 265px
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .user {
      padding-left: 15px;
      padding-right: 15px
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .user [class^=avatar-] {
      float: left !important
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .user .info {
      display: block
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .user .info span {
      display: flex
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item a i {
      margin-right: 15px
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item a .badge,
   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item a .caret,
   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item a p,
   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item a span {
      display: block
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item a .sidebar-mini-icon {
      display: block !important;
      margin-right: 15px
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item.active .nav-collapse,
   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-item.submenu .nav-collapse {
      display: block
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-section .sidebar-mini-icon {
      display: none
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .sidebar-wrapper .nav-section .text-section {
      display: block
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .user-profile .user-level,
   .sidebar_minimize.sidebar_minimize_hover .sidebar .user-profile .user-name {
      display: block
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .user-profile .avatar {
      display: block
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .user-profile .avatar.avatar-minimize {
      display: none !important
   }

   .sidebar_minimize.sidebar_minimize_hover .sidebar .menubars {
      display: flex
   }
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group {
   border: 0;
   background: rgba(0, 0, 0, .18) !important;
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .05);
   transition: all .4s
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group .form-control,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group .form-control {
   color: #fff !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group .form-control::-webkit-input-placeholder,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group .form-control::-webkit-input-placeholder {
   opacity: 1
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group .form-control::-moz-placeholder,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group .form-control::-moz-placeholder {
   opacity: 1
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group .form-control:-ms-input-placeholder,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group .form-control:-ms-input-placeholder {
   opacity: 1
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group .form-control:-moz-placeholder,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group .form-control:-moz-placeholder {
   opacity: 1
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .nav-search .input-group .search-icon,
.main-header[data-background-color=custom] .navbar-header .nav-search .input-group .search-icon {
   color: #fff !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group {
   background: #fff !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group .form-control,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group .form-control {
   color: inherit !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group .form-control::-webkit-input-placeholder,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group .form-control::-webkit-input-placeholder {
   color: #bfbfbf !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group .form-control::-moz-placeholder,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group .form-control::-moz-placeholder {
   color: #bfbfbf !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group .form-control:-ms-input-placeholder,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group .form-control:-ms-input-placeholder {
   color: #bfbfbf !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group .form-control:-moz-placeholder,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group .form-control:-moz-placeholder {
   color: #bfbfbf !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) #search-nav.focus .nav-search .input-group .search-icon,
.main-header[data-background-color=custom] .navbar-header #search-nav.focus .nav-search .input-group .search-icon {
   color: #bfbfbf !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .navbar-nav .nav-item .nav-link,
.main-header[data-background-color=custom] .navbar-header .navbar-nav .nav-item .nav-link {
   color: #fff !important
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .navbar-nav .nav-item .nav-link:focus,
.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .navbar-nav .nav-item .nav-link:hover,
.main-header[data-background-color=custom] .navbar-header .navbar-nav .nav-item .nav-link:focus,
.main-header[data-background-color=custom] .navbar-header .navbar-nav .nav-item .nav-link:hover {
   background: rgba(31, 30, 30, .12)
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .navbar-nav .nav-item.active .nav-link,
.main-header[data-background-color=custom] .navbar-header .navbar-nav .nav-item.active .nav-link {
   background: rgba(31, 30, 30, .12)
}

.main-header .navbar-header[data-background-color]:not([data-background-color=white]) .navbar-nav .topbar-user .profile-pic,
.main-header[data-background-color=custom] .navbar-header .navbar-nav .topbar-user .profile-pic {
   color: #fff
}

body[data-background-color=dark] .border-bottom,
body[data-background-color=dark] .border-left,
body[data-background-color=dark] .border-right,
body[data-background-color=dark] .border-top {
   border-color: rgba(181, 181, 181, .1) !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .dropdown-menu,
.main-header .navbar-header[data-background-color][data-background-color=dark] .dropdown-menu {
   background: #202940 !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .dropdown-menu:after,
.main-header .navbar-header[data-background-color][data-background-color=dark] .dropdown-menu:after {
   border-bottom-color: #202940
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .dropdown-menu .dropdown-title,
.main-header .navbar-header[data-background-color][data-background-color=dark] .dropdown-menu .dropdown-title {
   border-color: rgba(181, 181, 181, .1) !important;
   color: #fff
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .dropdown-menu .dropdown-divider,
.main-header .navbar-header[data-background-color][data-background-color=dark] .dropdown-menu .dropdown-divider {
   border-color: rgba(181, 181, 181, .1) !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .dropdown-menu .dropdown-item,
.main-header .navbar-header[data-background-color][data-background-color=dark] .dropdown-menu .dropdown-item {
   color: #fff
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .dropdown-menu .dropdown-item:hover,
.main-header .navbar-header[data-background-color][data-background-color=dark] .dropdown-menu .dropdown-item:hover {
   background: rgba(0, 0, 0, .1) !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .messages-notif-box .notif-center a,
.main-header .navbar-header[data-background-color][data-background-color=dark2] .messages-notif-box .see-all,
.main-header .navbar-header[data-background-color][data-background-color=dark2] .notif-box .see-all,
.main-header .navbar-header[data-background-color][data-background-color=dark] .messages-notif-box .notif-center a,
.main-header .navbar-header[data-background-color][data-background-color=dark] .messages-notif-box .see-all,
.main-header .navbar-header[data-background-color][data-background-color=dark] .notif-box .see-all {
   border-color: rgba(181, 181, 181, .1) !important;
   color: #fff
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .messages-notif-box .notif-center a,
.main-header .navbar-header[data-background-color][data-background-color=dark2] .notif-box .notif-center a,
.main-header .navbar-header[data-background-color][data-background-color=dark] .messages-notif-box .notif-center a,
.main-header .navbar-header[data-background-color][data-background-color=dark] .notif-box .notif-center a {
   color: #fff
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .messages-notif-box .notif-center a:hover,
.main-header .navbar-header[data-background-color][data-background-color=dark2] .notif-box .notif-center a:hover,
.main-header .navbar-header[data-background-color][data-background-color=dark] .messages-notif-box .notif-center a:hover,
.main-header .navbar-header[data-background-color][data-background-color=dark] .notif-box .notif-center a:hover {
   background: rgba(0, 0, 0, .1) !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .messages-notif-box .notif-center a .notif-content .time,
.main-header .navbar-header[data-background-color][data-background-color=dark2] .notif-box .notif-center a .notif-content .time,
.main-header .navbar-header[data-background-color][data-background-color=dark] .messages-notif-box .notif-center a .notif-content .time,
.main-header .navbar-header[data-background-color][data-background-color=dark] .notif-box .notif-center a .notif-content .time {
   color: #b9babf !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .scrollbar-outer>.scroll-element,
.main-header .navbar-header[data-background-color][data-background-color=dark] .scrollbar-outer>.scroll-element {
   background: 0 0
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .topbar-user .profile-pic,
.main-header .navbar-header[data-background-color][data-background-color=dark] .topbar-user .profile-pic {
   color: #fff !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .nav-search .input-group,
.main-header .navbar-header[data-background-color][data-background-color=dark] .nav-search .input-group {
   background: rgba(185, 185, 185, .18) !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .navbar-nav .nav-item .nav-link:focus,
.main-header .navbar-header[data-background-color][data-background-color=dark2] .navbar-nav .nav-item .nav-link:hover,
.main-header .navbar-header[data-background-color][data-background-color=dark] .navbar-nav .nav-item .nav-link:focus,
.main-header .navbar-header[data-background-color][data-background-color=dark] .navbar-nav .nav-item .nav-link:hover {
   background: rgba(185, 185, 185, .18) !important
}

.main-header .navbar-header[data-background-color][data-background-color=dark2] .navbar-nav .nav-item.active .nav-link,
.main-header .navbar-header[data-background-color][data-background-color=dark] .navbar-nav .nav-item.active .nav-link {
   background: rgba(185, 185, 185, .18) !important
}

.btn-toggle {
   color: #fff !important
}

.logo-header .btn-toggle,
.logo-header .more,
.logo-header .navbar-toggler .navbar-toggler-icon {
   color: #8d9498 !important
}

.logo-header[data-background-color] .navbar-toggler .navbar-toggler-icon {
   color: #fff !important
}

.logo-header[data-background-color] .btn-toggle,
.logo-header[data-background-color] .more {
   color: #fff !important
}

.logo-header[data-background-color=grey] .btn-toggle,
.logo-header[data-background-color=grey] .more,
.logo-header[data-background-color=grey] .navbar-toggler .navbar-toggler-icon,
.logo-header[data-background-color=white] .btn-toggle,
.logo-header[data-background-color=white] .more,
.logo-header[data-background-color=white] .navbar-toggler .navbar-toggler-icon {
   color: #8d9498 !important
}

.logo-header[data-background-color=grey],
.sidebar[data-background-color=grey] {
   -webkit-box-shadow: none !important;
   -moz-box-shadow: none !important;
   box-shadow: none !important
}

.logo-header[data-background-color=white] {
   background: #fff !important
}

.navbar-header[data-background-color=white] {
   background: #fff !important
}

.logo-header[data-background-color=grey] {
   background: #fafafe !important
}

.logo-header[data-background-color=dark] {
   background: #1a2035 !important
}

.logo-header[data-background-color=dark2] {
   background: #151a2b !important
}

.navbar-header[data-background-color=dark] {
   background: #1a2035 !important
}

.navbar-header[data-background-color=dark2] {
   background: #151a2b !important
}

.logo-header[data-background-color=blue] {
   background: #0033CC !important
}

.logo-header[data-background-color=blue2] {
   background: #1269db !important
}

.navbar-header[data-background-color=blue] {
   background: #0033CC !important
}

.navbar-header[data-background-color=blue2] {
   background: #1269db !important
}

.logo-header[data-background-color=purple] {
   background: #6861ce !important
}

.logo-header[data-background-color=purple2] {
   background: #5c55bf !important
}

.navbar-header[data-background-color=purple] {
   background: #6861ce !important
}

.navbar-header[data-background-color=purple2] {
   background: #5c55bf !important
}

.logo-header[data-background-color=light-blue] {
   background: #48abf7 !important
}

.logo-header[data-background-color=light-blue2] {
   background: #3697e1 !important
}

.navbar-header[data-background-color=light-blue] {
   background: #48abf7 !important
}

.navbar-header[data-background-color=light-blue2] {
   background: #3697e1 !important
}

.logo-header[data-background-color=green] {
   background: #196c1b !important
}

.logo-header[data-background-color=green2] {
   background: #2bb930 !important
}

.navbar-header[data-background-color=green] {
   background: #196c1b !important
}

.navbar-header[data-background-color=green2] {
   background: #2bb930 !important
}

.logo-header[data-background-color=orange] {
   background: #ffad46 !important
}

.logo-header[data-background-color=orange2] {
   background: #ff9e27 !important
}

.navbar-header[data-background-color=orange] {
   background: #ffad46 !important
}

.navbar-header[data-background-color=orange2] {
   background: #ff9e27 !important
}

.logo-header[data-background-color=red] {
   background: #f25961 !important
}

.logo-header[data-background-color=red2] {
   background: #ea4d56 !important
}

.navbar-header[data-background-color=red] {
   background: #f25961 !important
}

.navbar-header[data-background-color=red2] {
   background: #ea4d56 !important
}

.sidebar[data-background-color=grey] {
   background: #fafafe
}

.sidebar[data-background-color=dark] {
   background: #1a2035 !important
}

.sidebar[data-background-color=dark] .user {
   border-color: rgba(181, 181, 181, .1) !important
}

.sidebar[data-background-color=dark] .user .info a>span {
   color: #b9babf
}

.sidebar[data-background-color=dark] .user .info a>span .user-level {
   color: #8d9498
}

.sidebar[data-background-color=dark] .nav>.nav-item.active a:focus p,
.sidebar[data-background-color=dark] .nav>.nav-item.active a:hover p,
.sidebar[data-background-color=dark] .nav>.nav-item.active>a p {
   color: #fff !important
}

.sidebar[data-background-color=dark] .nav>.nav-item a {
   color: #b9babf !important
}

.sidebar[data-background-color=dark] .nav>.nav-item a:focus p,
.sidebar[data-background-color=dark] .nav>.nav-item a:hover p,
.sidebar[data-background-color=dark] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] p {
   color: #b9babf !important
}

.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:focus,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:hover,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] {
   background: #fff !important;
   color: #1a2035 !important
}

.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a .caret,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a i,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a p,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:focus .caret,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:focus i,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:focus p,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:hover .caret,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:hover i,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a:hover p,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] .caret,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=dark].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] p {
   color: #1a2035 !important
}

.sidebar[data-background-color=dark] .scrollbar-inner>.scroll-element .scroll-bar {
   background-color: #f7f7f7
}

.sidebar[data-background-color=dark] .scrollbar-inner>.scroll-element.scroll-draggable .scroll-bar,
.sidebar[data-background-color=dark] .scrollbar-inner>.scroll-element:hover .scroll-bar {
   background-color: #dcdbdb
}

.sidebar[data-background-color=dark2] {
   background: #151a2b !important
}

.sidebar[data-background-color=dark2] .user {
   border-color: rgba(181, 181, 181, .1) !important
}

.sidebar[data-background-color=dark2] .user .info a>span {
   color: #b9babf
}

.sidebar[data-background-color=dark2] .user .info a>span .user-level {
   color: #8d9498
}

.sidebar[data-background-color=dark2] .nav>.nav-item.active a:focus p,
.sidebar[data-background-color=dark2] .nav>.nav-item.active a:hover p,
.sidebar[data-background-color=dark2] .nav>.nav-item.active>a p {
   color: #fff !important
}

.sidebar[data-background-color=dark2] .nav>.nav-item a {
   color: #b9babf !important
}

.sidebar[data-background-color=dark2] .nav>.nav-item a:focus p,
.sidebar[data-background-color=dark2] .nav>.nav-item a:hover p,
.sidebar[data-background-color=dark2] .nav>.nav-item a[data-toggle=collapse][aria-expanded=true] p {
   color: #b9babf !important
}

.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:focus,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:hover,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] {
   background: #fff !important;
   color: #151a2b !important
}

.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a .caret,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a i,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a p,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:focus .caret,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:focus i,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:focus p,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:hover .caret,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:hover i,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a:hover p,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] .caret,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] i,
.sidebar[data-background-color=dark2].sidebar-style-2 .nav .nav-item.active>a[data-toggle=collapse][aria-expanded=true] p {
   color: #151a2b !important
}

.sidebar[data-background-color=dark2] .scrollbar-inner>.scroll-element .scroll-bar {
   background-color: #f7f7f7
}

.sidebar[data-background-color=dark2] .scrollbar-inner>.scroll-element.scroll-draggable .scroll-bar,
.sidebar[data-background-color=dark2] .scrollbar-inner>.scroll-element:hover .scroll-bar {
   background-color: #dcdbdb
}

.avatar {
   position: relative;
   display: inline-block
}

.avatar-img {
   width: 100%;
   height: 100%;
   -o-object-fit: cover;
   object-fit: cover
}

.avatar-title {
   width: 100%;
   height: 100%;
   background-color: #6861ce;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center
}

.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
   position: absolute;
   right: 0;
   bottom: 0;
   width: 25%;
   height: 25%;
   border-radius: 50%;
   content: '';
   border: 2px solid #fff
}

.avatar-online::before {
   background-color: #196c1b
}

.avatar-offline::before {
   background-color: #97a2b1
}

.avatar-away::before {
   background-color: #ffad46
}

.avatar {
   width: 3.2rem;
   height: 3.2rem
}

.avatar .border {
   border-width: 3px !important
}

.avatar .rounded {
   border-radius: 6px !important
}

.avatar .avatar-title {
   font-size: 18px
}

.avatar-xs {
   width: 1.95rem;
   height: 1.95rem
}

.avatar-xs .border {
   border-width: 2px !important
}

.avatar-xs .rounded {
   border-radius: 4px !important
}

.avatar-xs .avatar-title {
   font-size: 12px
}

.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
   border-width: 1px
}

.avatar-sm {
   width: 2.75rem;
   height: 2.75rem
}

.avatar-sm .border {
   border-width: 3px !important
}

.avatar-sm .rounded {
   border-radius: 4px !important
}

.avatar-sm .avatar-title {
   font-size: 15px
}

.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
   border-width: 2px
}

.avatar-lg {
   width: 3.95rem;
   height: 3.95rem
}

.avatar-lg .border {
   border-width: 3px !important
}

.avatar-lg .rounded {
   border-radius: 8px !important
}

.avatar-lg .avatar-title {
   font-size: 24px
}

.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
   border-width: 3px
}

.avatar-xl {
   width: 5.2rem;
   height: 5.2rem
}

.avatar-xl .border {
   border-width: 4px !important
}

.avatar-xl .rounded {
   border-radius: 8px !important
}

.avatar-xl .avatar-title {
   font-size: 28px
}

.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
   border-width: 4px
}

.avatar-xxl {
   width: 5.325rem;
   height: 5.325rem
}

.avatar-xxl .border {
   border-width: 6px !important
}

.avatar-xxl .rounded {
   border-radius: 8px !important
}

.avatar-xxl .avatar-title {
   font-size: 30px
}

.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
   border-width: 4px
}

@media (min-width:768px) {
   .avatar-xxl {
      width: 8rem;
      height: 8rem
   }

   .avatar-xxl .border {
      border-width: 4px !important
   }

   .avatar-xxl .rounded {
      border-radius: 12px !important
   }

   .avatar-xxl .avatar-title {
      font-size: 42px
   }

   .avatar-xxl.avatar-away::before,
   .avatar-xxl.avatar-offline::before,
   .avatar-xxl.avatar-online::before {
      border-width: 4px
   }
}

.avatar-group {
   display: inline-flex
}

.avatar-group .avatar+.avatar {
   margin-left: -.75rem
}

.avatar-group .avatar-xs+.avatar-xs {
   margin-left: -.40625rem
}

.avatar-group .avatar-sm+.avatar-sm {
   margin-left: -.625rem
}

.avatar-group .avatar-lg+.avatar-lg {
   margin-left: -1rem
}

.avatar-group .avatar-xl+.avatar-xl {
   margin-left: -1.28125rem
}

.avatar-group .avatar:hover {
   z-index: 1
}

.border-dark {
   border-color: #202940 !important
}

.breadcrumbs {
   list-style: none;
   display: inline;
   width: auto;
   border-left: 1px solid #efefef;
   margin-left: 25px;
   padding-left: 25px;
   margin-bottom: 0;
   padding-top: 8px;
   padding-bottom: 8px;
   height: 100%
}

.breadcrumbs li {
   display: inline-block
}

.breadcrumbs li a {
   color: #2a2f5b;
   font-size: 13px
}

.breadcrumbs li a i {
   font-size: 16px
}

.breadcrumbs li a:hover {
   text-decoration: none
}

.breadcrumbs li.separator {
   padding-left: 10px;
   padding-right: 10px;
   font-size: 12px
}

.card,
.card-light {
   border-radius: 10px;
   background-color: #fff;
   margin-bottom: 30px;
   -webkit-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, .1);
   -moz-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, .1);
   box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, .1);
   border: 0
}

.card .card-header,
.card-light .card-header {
   padding: 1rem 1.25rem;
   background-color: transparent;
   border-bottom: 1px solid #ebecec !important
}

.card .card-header:first-child,
.card-light .card-header:first-child {
   border-radius: 0
}

.card .card-head-row,
.card-light .card-head-row {
   display: flex;
   align-items: center
}

.card .card-head-row .card-tools,
.card-light .card-head-row .card-tools {
   margin-left: auto;
   float: right;
   padding-left: 15px
}

.card .card-head-row .card-tools .btn,
.card-light .card-head-row .card-tools .btn {
   margin-right: 10px
}

.card .card-head-row .card-tools .btn:last-child,
.card-light .card-head-row .card-tools .btn:last-child {
   margin-right: 0
}

.card .separator-solid,
.card-light .separator-solid {
   border-top: 1px solid #ebecec;
   margin: 15px 0
}

.card .separator-dashed,
.card-light .separator-dashed {
   border-top: 1px dashed #ebecec;
   margin: 15px 0
}

.card .separator-dot,
.card-light .separator-dot {
   border-top: 1px dotted #ebecec;
   margin: 15px 0
}

.card .full-width-separator,
.card-light .full-width-separator {
   margin: 15px -20px 15px
}

.card .b-b1,
.card-light .b-b1 {
   border-bottom: 1px solid rgba(255, 255, 255, .3)
}

.card .card-body,
.card-light .card-body {
   padding: 1.25rem
}

.card .card-footer,
.card-light .card-footer {
   background-color: transparent;
   line-height: 30px;
   border-top: 1px solid #ebecec !important;
   font-size: 13px
}

.card .pull-in,
.card-light .pull-in {
   margin-left: -1.25rem;
   margin-right: -1.25rem
}

.card .pull-in.sparkline-fix,
.card-light .pull-in.sparkline-fix {
   margin-left: -1.35rem;
   margin-right: -1.35rem;
   margin-bottom: -3px
}

.card .chart-as-background,
.card-light .chart-as-background {
   position: absolute;
   bottom: 0;
   width: calc(100% + 2px)
}

.card .card-action,
.card-light .card-action {
   padding: 30px;
   background-color: transparent;
   line-height: 30px;
   border-top: 1px solid #ebecec !important;
   font-size: 1rem
}

.card .card-footer hr,
.card-light .card-footer hr {
   margin-top: 5px;
   margin-bottom: 5px
}

.card .card-footer .legend,
.card-light .card-footer .legend {
   display: inline-block
}

@media screen and (max-width:476px) {
   .card .card-head-row:not(.card-tools-still-right) {
      flex-direction: column;
      align-items: unset
   }

   .card .card-head-row:not(.card-tools-still-right) .card-tools {
      margin-left: 0;
      float: left;
      padding-left: 0;
      padding-top: 10px
   }
}

.card.full-height {
   height: calc(100% - 30px)
}

.card-space {
   padding: 0 30px
}

.card-space>.card-action,
.card-space>.card-body,
.card-space>.card-footer,
.card-space>.card-header {
   padding-left: 0 !important;
   padding-right: 0 !important
}

.card-with-nav .card-header {
   border-bottom: 0 !important;
   padding-top: 0 !important;
   padding-bottom: 0 !important
}

.card-with-nav .card-body {
   padding: 15px 25px !important
}

.card-list {
   padding: 10px 0
}

.card-list .item-list {
   display: flex;
   flex-direction: row;
   padding: 10px 0;
   align-items: center
}

.card-list .item-list .info-user {
   flex: 1
}

.card-list .item-list .info-user .username,
.card-list .item-list .info-user a.username {
   font-size: 14px;
   margin-bottom: 5px;
   font-weight: 600
}

.card-list .item-list .info-user .status {
   font-size: 12px;
   color: #7d7b7b
}

.card-title {
   margin: 0;
   color: #2a2f5b;
   font-size: 20px;
   font-weight: 600;
   line-height: 1.6
}

.card-title a,
.card-title a:focus,
.card-title a:hover {
   color: #2a2f5b;
   text-decoration: none
}

.card-sub {
   display: block;
   margin: 5px 0 10px 0;
   font-size: .9rem;
   background: #f7f8fa;
   color: #2a2f5b;
   padding: .85rem 1.5rem;
   border-radius: 4px;
   line-height: 1.82
}

.card-category {
   margin-top: 8px;
   font-size: 13px;
   color: #8d9498;
   margin-bottom: 0;
   word-break: normal
}

label {
   font-size: 1rem;
   font-weight: 400;
   color: #8d9498;
   margin-bottom: 0
}

.card-transparent {
   background: 0 0 !important;
   box-shadow: none;
   border-color: transparent !important
}

.card-stats .card-body {
   padding: 15px !important
}

.card-stats .card-title {
   margin-bottom: 0 !important
}

.card-stats .card-category {
   margin-top: 0
}

.card-stats .col-icon {
   width: 65px;
   height: 65px;
   padding-left: 0;
   padding-right: 0;
   margin-left: 15px
}

.card-stats .icon-big {
   width: 100%;
   height: 100%;
   font-size: 2.2em;
   min-height: 64px;
   display: flex;
   align-items: center;
   justify-content: center
}

.card-stats .icon-big.icon-black,
.card-stats .icon-big.icon-danger,
.card-stats .icon-big.icon-info,
.card-stats .icon-big.icon-primary,
.card-stats .icon-big.icon-secondary,
.card-stats .icon-big.icon-success,
.card-stats .icon-big.icon-warning {
   border-radius: 5px
}

.card-stats .icon-big.icon-black i,
.card-stats .icon-big.icon-danger i,
.card-stats .icon-big.icon-info i,
.card-stats .icon-big.icon-primary i,
.card-stats .icon-big.icon-secondary i,
.card-stats .icon-big.icon-success i,
.card-stats .icon-big.icon-warning i {
   color: #fff !important
}

.card-stats .icon-big.icon-black {
   background: #1a2035
}

.card-stats .icon-big.icon-primary {
   background: #0033CC
}

.card-stats .icon-big.icon-secondary {
   background: #6861ce
}

.card-stats .icon-big.icon-success {
   background: #196c1b
}

.card-stats .icon-big.icon-warning {
   background: #ffad46
}

.card-stats .icon-big.icon-info {
   background: #48abf7
}

.card-stats .icon-big.icon-danger {
   background: #f25961
}

.card-stats .icon-big.round {
   border-radius: 50% !important
}

.card-stats .icon-big i.fa,
.card-stats .icon-big i.fab,
.card-stats .icon-big i.fal,
.card-stats .icon-big i.far,
.card-stats .icon-big i.fas {
   font-size: .8em
}

.card-stats .col-stats {
   align-items: center;
   display: flex;
   padding-left: 15px
}

.card-tasks .table {
   margin-bottom: 0
}

.card-tasks .table .form-check {
   padding: 0 0 0 .75rem !important
}

.card-tasks .table .form-check label {
   margin-bottom: 0 !important
}

.card-tasks .table tbody td:first-child,
.card-tasks .table thead th:first-child {
   padding-left: 15px;
   padding-right: 15px
}

.card-tasks .table tbody td:last-child,
.card-tasks .table thead th:last-child {
   padding-right: 15px
}

.card-tasks .table tbody tr:last-child td {
   border-bottom-width: 0 !important
}

.card-tasks .card-body {
   padding-top: 0;
   padding-bottom: 0
}

.card-tasks .card-body .table td {
   font-size: 13px
}

.card-tasks .card-body .table td .btn {
   font-size: 15px;
   opacity: .7;
   transition: all .3s
}

.card-tasks .card-body .table td:hover .btn {
   opacity: 1
}

.card-tasks .form-button-action {
   display: block !important
}

.card-black,
.card-danger,
.card-dark,
.card-info,
.card-primary,
.card-secondary,
.card-success,
.card-warning {
   color: #fff !important;
   border: 0 !important
}

.card-black .card-header,
.card-danger .card-header,
.card-dark .card-header,
.card-info .card-header,
.card-primary .card-header,
.card-secondary .card-header,
.card-success .card-header,
.card-warning .card-header {
   border-bottom: transparent !important
}

.card-black .card-category,
.card-black .card-title,
.card-black label,
.card-danger .card-category,
.card-danger .card-title,
.card-danger label,
.card-dark .card-category,
.card-dark .card-title,
.card-dark label,
.card-info .card-category,
.card-info .card-title,
.card-info label,
.card-primary .card-category,
.card-primary .card-title,
.card-primary label,
.card-secondary .card-category,
.card-secondary .card-title,
.card-success .card-category,
.card-success .card-title,
.card-success label,
.card-warning .card-category,
.card-warning .card-title,
.card-warning label {
   color: #fff !important
}

.card-black .icon-big>i,
.card-danger .icon-big>i,
.card-dark .icon-big>i,
.card-info .icon-big>i,
.card-primary .icon-big>i,
.card-secondary .icon-big>i,
.card-success .icon-big>i,
.card-warning .icon-big>i {
   color: #fff !important
}

.card-black .card-footer,
.card-danger .card-footer,
.card-dark .card-footer,
.card-info .card-footer,
.card-primary .card-footer,
.card-secondary .card-footer,
.card-success .card-footer,
.card-warning .card-footer {
   border-top: transparent !important
}

.card-black {
   background: #1a2035 !important
}

.card-primary {
   background: #0033CC !important
}

.card-secondary {
   background: #6861ce !important
}

.card-info {
   background: #48abf7 !important
}

.card-success {
   background: #196c1b !important
}

.card-warning {
   background: #ffad46 !important
}

.card-danger {
   background: #f25961 !important
}

.card-round {
   border-radius: 10px
}

.progress-card {
   margin-bottom: 25px
}

.progress-card .progress-status {
   display: flex;
   margin-bottom: 10px;
   -webkit-box-pack: justify !important;
   -ms-flex-pack: justify !important;
   justify-content: space-between !important
}

.card-post .info-post .username {
   margin-bottom: 0;
   font-weight: 600
}

.card-post .info-post .date {
   margin-bottom: 0
}

.card-pricing {
   padding: 20px 5px;
   text-align: center;
   border-radius: 10px
}

.card-pricing .card-header {
   border-bottom: 0 !important
}

.card-pricing .card-footer {
   border-top: 0 !important;
   padding: 15px 15px 10px 15px
}

.card-pricing .card-title {
   font-weight: 400;
   font-size: 20px
}

.card-pricing .card-price .price {
   font-size: 36px;
   font-weight: 400
}

.card-pricing .card-price .text {
   font-size: 18px;
   font-weight: 400;
   color: #d1d7e3
}

.card-pricing .specification-list {
   list-style: none;
   padding-left: 0
}

.card-pricing .specification-list li {
   padding: 8px 0 12px;
   border-bottom: 1px solid #eee;
   text-align: left;
   font-size: 12px;
   margin-bottom: 5px
}

.card-pricing .specification-list li .name-specification {
   color: #83848a
}

.card-pricing .specification-list li .status-specification {
   margin-left: auto;
   float: right;
   font-weight: 400
}

.card-pricing.card-pricing-focus {
   padding: 40px 5px
}

.card-pricing.card-black .name-specification,
.card-pricing.card-danger .name-specification,
.card-pricing.card-info .name-specification,
.card-pricing.card-primary .name-specification,
.card-pricing.card-secondary .name-specification,
.card-pricing.card-success .name-specification,
.card-pricing.card-warning .name-specification {
   color: #fff !important
}

.card-pricing.card-black .price,
.card-pricing.card-danger .price,
.card-pricing.card-info .price,
.card-pricing.card-primary .price,
.card-pricing.card-secondary .price,
.card-pricing.card-success .price,
.card-pricing.card-warning .price {
   color: #fff !important
}

.card-pricing.card-primary .specification-list li {
   border-color: #2f8bff !important
}

.card-pricing.card-primary .btn-light {
   color: #0033CC !important
}

.card-pricing.card-success .specification-list li {
   border-color: #64e069 !important
}

.card-pricing.card-success .btn-light {
   color: #196c1b !important
}

.card-pricing.card-secondary .specification-list li {
   border-color: #7f77dc !important
}

.card-pricing.card-secondary .btn-light {
   color: #6861ce !important
}

.card-pricing.card-black .specification-list li {
   border-color: #6f8996 !important
}

.card-pricing.card-black .btn-light {
   color: #1a2035 !important
}

.card-pricing.card-info .specification-list li {
   border-color: #11c0e4 !important
}

.card-pricing.card-info .btn-light {
   color: #48abf7 !important
}

.card-pricing.card-danger .specification-list li {
   border-color: #ff6972 !important
}

.card-pricing.card-danger .btn-light {
   color: #f25961 !important
}

.card-pricing.card-warning .specification-list li {
   border-color: #ffbc67 !important
}

.card-pricing.card-warning .btn-light {
   color: #ffad46 !important
}

.card-pricing2 {
   padding-bottom: 10px;
   background: #fff !important;
   border-bottom: 7px solid;
   text-align: center;
   overflow: hidden;
   position: relative;
   border-radius: 10px;
   -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
   -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08);
   box-shadow: 0 1px 15px 1px rgba(69, 65, 78, .08)
}

.card-pricing2:before {
   content: "";
   width: 100%;
   height: 350px;
   position: absolute;
   top: -150px;
   left: 0;
   transform: skewY(-20deg)
}

.card-pricing2 .price-value:after,
.card-pricing2 .price-value:before {
   content: "";
   left: 50%;
   transform: translateX(-50%) scaleY(.5) rotate(45deg)
}

.card-pricing2 .value:after,
.card-pricing2 .value:before {
   content: "";
   left: 50%;
   transform: translateX(-50%) scaleY(.5) rotate(45deg)
}

.card-pricing2 .pricing-header {
   padding: 20px 20px 60px;
   text-align: left;
   position: relative
}

.card-pricing2 .sub-title {
   display: block;
   font-size: 16px
}

.card-pricing2 .value {
   background: #fff
}

.card-pricing2 .price-value {
   display: inline-block;
   width: 170px;
   height: 110px;
   padding: 15px;
   border: 2px solid;
   border-top: none;
   border-bottom: none;
   position: relative
}

.card-pricing2 .price-value:after,
.card-pricing2 .price-value:before {
   width: 121px;
   height: 121px;
   border: 3px solid;
   border-right: none;
   border-bottom: none;
   position: absolute;
   top: -60px
}

.card-pricing2 .price-value:after {
   border-top: none;
   border-left: none;
   border-bottom: 3px solid;
   border-right: 3px solid;
   top: auto;
   bottom: -60px
}

.card-pricing2 .value {
   width: 100%;
   height: 100%;
   border: 2px solid;
   border-top: none;
   border-bottom: none;
   z-index: 1;
   position: relative
}

.card-pricing2 .value:after,
.card-pricing2 .value:before {
   width: 97px;
   height: 97px;
   background: #fff;
   border: 3px solid;
   border-bottom: none;
   border-right: none;
   position: absolute;
   top: -48px;
   z-index: -1
}

.card-pricing2 .value:after {
   border-right: 3px solid;
   border-bottom: 3px solid;
   border-top: none;
   border-left: none;
   top: auto;
   bottom: -48px
}

.card-pricing2 .currency {
   display: inline-block;
   font-size: 30px;
   margin-top: 7px;
   vertical-align: top
}

.card-pricing2 .amount {
   display: inline-block;
   font-size: 40px;
   font-weight: 600;
   line-height: 65px
}

.card-pricing2 .amount span {
   display: inline-block;
   font-size: 30px;
   font-weight: 400;
   vertical-align: top;
   margin-top: -7px
}

.card-pricing2 .month {
   display: block;
   font-size: 16px;
   line-height: 0
}

.card-pricing2 .pricing-content {
   padding: 50px 0 0 80px;
   margin-bottom: 20px;
   list-style: none;
   text-align: left;
   transition: all .3s ease 0s
}

.card-pricing2 .pricing-content li {
   padding: 7px 0;
   font-size: 13px;
   color: grey;
   position: relative
}

.card-pricing2 .pricing-content li.disable:before,
.card-pricing2 .pricing-content li:before {
   content: "\f00c";
   font-family: 'Font Awesome 5 Solid';
   font-weight: 900;
   width: 20px;
   height: 20px;
   line-height: 20px;
   border-radius: 50%;
   background: #98c458;
   text-align: center;
   color: #fff;
   position: absolute;
   left: -50px;
   font-size: 9px
}

.card-pricing2 .pricing-content li.disable:before {
   content: "\f00d";
   background: #fe6c6c
}

.card-pricing2.card-black {
   border-bottom-color: #1a2035
}

.card-pricing2.card-black .price-value:before,
.card-pricing2.card-black .value:before {
   border-left-color: #1a2035;
   border-top-color: #1a2035
}

.card-pricing2.card-black .price-value,
.card-pricing2.card-black .value {
   border-right-color: #1a2035
}

.card-pricing2.card-black .price-value:after,
.card-pricing2.card-black .value:after {
   border-right-color: #1a2035
}

.card-pricing2.card-black .price-value:after,
.card-pricing2.card-black .value:after {
   border-bottom-color: #1a2035
}

.card-pricing2.card-black .value {
   color: #1a2035
}

.card-pricing2.card-black:before {
   background: #1a2035
}

.card-pricing2.card-black .price-value,
.card-pricing2.card-black .value {
   border-left-color: #1a2035
}

.card-pricing2.card-primary {
   border-bottom-color: #0033CC
}

.card-pricing2.card-primary .price-value:before,
.card-pricing2.card-primary .value:before {
   border-left-color: #0033CC;
   border-top-color: #0033CC
}

.card-pricing2.card-primary .price-value,
.card-pricing2.card-primary .value {
   border-right-color: #0033CC
}

.card-pricing2.card-primary .price-value:after,
.card-pricing2.card-primary .value:after {
   border-right-color: #0033CC
}

.card-pricing2.card-primary .price-value:after,
.card-pricing2.card-primary .value:after {
   border-bottom-color: #0033CC
}

.card-pricing2.card-primary .value {
   color: #0033CC
}

.card-pricing2.card-primary:before {
   background: #0033CC
}

.card-pricing2.card-primary .price-value,
.card-pricing2.card-primary .value {
   border-left-color: #0033CC
}

.card-pricing2.card-secondary {
   border-bottom-color: #6861ce
}

.card-pricing2.card-secondary .price-value:before,
.card-pricing2.card-secondary .value:before {
   border-left-color: #6861ce;
   border-top-color: #6861ce
}

.card-pricing2.card-secondary .price-value,
.card-pricing2.card-secondary .value {
   border-right-color: #6861ce
}

.card-pricing2.card-secondary .price-value:after,
.card-pricing2.card-secondary .value:after {
   border-right-color: #6861ce
}

.card-pricing2.card-secondary .price-value:after,
.card-pricing2.card-secondary .value:after {
   border-bottom-color: #6861ce
}

.card-pricing2.card-secondary .value {
   color: #6861ce
}

.card-pricing2.card-secondary:before {
   background: #6861ce
}

.card-pricing2.card-secondary .price-value,
.card-pricing2.card-secondary .value {
   border-left-color: #6861ce
}

.card-pricing2.card-info {
   border-bottom-color: #48abf7
}

.card-pricing2.card-info .price-value:before,
.card-pricing2.card-info .value:before {
   border-left-color: #48abf7;
   border-top-color: #48abf7
}

.card-pricing2.card-info .price-value,
.card-pricing2.card-info .value {
   border-right-color: #48abf7
}

.card-pricing2.card-info .price-value:after,
.card-pricing2.card-info .value:after {
   border-right-color: #48abf7
}

.card-pricing2.card-info .price-value:after,
.card-pricing2.card-info .value:after {
   border-bottom-color: #48abf7
}

.card-pricing2.card-info .value {
   color: #48abf7
}

.card-pricing2.card-info:before {
   background: #48abf7
}

.card-pricing2.card-info .price-value,
.card-pricing2.card-info .value {
   border-left-color: #48abf7
}

.card-pricing2.card-success {
   border-bottom-color: #196c1b
}

.card-pricing2.card-success .price-value:before,
.card-pricing2.card-success .value:before {
   border-left-color: #196c1b;
   border-top-color: #196c1b
}

.card-pricing2.card-success .price-value,
.card-pricing2.card-success .value {
   border-right-color: #196c1b
}

.card-pricing2.card-success .price-value:after,
.card-pricing2.card-success .value:after {
   border-right-color: #196c1b
}

.card-pricing2.card-success .price-value:after,
.card-pricing2.card-success .value:after {
   border-bottom-color: #196c1b
}

.card-pricing2.card-success .value {
   color: #196c1b
}

.card-pricing2.card-success:before {
   background: #196c1b
}

.card-pricing2.card-success .price-value,
.card-pricing2.card-success .value {
   border-left-color: #196c1b
}

.card-pricing2.card-warning {
   border-bottom-color: #ffad46
}

.card-pricing2.card-warning .price-value:before,
.card-pricing2.card-warning .value:before {
   border-left-color: #ffad46;
   border-top-color: #ffad46
}

.card-pricing2.card-warning .price-value,
.card-pricing2.card-warning .value {
   border-right-color: #ffad46
}

.card-pricing2.card-warning .price-value:after,
.card-pricing2.card-warning .value:after {
   border-right-color: #ffad46
}

.card-pricing2.card-warning .price-value:after,
.card-pricing2.card-warning .value:after {
   border-bottom-color: #ffad46
}

.card-pricing2.card-warning .value {
   color: #ffad46
}

.card-pricing2.card-warning:before {
   background: #ffad46
}

.card-pricing2.card-warning .price-value,
.card-pricing2.card-warning .value {
   border-left-color: #ffad46
}

.card-pricing2.card-danger {
   border-bottom-color: #f25961
}

.card-pricing2.card-danger .price-value:before,
.card-pricing2.card-danger .value:before {
   border-left-color: #f25961;
   border-top-color: #f25961
}

.card-pricing2.card-danger .price-value,
.card-pricing2.card-danger .value {
   border-right-color: #f25961
}

.card-pricing2.card-danger .price-value:after,
.card-pricing2.card-danger .value:after {
   border-right-color: #f25961
}

.card-pricing2.card-danger .price-value:after,
.card-pricing2.card-danger .value:after {
   border-bottom-color: #f25961
}

.card-pricing2.card-danger .value {
   color: #f25961
}

.card-pricing2.card-danger:before {
   background: #f25961
}

.card-pricing2.card-danger .price-value,
.card-pricing2.card-danger .value {
   border-left-color: #f25961
}

.row-cardProduct {
   padding: 0 5px;
   white-space: nowrap;
   overflow-x: auto;
   display: block !important;
   margin-right: -2rem;
   width: unset !important
}

.col-cardProduct {
   width: 225px;
   padding: 0 10px;
   display: inline-block
}

.card-product {
   background: #fff;
   border-radius: 10px;
   overflow: hidden;
   box-shadow: 0 7px 15px rgba(0, 0, 0, .12);
   margin-bottom: 15px
}

.card-product .product-summary {
   padding: 15px
}

@media screen and (max-width:768px) {
   .col-cardProduct {
      width: 175px
   }

   .card-product .title-product {
      font-size: 1rem
   }

   .card-product .price-product {
      font-size: 18px
   }
}

.skew-shadow {
   position: relative;
   overflow: hidden
}

.skew-shadow:before {
   content: '';
   position: absolute;
   background: rgba(255, 255, 255, .1);
   width: 50%;
   min-width: 150px;
   height: 100%;
   top: 0;
   right: -25%;
   transform: skewX(-32.5deg)
}

.bubble-shadow {
   position: relative;
   overflow: hidden
}

.bubble-shadow:before {
   position: absolute;
   top: -10%;
   right: -140px;
   width: 300px;
   height: 300px;
   content: "";
   border-radius: 50%;
   background: rgba(255, 255, 255, .05)
}

.bubble-shadow:after {
   position: absolute;
   top: -65px;
   right: 80px;
   width: 150px;
   height: 150px;
   content: "";
   border-radius: 50%;
   background: rgba(255, 255, 255, .05)
}

.curves-shadow {
   position: relative;
   overflow: hidden
}

.curves-shadow:before {
   content: '';
   position: absolute;
   background: url(../img/img-shadow.png);
   background-size: cover;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0
}

@media only screen and (min-width:991px) {
   .card-list-messages {
      margin-left: -14px;
      margin-right: -14px;
      border-radius: 0;
      box-shadow: none
   }
}

@media only screen and (max-width:991px) {
   .card-pricing2 {
      margin-bottom: 30px
   }
}

@media only screen and (max-width:767px) {
   .card-pricing2:before {
      transform: skewY(-15deg)
   }
}

.card-annoucement .card-body {
   padding: 50px 25px
}

.card-annoucement .card-opening {
   font-size: 20px;
   font-weight: 400;
   letter-spacing: .01em
}

.card-annoucement .card-desc {
   padding: 15px 0;
   font-size: 16px;
   line-height: 1.65;
   font-weight: 300
}

.card-annoucement.card-primary .btn-light {
   color: #0033CC !important
}

.card-annoucement.card-success .btn-light {
   color: #196c1b !important
}

.card-annoucement.card-secondary .btn-light {
   color: #6861ce !important
}

.card-annoucement.card-black .btn-light {
   color: #1a2035 !important
}

.card-annoucement.card-info .btn-light {
   color: #48abf7 !important
}

.card-annoucement.card-danger .btn-light {
   color: #f25961 !important
}

.card-annoucement.card-warning .btn-light {
   color: #ffad46 !important
}

.card-profile {
   color: #2a2f5b
}

.card-profile .profile-picture {
   text-align: center;
   position: absolute;
   margin: 0 auto;
   left: 0;
   right: 0;
   bottom: -41px;
   width: 100%;
   box-sizing: border-box
}

.card-profile .user-profile .name {
   font-size: 20px;
   font-weight: 400;
   margin-bottom: 5px
}

.card-profile .user-profile .job {
   color: #83848a;
   margin-bottom: 5px
}

.card-profile .user-profile .desc {
   color: #bbb;
   margin-bottom: 15px
}

.card-profile .user-profile .social-media {
   margin-bottom: 20px
}

.card-profile .user-profile .social-media .btn {
   padding: 5px !important
}

.card-profile .user-profile .social-media .btn i {
   font-size: 22px !important
}

.card-profile .user-stats {
   margin-bottom: 10px
}

.card-profile .user-stats [class^=col] {
   border-right: 1px solid #ebebeb
}

.card-profile .user-stats [class^=col]:last-child {
   border-right: 0
}

.card-profile .user-stats .number {
   font-weight: 400;
   font-size: 15px
}

.card-profile .user-stats .title {
   color: #7d7b7b
}

.card-profile .card-header {
   border-bottom: 0;
   height: 100px;
   position: relative
}

.card-profile .card-body {
   padding-top: 60px
}

.card-profile .card-footer {
   border-top: 0
}

.card-profile.card-secondary .card-header {
   background: #6861ce
}

.row-card-no-pd {
   border-radius: 10px;
   margin-left: 0;
   margin-right: 0;
   background: #fff;
   margin-bottom: 30px;
   padding-top: 15px;
   padding-bottom: 15px;
   position: relative;
   -webkit-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, .1);
   -moz-box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, .1);
   box-shadow: 2px 6px 15px 0 rgba(69, 65, 78, .1);
   border: 0
}

.row-card-no-pd .card {
   margin-bottom: 0;
   border-width: 0;
   box-shadow: none;
   position: relative
}

.row-card-no-pd .card .card-header {
   padding-left: 0 !important;
   padding-top: 0 !important;
   padding-right: 0 !important
}

.row-card-no-pd [class*=col] .card:before {
   position: absolute;
   height: calc(100%);
   width: 1px;
   background: #eee;
   content: '';
   right: -15px
}

.row-card-no-pd [class*=col]:last-child .card:before {
   width: 0
}

.accordion .card {
   border-radius: 10px !important;
   background: #f7f7f7 !important;
   color: #2a2f5b !important;
   border: 0;
   box-shadow: none
}

.accordion .card .span-icon {
   font-size: 22px;
   padding-left: 15px;
   padding-right: 15px
}

.accordion .card>.card-header {
   border: 0 !important;
   display: flex;
   flex-direction: row;
   align-items: center;
   cursor: pointer;
   border-radius: 0 !important
}

.accordion .card>.card-header>.span-mode {
   margin-left: auto
}

.accordion .card>.card-header>.span-mode:before {
   content: "\f068" !important;
   font-family: 'Font Awesome 5 Solid';
   font-weight: 900;
   font-size: 16px
}

.accordion .card>.card-header.collapsed>.span-mode:before {
   content: "\f067" !important
}

.accordion .card .card-body {
   border-top: 1px solid #ebebeb;
   padding: 30px
}

.accordion.accordion-black .card .card-header,
.accordion.accordion-black .card .card-header .btn-link,
.accordion.accordion-danger .card .card-header,
.accordion.accordion-danger .card .card-header .btn-link,
.accordion.accordion-info .card .card-header,
.accordion.accordion-info .card .card-header .btn-link,
.accordion.accordion-primary .card .card-header,
.accordion.accordion-primary .card .card-header .btn-link,
.accordion.accordion-secondary .card .card-header,
.accordion.accordion-secondary .card .card-header .btn-link,
.accordion.accordion-success .card .card-header,
.accordion.accordion-success .card .card-header .btn-link,
.accordion.accordion-warning .card .card-header,
.accordion.accordion-warning .card .card-header .btn-link {
   font-size: 1rem
}

.accordion.accordion-black .card .card-header {
   color: #1a2035
}

.accordion.accordion-black .card .card-header .btn-link {
   color: #1a2035 !important
}

.accordion.accordion-primary .card .card-header {
   color: #0033CC
}

.accordion.accordion-primary .card .card-header .btn-link {
   color: #0033CC !important
}

.accordion.accordion-secondary .card .card-header {
   color: #6861ce
}

.accordion.accordion-secondary .card .card-header .btn-link {
   color: #6861ce !important
}

.accordion.accordion-info .card .card-header {
   color: #48abf7
}

.accordion.accordion-info .card .card-header .btn-link {
   color: #48abf7 !important
}

.accordion.accordion-success .card .card-header {
   color: #196c1b
}

.accordion.accordion-success .card .card-header .btn-link {
   color: #196c1b !important
}

.accordion.accordion-warning .card .card-header {
   color: #ffad46
}

.accordion.accordion-warning .card .card-header .btn-link {
   color: #ffad46 !important
}

.accordion.accordion-danger .card .card-header {
   color: #f25961
}

.accordion.accordion-danger .card .card-header .btn-link {
   color: #f25961 !important
}

.border-transparent {
   border-color: transparent !important
}

.gutters-0 {
   margin-right: 0;
   margin-left: 0
}

.gutters-0>.col,
.gutters-0>[class*=col-] {
   padding-right: 0;
   padding-left: 0
}

.gutters-0 .card {
   margin-bottom: 0
}

.gutters-xs {
   margin-right: -.25rem;
   margin-left: -.25rem
}

.gutters-xs>.col,
.gutters-xs>[class*=col-] {
   padding-right: .25rem;
   padding-left: .25rem
}

.gutters-xs .card {
   margin-bottom: .5rem
}

.gutters-sm {
   margin-right: -.5rem;
   margin-left: -.5rem
}

.gutters-sm>.col,
.gutters-sm>[class*=col-] {
   padding-right: .5rem;
   padding-left: .5rem
}

.gutters-sm .card {
   margin-bottom: 1rem
}

.gutters-lg {
   margin-right: -1rem;
   margin-left: -1rem
}

.gutters-lg>.col,
.gutters-lg>[class*=col-] {
   padding-right: 1rem;
   padding-left: 1rem
}

.gutters-lg .card {
   margin-bottom: 2rem
}

.gutters-xl {
   margin-right: -1.5rem;
   margin-left: -1.5rem
}

.gutters-xl>.col,
.gutters-xl>[class*=col-] {
   padding-right: 1.5rem;
   padding-left: 1.5rem
}

.gutters-xl .card {
   margin-bottom: 3rem
}

.stamp {
   background: #6861ce;
   display: inline-block;
   min-width: 2rem;
   height: 2rem;
   padding: 0 .25rem;
   line-height: 2rem;
   text-align: center;
   border-radius: 3px;
   font-weight: 600
}

.stamp i {
   color: #fff !important
}

.stamp-md {
   min-width: 2.5rem;
   height: 2.5rem;
   line-height: 2.5rem
}

.form-control {
   font-size: 1rem;
   border-color: #d9d9d9;
   padding: .6rem 1rem;
   height: inherit !important;
   border-width: 2px
}

.form-control:focus {
   border-color: #3e93ff
}

.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-append>.btn,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-prepend>.input-group-text {
   padding: .5rem 1rem !important;
   font-size: 1.25rem !important
}

.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-append>.btn,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-prepend>.input-group-text {
   padding: .25rem .5rem !important;
   font-size: .875rem !important;
   line-height: 1.5
}

.form-control::-webkit-input-placeholder {
   color: inherit;
   opacity: .7
}

.form-control:-moz-placeholder {
   color: inherit;
   opacity: .7
}

.form-control::-moz-placeholder {
   color: inherit;
   opacity: .7
}

.form-control:-ms-input-placeholder {
   color: inherit;
   opacity: .7
}

.form-control::-ms-input-placeholder {
   color: inherit;
   opacity: .7
}

.input-group-text {
   border-color: #ebedf2 !important
}

.form-button-action {
   display: inline-flex
}

.form-check-label,
.form-radio-label {
   margin-right: 15px
}

.select-all-checkbox+.form-check-sign:before {
   background: #ccc !important;
   border-color: #ccc !important
}

.form-check .form-check-input {
   margin-left: 0;
   margin-right: .75em
}

.form-radio [type=radio]:checked,
.form-radio [type=radio]:not(:checked) {
   position: absolute;
   left: -9999px
}

.form-radio [type=radio]:checked+.form-radio-sign,
.form-radio [type=radio]:not(:checked)+.form-radio-sign {
   color: #2a2f5b;
   position: relative;
   padding-left: 2em;
   cursor: pointer;
   line-height: 22px;
   font-weight: 400
}

.form-radio [type=radio]:not(:checked)+.form-radio-sign:before {
   content: "\f18a";
   font-size: 22px;
   font-family: LineAwesome;
   position: absolute;
   left: 0;
   top: auto;
   background: 0 0;
   line-height: 1;
   color: #bbb
}

.form-radio [type=radio]:checked+.form-radio-sign:before {
   content: "\f18a";
   font-size: 22px;
   font-family: LineAwesome;
   position: absolute;
   left: 0;
   top: auto;
   background: 0 0;
   line-height: 1;
   display: none
}

.form-radio [type=radio]:checked+.form-radio-sign:after,
.form-radio [type=radio]:not(:checked)+.form-radio-sign:after {
   content: "\f1bc";
   position: absolute;
   left: 0;
   top: auto;
   text-align: center;
   font-size: 22px;
   color: #4d7cfe;
   transition: all .2s;
   line-height: 1;
   font-family: LineAwesome
}

.form-radio [type=radio]:not(:checked)+.form-radio-sign:after {
   opacity: 0;
   transform: scale(0)
}

.form-radio [type=radio]:checked+.form-radio-sign {
   font-weight: 400
}

.form-radio [type=radio]:checked+.form-radio-sign:after {
   opacity: 1;
   transform: scale(1)
}

.form-radio [type=radio]:disabled:not(:checked)+.form-radio-sign:before {
   box-shadow: none;
   opacity: .65
}

.form-radio [type=radio]:disabled:checked+.form-radio-sign:before {
   box-shadow: none;
   opacity: .65
}

.form-radio [type=radio]:disabled:checked+.form-radio-sign:after {
   opacity: .65
}

.form-radio [type=radio]:disabled+.form-radio-sign {
   color: #aaa;
   opacity: .65
}

.form-radio [type=radio]:checked:focus+.form-radio-sign:before,
.form-radio [type=radio]:not(:checked):focus+.form-radio-sign:before {
   border: 1px solid #ccc
}

.form-radio {
   padding-left: .75rem
}

.form-radio-input {
   position: relative;
   margin-top: .3rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
   background-color: #0033CC
}

.custom-checkbox.checkbox-black .custom-control-input:checked~.custom-control-label::before {
   background-color: #1a2035
}

.custom-checkbox.checkbox-primary .custom-control-input:checked~.custom-control-label::before {
   background-color: #0033CC
}

.custom-checkbox.checkbox-secondary .custom-control-input:checked~.custom-control-label::before {
   background-color: #6861ce
}

.custom-checkbox.checkbox-info .custom-control-input:checked~.custom-control-label::before {
   background-color: #48abf7
}

.custom-checkbox.checkbox-success .custom-control-input:checked~.custom-control-label::before {
   background-color: #196c1b
}

.custom-checkbox.checkbox-warning .custom-control-input:checked~.custom-control-label::before {
   background-color: #ffad46
}

.custom-checkbox.checkbox-danger .custom-control-input:checked~.custom-control-label::before {
   background-color: #f25961
}

.col-form-label {
   line-height: 1.8
}

.required-label {
   color: red
}

.label-align-left {
   text-align: left
}

.label-align-right {
   text-align: right
}

.label-align-center {
   text-align: center
}

.form-check,
.form-group {
   margin-bottom: 0;
   padding-top: 10px;
   padding-bottom: 10px;
}

.form-check label,
.form-group label {
   margin-bottom: .5rem;
   color: #495057;
   font-size: 1rem;
   white-space: nowrap
}

.form-group-default {
   background-color: #fff;
   border: 1px solid rgba(0, 0, 0, .07);
   border-radius: 4px;
   padding-top: 7px;
   padding-left: 12px;
   padding-right: 12px;
   padding-bottom: 4px;
   overflow: hidden;
   width: 100%;
   -webkit-transition: background-color .2s ease;
   transition: background-color .2s ease;
   margin-bottom: 15px
}

.form-group-default label {
   opacity: 1;
   -webkit-backface-visibility: hidden;
   -moz-backface-visibility: hidden;
   backface-visibility: hidden;
   margin: 0;
   display: block;
   -webkit-transition: opacity .2s ease;
   transition: opacity .2s ease
}

.form-group-default label:not(.error) {
   font-size: 10.5px !important;
   letter-spacing: .06em;
   text-transform: uppercase;
   font-weight: 400
}

.form-group-default .form-control {
   background: unset !important
}

.form-group-default .form-control,
.form-group-default .form-select {
   border: 0;
   min-height: 25px;
   padding: 0;
   margin-top: 6px;
   font-size: 1rem
}

.form-group-default select.form-control:not([size]):not([multiple]),
.form-group-default select.form-select:not([size]):not([multiple]) {
   height: unset !important
}

.form-group-default.active {
   border-color: rgba(0, 0, 0, .1) !important;
   background-color: #f0f0f0
}

.form-group-default.active label {
   opacity: .5
}

.form-floating-custom {
   position: relative
}

.form-floating-custom .form-control,
.form-floating-custom .form-select {
   border-left: 0;
   border-top: 0;
   border-right: 0;
   border-radius: 0;
   padding-left: 0;
   background: 0 0 !important
}

.form-floating-custom label {
   padding-left: 0 !important
}

.form-floating-custom .form-control:focus+label,
.form-floating-custom .form-control:not(:placeholder-shown)+label,
.form-floating-custom .form-select:focus+label,
.form-floating-custom .form-select:not(:placeholder-shown)+label {
   font-size: 85% !important;
   transform: translate3d(0, -10px, 0);
   top: 0;
   opacity: 1;
   padding: .375rem 0 .75rem
}

.form-floating-custom .form-control:not(:placeholder-shown)+label,
.form-floating-custom .form-select:not(:placeholder-shown)+label {
   color: #0033CC !important
}

.form-floating-custom .form-control:not(:placeholder-shown)~label::after {
   background: 0 0 !important
}

.form-floating-custom .form-control ::-webkit-input-placeholder {
   color: transparent
}

.form-floating-custom .form-control :-moz-placeholder {
   color: transparent
}

.form-floating-custom .form-control ::-moz-placeholder {
   color: transparent
}

.form-floating-custom .form-control :-ms-input-placeholder {
   color: transparent
}

.form-floating-custom>.form-select~label::after {
   display: none !important
}

.form-floating-custom>.form-control~label::after {
   background: 0 0 !important
}

.form-inline label {
   margin-bottom: 0 !important
}

.input-fixed {
   width: 200px
}

.form-control.input-full {
   width: 100% !important
}

.has-success label {
   color: #196c1b !important
}

.has-success .form-control {
   border-color: #196c1b !important;
   color: #196c1b !important
}

.has-success .input-group-text {
   border-color: #196c1b !important;
   background: #196c1b !important;
   color: #fff !important
}

.has-error label {
   color: #f25961 !important
}

.has-error .form-control {
   border-color: #f25961 !important;
   color: #f25961 !important
}

.has-error .input-group-text {
   border-color: #f25961 !important;
   background: #f25961 !important;
   color: #fff !important
}

.input-group label.error,
.input-group label.success {
   width: 100%;
   order: 100
}

.custom-control {
   position: relative
}

.custom-control.custom-checkbox,
.custom-control.custom-radio {
   margin-bottom: 0;
   cursor: pointer;
   margin-right: 25px;
   display: inline-block
}

.custom-control.custom-checkbox .custom-control-input,
.custom-control.custom-radio .custom-control-input {
   margin-right: 10px
}

.custom-control.custom-checkbox label.error,
.custom-control.custom-checkbox label.success,
.custom-control.custom-radio label.error,
.custom-control.custom-radio label.success {
   position: absolute;
   width: 100vh;
   top: 23px;
   left: 0
}

.has-feedback {
   position: relative
}

.form-control-feedback {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   right: 20px
}

.has-success .form-control-feedback {
   color: #196c1b
}

.has-error .form-control-feedback {
   color: #f25961
}

.input-group.has-icon {
   border-radius: .25rem;
   border: 1px solid #ced4da
}

.input-group.has-icon.has-success,
.input-group.has-success {
   border: 1px solid #196c1b !important;
   color: #196c1b
}

.input-group.has-error {
   border: 1px solid #f25961 !important;
   color: #f25961
}

.input-group.has-icon.has-error {
   border: 1px solid #f25961 !important;
   color: #f25961
}

.input-group.has-icon .form-control {
   border-radius: .25rem;
   border: 0;
   position: relative
}

.input-group.has-icon .input-group-icon {
   background: 0 0;
   border: 0
}

.input-square {
   border-radius: 0 !important
}

.input-pill {
   border-radius: 1.3rem !important
}

.input-solid {
   background: #e8e8e8 !important;
   border-color: #e8e8e8 !important
}

.input-border-bottom {
   border-width: 0 0 1px 0;
   border-radius: 0;
   padding: .75rem 0;
   background: 0 0 !important
}

.input-file .form-control,
.input-file .form-control-file,
.input-file input[type=file] {
   width: .1px;
   height: .1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1
}

.input-file label.error,
.input-file label.success {
   display: block
}

.input-file .form-control+label:not(.error),
.input-file .form-control-file+label:not(.error),
.input-file .label-input-file,
.input-file input[type=file]+label:not(.error) {
   font-weight: 600;
   letter-spacing: .02em;
   color: #fff !important;
   display: inline-block
}

.input-file.input-file-image img.img-upload-preview {
   max-width: 100%;
   display: block;
   margin-bottom: 15px;
   box-shadow: 0 1px 15px 1px rgba(39, 39, 39, .1)
}

.input-file.input-file-image img.img-upload-preview.img-circle {
   border-radius: 2000px
}

.form-control:disabled,
.form-control[readonly] {
   background: #e8e8e8 !important;
   border-color: #e8e8e8 !important
}

.form-control:disabled,
.form-control[readonly] {
   opacity: .6 !important
}

.input-group-text {
   font-size: 1rem
}

.input-group-text i.la {
   font-size: 21px
}

.input-group-text i[class*=flaticon] {
   font-size: 17px
}

.input-icon {
   position: relative
}

.input-icon .form-control:not(:first-child) {
   padding-left: 2.5rem
}

.input-icon .form-control:not(:last-child) {
   padding-right: 2.5rem
}

.input-icon .input-icon-addon {
   position: absolute;
   left: 1rem;
   top: 0;
   height: 100%;
   display: flex;
   align-items: center
}

.input-icon .input-icon-addon:last-child {
   left: auto;
   right: 1rem
}

label.error {
   color: #f25961 !important;
   font-size: 80% !important;
   margin-top: .5rem
}

.select2-input {
   position: relative
}

.select2-input label.error,
.select2-input label.success {
   position: absolute;
   bottom: -30px
}

.select2-input .select2 {
   margin-bottom: 15px
}

.dropzone {
   padding: 70px 60px 80px !important;
   border: 2px dashed rgba(0, 0, 0, .13) !important;
   background: 0 0 !important
}

.dropzone:hover {
   background: #fafafa !important;
   transition: all .5s !important
}

.dropzone .dz-message .icon {
   margin-bottom: 15px;
   font-size: 39px
}

.dropzone .dz-message .message {
   font-size: 34px;
   font-weight: 200
}

.dropzone .dz-message .note {
   font-size: 16px;
   margin-top: 18px;
   font-weight: 300
}

.note-editor.note-frame {
   border: 0 !important;
   box-shadow: none !important
}

.note-editor.note-frame .note-toolbar {
   padding: 0 !important;
   border-bottom: 0 !important
}

.note-editor.note-frame .note-btn {
   border: 1px solid #eee !important;
   background: #fafafa !important
}

.note-editor.note-frame .note-placeholder {
   margin-top: 15px !important
}

.note-editor.note-frame .note-codable {
   margin-top: 15px !important
}

.note-editor.note-frame .note-editing-area .note-editable {
   margin-top: 15px !important;
   border: 2px solid #eee !important
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
   vertical-align: middle
}

.table>tbody>tr>td,
.table>tbody>tr>th {
   padding: 16px 24px !important
}

.table>tfoot>tr>td,
.table>tfoot>tr>th {
   padding: 12px 24px !important
}

.table thead th {
   font-size: .95rem;
   text-transform: uppercase;
   letter-spacing: 1px;
   padding: 12px 24px !important;
   border-bottom-width: 1px;
   font-weight: 600
}

.table td,
.table th {
   font-size: 1rem;
   border-top-width: 0;
   border-bottom: 1px solid;
   border-color: #ebedf2 !important;
   vertical-align: middle !important
}

.table .thead-light th {
   background-color: #f6f9fc;
   color: #8898aa;
   border-color: #ebecec !important
}

.table .thead-dark th {
   background-color: #1a2035;
   color: #fff
}

.table-full-width {
   margin-left: -15px;
   margin-right: -15px
}

.table-bordered-bd-black td,
.table-bordered-bd-black th {
   border: 1px solid #1a2035 !important
}

.table-bordered-bd-primary td,
.table-bordered-bd-primary th {
   border: 1px solid #0033CC !important
}

.table-bordered-bd-secondary td,
.table-bordered-bd-secondary th {
   border: 1px solid #6861ce !important
}

.table-bordered-bd-info td,
.table-bordered-bd-info th {
   border: 1px solid #48abf7 !important
}

.table-bordered-bd-success td,
.table-bordered-bd-success th {
   border: 1px solid #196c1b !important
}

.table-bordered-bd-warning td,
.table-bordered-bd-warning th {
   border: 1px solid #ffad46 !important
}

.table-bordered-bd-danger td,
.table-bordered-bd-danger th {
   border: 1px solid #f25961 !important
}

.table-striped td,
.table-striped th {
   border-top: 0 !important;
   border-bottom: 0 !important
}

.table-head-bg-black thead {
   border: 1px solid #1a2035 !important
}

.table-head-bg-primary thead {
   border: 1px solid #0033CC !important
}

.table-head-bg-secondary thead {
   border: 1px solid #6861ce !important
}

.table-head-bg-info thead {
   border: 1px solid #48abf7 !important
}

.table-head-bg-success thead {
   border: 1px solid #196c1b !important
}

.table-head-bg-warning thead {
   border: 1px solid #ffad46 !important
}

.table-head-bg-danger thead {
   border: 1px solid #f25961 !important
}

.table-head-bg-black thead th,
.table-striped-bg-black tbody tr:nth-of-type(odd) {
   background: #1a2035 !important;
   color: #fff !important;
   border: 0 !important
}

.table-head-bg-primary thead th,
.table-striped-bg-primary tbody tr:nth-of-type(odd) {
   background: #0033CC !important;
   color: #fff !important;
   border: 0 !important
}

.table-head-bg-secondary thead th,
.table-striped-bg-secondary tbody tr:nth-of-type(odd) {
   background: #6861ce !important;
   color: #fff !important;
   border: 0 !important
}

.table-head-bg-info thead th,
.table-striped-bg-info tbody tr:nth-of-type(odd) {
   background: #48abf7 !important;
   color: #fff !important;
   border: 0 !important
}

.table-head-bg-success thead th,
.table-striped-bg-success tbody tr:nth-of-type(odd) {
   background: #196c1b !important;
   color: #fff !important;
   border: 0 !important
}

.table-head-bg-warning thead th,
.table-striped-bg-warning tbody tr:nth-of-type(odd) {
   background: #ffad46 !important;
   color: #fff !important;
   border: 0 !important
}

.table-head-bg-danger thead th,
.table-striped-bg-danger tbody tr:nth-of-type(odd) {
   background: #f25961 !important;
   color: #fff !important;
   border: 0 !important
}

.table-responsive {
   width: 100% !important;
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
   -ms-overflow-style: -ms-autohiding-scrollbar
}

.navbar .navbar-nav .nav-item {
   padding: 0 4px
}

.navbar .navbar-nav .nav-item .nav-link {
   display: inline-block;
   vertical-align: middle;
   color: #8a95a0;
   padding: 8px 14px;
   border-radius: 3px;
   position: relative;
   font-size: 13px;
   font-weight: 400;
   text-align: center;
   transition: all .3s
}

.navbar .navbar-nav .nav-item .nav-link:focus,
.navbar .navbar-nav .nav-item .nav-link:hover {
   background: rgba(77, 89, 149, .06)
}

.navbar .navbar-nav .nav-item .nav-link i {
   font-size: 16px;
   vertical-align: middle;
   line-height: 1 !important
}

.navbar .navbar-nav .nav-item.active .nav-link {
   background: rgba(77, 89, 149, .06)
}

.navbar .navbar-nav .topbar-icon {
   padding: 0
}

.navbar .navbar-nav .topbar-icon .nav-link {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: unset;
   height: 44px;
   width: 44px
}

.navbar .navbar-nav .topbar-user .profile-pic {
   display: flex;
   align-items: center;
   padding: 0 8px;
   height: 44px;
   border-radius: 3px;
   color: #000
}

.navbar .navbar-nav .topbar-user .profile-pic:focus,
.navbar .navbar-nav .topbar-user .profile-pic:hover {
   background: rgba(77, 89, 149, .06)
}

.navbar .navbar-nav .topbar-user .profile-username {
   margin-left: 8px
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
   left: auto;
   right: 0;
   z-index: 1001
}

.dropdown-item {
   font-size: 13px
}

.navbar .navbar-nav .notification {
   position: absolute;
   background-color: #196c1b;
   text-align: center;
   border-radius: 10px;
   min-width: 17px;
   height: 17px;
   font-size: 10px;
   color: #fff;
   font-weight: 300;
   line-height: 17px;
   top: 3px;
   right: 3px;
   letter-spacing: -1px
}

.navbar-header {
   padding: 0 30px
}

.navbar-header .container-fluid {
   min-height: inherit;
   padding: 0 !important
}

.navbar-header .dropdown-toggle::after {
   margin-left: 0
}

.profile-pic:focus,
.profile-pic:hover {
   text-decoration: none
}

.profile-pic span {
   font-size: 1rem
}

.navbar-header .dropdown-toggle::after {
   vertical-align: middle;
   color: #555
}

.hidden-caret .dropdown-toggle::after {
   display: none !important
}

.navbar[class*=bg-] {
   border-bottom: 1px solid rgba(255, 255, 255, .2) !important;
   border-left: 1px solid rgba(255, 255, 255, .1) !important
}

.navbar[class*=bg-] .navbar-brand {
   color: #fff
}

.navbar[class*=bg-] .navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar[class*=bg-] .navbar-nav>.nav-item>.nav-link {
   color: #fff
}

.navbar[class*=bg-] .navbar-nav>.nav-item>.nav-link.disabled {
   color: #d8d8d8 !important
}

.navbar[class*=bg-] .navbar-nav>.nav-item>.nav-link:hover {
   background: rgba(255, 255, 255, .22) !important
}

.navbar[class*=bg-] .navbar-nav>.nav-item.active>.nav-link {
   background: rgba(255, 255, 255, .22) !important
}

.navbar[class*=bg-] .btn-toggle {
   background: rgba(19, 19, 19, .25) !important;
   color: #fff !important
}

.navbar[class*=bg-] .nav-search .input-group {
   border: 0;
   background: rgba(19, 19, 19, .25) !important
}

.navbar[class*=bg-] .nav-search .input-group .form-control {
   color: #fff !important
}

.navbar[class*=bg-] .nav-search .search-icon {
   color: #fff !important
}

.navbar-nav.page-navigation.page-navigation-black .nav-item.active .nav-link {
   color: #1a2035 !important
}

.navbar-nav.page-navigation.page-navigation-black .nav-item .nav-link:hover {
   color: #1a2035 !important
}

.navbar-nav.page-navigation.page-navigation-primary .nav-item.active .nav-link {
   color: #0033CC !important
}

.navbar-nav.page-navigation.page-navigation-primary .nav-item .nav-link:hover {
   color: #0033CC !important
}

.navbar-nav.page-navigation.page-navigation-secondary .nav-item.active .nav-link {
   color: #6861ce !important
}

.navbar-nav.page-navigation.page-navigation-secondary .nav-item .nav-link:hover {
   color: #6861ce !important
}

.navbar-nav.page-navigation.page-navigation-success .nav-item.active .nav-link {
   color: #196c1b !important
}

.navbar-nav.page-navigation.page-navigation-success .nav-item .nav-link:hover {
   color: #196c1b !important
}

.navbar-nav.page-navigation.page-navigation-warning .nav-item.active .nav-link {
   color: #ffad46 !important
}

.navbar-nav.page-navigation.page-navigation-warning .nav-item .nav-link:hover {
   color: #ffad46 !important
}

.navbar-nav.page-navigation.page-navigation-danger .nav-item.active .nav-link {
   color: #f25961 !important
}

.navbar-nav.page-navigation.page-navigation-danger .nav-item .nav-link:hover {
   color: #f25961 !important
}

.navbar-nav.page-navigation.page-navigation-info .nav-item.active .nav-link {
   color: #48abf7 !important
}

.navbar-nav.page-navigation.page-navigation-info .nav-item .nav-link:hover {
   color: #48abf7 !important
}

.navbar-line {
   min-height: inherit
}

.navbar-line .navbar-nav {
   min-height: inherit
}

.navbar-line .navbar-nav.page-navigation .nav-item {
   min-height: 100%;
   display: flex;
   align-items: center
}

.navbar-line .navbar-nav.page-navigation .nav-item .nav-link {
   padding: 10px 20px;
   background: 0 0 !important;
   font-weight: 600
}

.navbar-line .navbar-nav.page-navigation .nav-item .nav-link:first-child {
   margin-left: -20px
}

.navbar-line .navbar-nav.page-navigation .nav-item.active {
   position: relative
}

.navbar-line .navbar-nav.page-navigation .nav-item.active::before {
   height: 2px;
   width: calc(100% - 30px);
   bottom: 0;
   transform: translateX(-50%);
   margin-left: -10px;
   left: 50%;
   background: #0033CC;
   content: '';
   position: absolute
}

.navbar-line .navbar-nav.page-navigation .nav-item.active::before .nav-link {
   color: #fff !important
}

.navbar-line .navbar-nav.page-navigation .nav-item.active:first-child::before {
   width: calc(100% - 30px);
   margin-left: -10px
}

.navbar-line .navbar-nav.page-navigation .nav-item:not(.active) .nav-link {
   font-weight: 400;
   color: rgba(255, 255, 255, .85) !important;
   transition: all .3s
}

.navbar-line .navbar-nav.page-navigation .nav-item:not(.active) .nav-link:hover {
   color: #fff !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-black .nav-item.active::before {
   background: #1a2035 !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-primary .nav-item.active::before {
   background: #0033CC !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-secondary .nav-item.active::before {
   background: #6861ce !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-success .nav-item.active::before {
   background: #196c1b !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-warning .nav-item.active::before {
   background: #ffad46 !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-danger .nav-item.active::before {
   background: #f25961 !important
}

.navbar-line .navbar-nav.page-navigation.page-navigation-info .nav-item.active::before {
   background: #48abf7 !important
}

.navbar-header:not([data-background-color]) .navbar-line .navbar-nav.page-navigation .active .nav-link,
.navbar-header[data-background-color=white] .navbar-line .navbar-nav.page-navigation .active .nav-link {
   color: #666
}

.navbar-header:not([data-background-color]) .navbar-line .navbar-nav.page-navigation .nav-item:not(.active) .nav-link,
.navbar-header[data-background-color=white] .navbar-line .navbar-nav.page-navigation .nav-item:not(.active) .nav-link {
   color: #9ea2ad !important
}

.navbar-header:not([data-background-color]) .navbar-line .navbar-nav.page-navigation .nav-item:not(.active) .nav-link:hover,
.navbar-header[data-background-color=white] .navbar-line .navbar-nav.page-navigation .nav-item:not(.active) .nav-link:hover {
   color: #666 !important
}

.nav-search .input-group {
   border: 1px solid #e6e7e9;
   background: #f9f9f9;
   border-radius: 5px
}

.nav-search .input-group:focus,
.nav-search .input-group:hover {
   border: 1px solid #ddd
}

.nav-search.nav-search-round .input-group {
   border-radius: 50px
}

.nav-search .form-control {
   border: 0;
   background: 0 0 !important;
   font-size: 1rem;
   padding: .75em 1em;
   min-width: 250px;
   max-width: 100%
}

.nav-search .input-group-text {
   border: 0;
   background: 0 0
}

.nav-search .search-icon {
   font-size: 18px;
   color: #4a4a4a
}

.nav-search .btn-search {
   background: 0 0 !important;
   padding: .375rem 1rem;
   display: flex;
   align-items: center;
   height: 100%
}

.quick-search {
   display: flex;
   align-items: center;
   width: 225px
}

.quick-search .input-group {
   background: #eee;
   border-radius: 5px
}

.quick-search .btn-search {
   background: 0 0;
   padding: .5rem 1rem
}

.quick-search .search-icon {
   font-size: 16px
}

.quick-search input.form-control {
   background: 0 0;
   border: 0;
   padding: .5rem 0
}

.dropdown-search {
   min-width: 350px;
   padding: 5px 0
}

.dropdown-search .nav-search .input-group {
   background: 0 0 !important;
   box-shadow: none !important;
   border: 0 !important
}

.dropdown-search .nav-search .input-group .form-control {
   color: inherit !important
}

.dropdown-search .nav-search .input-group .form-control::-webkit-input-placeholder {
   color: #bfbfbf !important
}

.dropdown-search .nav-search .input-group .form-control::-moz-placeholder {
   color: #bfbfbf !important
}

.dropdown-search .nav-search .input-group .form-control:-ms-input-placeholder {
   color: #bfbfbf !important
}

.dropdown-search .nav-search .input-group .form-control:-moz-placeholder {
   color: #bfbfbf !important
}

.badge {
   border-radius: 5px;
   margin-left: auto;
   line-height: 1;
   padding: 4px 7px;
   vertical-align: middle;
   font-weight: 400;
   font-size: 11px;
   border: 1px solid #ddd;
   height: fit-content !important
}

[class*=badge-]:not(.badge-count) {
   border: 0 !important
}

.badge-count {
   color: inherit !important
}

.badge-black {
   background: #1a2035;
   color: #fff !important
}

.badge-primary {
   background: #0033CC
}

.badge-secondary {
   background: #6861ce
}

.badge-info {
   background: #48abf7
}

.badge-success {
   background-color: #196c1b
}

.badge-warning {
   background: #ffad46
}

.badge-danger {
   background-color: #f25961
}

.dropdown-menu {
   border: 0;
   border-radius: 3px;
   box-shadow: 0 1px 11px rgba(0, 0, 0, .15) !important;
   padding-bottom: 5px;
   margin-top: 3px
}

.dropdown-title {
   border-bottom: 1px solid #f1f1f1;
   color: #444;
   font-size: 1rem;
   font-weight: 600;
   padding: 12px 15px;
   text-align: center
}

.messages-notif-box,
.notif-box {
   width: 280px;
   padding: 0 !important
}

.messages-notif-box .notif-center a,
.notif-box .notif-center a {
   display: flex;
   color: #4d585f
}

.messages-notif-box .notif-center a:hover,
.notif-box .notif-center a:hover {
   text-decoration: none;
   background: #fafafa;
   transition: all .2s
}

.messages-notif-box .notif-center a .notif-icon,
.notif-box .notif-center a .notif-icon {
   display: inline-flex;
   width: 40px;
   height: 40px;
   margin: 10px;
   align-items: center;
   justify-content: center;
   background: #eee;
   border-radius: 50%
}

.messages-notif-box .notif-center a .notif-img,
.notif-box .notif-center a .notif-img {
   display: inline-flex;
   width: 40px;
   height: 40px;
   margin: 10px;
   align-items: center;
   justify-content: center;
   background: #eee;
   border-radius: 50%
}

.messages-notif-box .notif-center a .notif-img img,
.notif-box .notif-center a .notif-img img {
   width: 100%;
   height: 100%;
   border-radius: 50%
}

.messages-notif-box .notif-center a .notif-icon.notif-black,
.messages-notif-box .notif-center a .notif-icon.notif-danger,
.messages-notif-box .notif-center a .notif-icon.notif-info,
.messages-notif-box .notif-center a .notif-icon.notif-primary,
.messages-notif-box .notif-center a .notif-icon.notif-secondary,
.messages-notif-box .notif-center a .notif-icon.notif-success,
.messages-notif-box .notif-center a .notif-icon.notif-warning,
.notif-box .notif-center a .notif-icon.notif-black,
.notif-box .notif-center a .notif-icon.notif-danger,
.notif-box .notif-center a .notif-icon.notif-info,
.notif-box .notif-center a .notif-icon.notif-primary,
.notif-box .notif-center a .notif-icon.notif-secondary,
.notif-box .notif-center a .notif-icon.notif-success,
.notif-box .notif-center a .notif-icon.notif-warning {
   color: #fff !important
}

.messages-notif-box .notif-center a .notif-icon.notif-black,
.notif-box .notif-center a .notif-icon.notif-black {
   background: #1a2035 !important
}

.messages-notif-box .notif-center a .notif-icon.notif-primary,
.notif-box .notif-center a .notif-icon.notif-primary {
   background: #0033CC !important
}

.messages-notif-box .notif-center a .notif-icon.notif-secondary,
.notif-box .notif-center a .notif-icon.notif-secondary {
   background: #6861ce !important
}

.messages-notif-box .notif-center a .notif-icon.notif-info,
.notif-box .notif-center a .notif-icon.notif-info {
   background: #48abf7 !important
}

.messages-notif-box .notif-center a .notif-icon.notif-success,
.notif-box .notif-center a .notif-icon.notif-success {
   background: #196c1b !important
}

.messages-notif-box .notif-center a .notif-icon.notif-warning,
.notif-box .notif-center a .notif-icon.notif-warning {
   background: #ffad46 !important
}

.messages-notif-box .notif-center a .notif-icon.notif-danger,
.notif-box .notif-center a .notif-icon.notif-danger {
   background: #f25961 !important
}

.messages-notif-box .notif-center a .notif-icon i,
.notif-box .notif-center a .notif-icon i {
   font-size: 15px
}

.messages-notif-box .notif-center a .notif-content,
.notif-box .notif-center a .notif-content {
   padding: 10px 15px 10px 0
}

.messages-notif-box .notif-center a .message-content,
.notif-box .notif-center a .message-content {
   padding: 7px 15px 10px 0
}

.messages-notif-box .notif-center a .notif-content .subject,
.notif-box .notif-center a .notif-content .subject {
   font-size: 13px;
   font-weight: 600;
   display: block;
   margin-bottom: 2px
}

.messages-notif-box .notif-center a .notif-content .block,
.notif-box .notif-center a .notif-content .block {
   font-size: 13px;
   line-height: 20px;
   display: block
}

.messages-notif-box .notif-center a .notif-content .time,
.notif-box .notif-center a .notif-content .time {
   color: #7d8c95;
   font-size: 11px
}

.messages-notif-box .see-all,
.notif-box .see-all {
   border-top: 1px solid #f1f1f1;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 20px;
   color: #555;
   font-size: 13px;
   font-weight: 400;
   text-decoration: none
}

.messages-notif-box .see-all:hover,
.notif-box .see-all:hover {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 10px 20px;
   color: #555;
   font-size: 13px;
   font-weight: 400;
   text-decoration: none
}

.messages-notif-box .see-all i,
.notif-box .see-all i {
   float: right
}

.notif-box .notif-scroll {
   max-height: 256px
}

.messages-notif-box .message-notif-scroll {
   max-height: 250px
}

.messages-notif-box .notif-center a {
   border-bottom: 1px solid #f1f1f1
}

.messages-notif-box .notif-center a:last-child {
   border-bottom: 0
}

.messages-notif-box .notif-center a .notif-content {
   padding: 7px 15px 7px 5px
}

.dropdown-user {
   width: 260px
}

.user-box {
   display: flex;
   padding: .25rem 1rem
}

.user-box .u-text {
   padding: 0 10px
}

.user-box .u-text h4 {
   margin-bottom: 5px;
   margin-top: 4px;
   font-size: 1rem;
   font-weight: 400;
   line-height: 1
}

.user-box .u-text .text-muted {
   font-size: 12px;
   margin-bottom: 5px
}

.user-box .u-text .btn {
   font-size: 11px
}

.caret {
   display: inline-block;
   width: 0;
   height: 0;
   margin-left: .255em;
   vertical-align: .255em;
   content: "";
   border-top: .3em solid;
   border-right: .3em solid transparent;
   border-bottom: 0;
   border-left: .3em solid transparent
}

.quick-actions {
   width: 350px;
   padding: 0
}

.quick-actions:after {
   border-bottom-color: #0033CC !important
}

.quick-actions .quick-actions-header {
   display: flex;
   align-items: center;
   flex-direction: column;
   background: #0033CC;
   color: #fff;
   padding: 15px;
   border-radius: 3px 3px 0 0
}

.quick-actions .quick-actions-header .title {
   font-size: 16px
}

.quick-actions .quick-actions-header .subtitle {
   font-size: 13px
}

.quick-actions .quick-actions-items {
   padding: 7.5px 0
}

.quick-actions a:hover {
   text-decoration: none
}

.quick-actions .quick-actions-item {
   display: flex;
   flex-direction: column;
   margin: 7.5px 10px;
   padding: 10px;
   align-items: center;
   color: #0033CC
}

.quick-actions .quick-actions-item i {
   color: #fff;
   font-size: 18px
}

.quick-actions .quick-actions-item .text {
   text-align: center;
   font-size: 1rem;
   margin-top: 12px
}

.quick-actions .quick-actions-item:hover .avatar-item {
   transform: scale(1.1)
}

.quick-actions .avatar-item {
   display: inline-flex;
   width: 48px;
   height: 48px;
   color: #fff;
   border-radius: .375rem;
   background-color: #0033CC;
   align-items: center;
   justify-content: center;
   transition: all .15s;
   box-shadow: 2px 2px 8px 0 rgba(31, 30, 30, .13) !important
}

.quick-actions.quick-actions-black:after {
   border-bottom-color: #1a2035 !important
}

.quick-actions.quick-actions-black .quick-actions-header {
   background: #1a2035
}

.quick-actions.quick-actions-black .quick-actions-item {
   color: #1a2035
}

.quick-actions.quick-actions-primary:after {
   border-bottom-color: #0033CC !important
}

.quick-actions.quick-actions-primary .quick-actions-header {
   background: #0033CC
}

.quick-actions.quick-actions-primary .quick-actions-item {
   color: #0033CC
}

.quick-actions.quick-actions-secondary:after {
   border-bottom-color: #6861ce !important
}

.quick-actions.quick-actions-secondary .quick-actions-header {
   background: #6861ce
}

.quick-actions.quick-actions-secondary .quick-actions-item {
   color: #6861ce
}

.quick-actions.quick-actions-info:after {
   border-bottom-color: #48abf7 !important
}

.quick-actions.quick-actions-info .quick-actions-header {
   background: #48abf7
}

.quick-actions.quick-actions-info .quick-actions-item {
   color: #48abf7
}

.quick-actions.quick-actions-warning:after {
   border-bottom-color: #ffad46 !important
}

.quick-actions.quick-actions-warning .quick-actions-header {
   background: #ffad46
}

.quick-actions.quick-actions-warning .quick-actions-item {
   color: #ffad46
}

.quick-actions.quick-actions-success:after {
   border-bottom-color: #196c1b !important
}

.quick-actions.quick-actions-success .quick-actions-header {
   background: #196c1b
}

.quick-actions.quick-actions-success .quick-actions-item {
   color: #196c1b
}

.quick-actions.quick-actions-danger:after {
   border-bottom-color: #f25961 !important
}

.quick-actions.quick-actions-danger .quick-actions-header {
   background: #f25961
}

.quick-actions.quick-actions-danger .quick-actions-item {
   color: #f25961
}

@media screen and (max-width:991.5px) {

   .messages-notif-box .message-notif-scroll,
   .notif-box .notif-scroll,
   .quick-actions .quick-actions-scroll {
      max-height: calc(100vh - 200px)
   }

   .dropdown-user .dropdown-user-scroll {
      max-height: calc(100vh - 132px)
   }
}

@media screen and (min-width:991px) {
   .navbar-header .dropdown-menu {
      margin-top: 13px
   }

   .navbar-header .dropdown-menu:after {
      border-bottom: 8px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: "";
      right: 10px;
      top: -8px;
      position: absolute;
      z-index: 1001
   }
}

.chart-circle {
   display: flex;
   justify-content: center
}

.chart-circle .circles-text {
   font-size: 25px !important
}

.chart-container {
   min-height: 300px;
   position: relative
}

.html-legend {
   display: inline-flex;
   align-items: center;
   justify-content: center;
   width: 100%;
   list-style: none;
   cursor: pointer;
   padding-left: 0;
   text-align: center;
   margin-top: 1rem
}

.html-legend li {
   justify-content: center;
   align-items: center;
   display: flex;
   vertical-align: middle;
   padding: 0 5px;
   margin-right: 5px;
   margin-bottom: 6px;
   color: #8d9498;
   font-size: 12px
}

.html-legend li.hidden {
   text-decoration: line-through
}

.html-legend li span {
   border-radius: 15px;
   display: inline-block;
   height: 15px;
   margin-right: 10px;
   width: 15px;
   vertical-align: top
}

.jqstooltip {
   box-sizing: content-box
}

.alert {
   border: 0;
   position: relative;
   padding: .95rem 1.25rem;
   border-radius: 1px;
   color: inherit;
   background-color: #fff;
   -webkit-box-shadow: 1px 1px 14px 0 rgba(18, 38, 63, .26);
   -moz-box-shadow: 1px 1px 14px 0 rgba(18, 38, 63, .26);
   box-shadow: 1px 1px 14px 0 rgba(18, 38, 63, .26)
}

.alert [data-notify=icon] {
   display: block
}

.alert [data-notify=icon]::before {
   line-height: 35px;
   font-size: 22px;
   display: block;
   left: 15px;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   width: 35px;
   height: 35px;
   border-radius: 30px;
   text-align: center;
   color: #fff
}

.alert [data-notify=title] {
   display: block;
   color: #2b2b2b;
   font-weight: 700;
   font-size: 1rem;
   margin-bottom: 5px
}

.alert [data-notify=message] {
   font-size: 13px;
   color: #908e8e
}

.alert .close {
   background: rgba(255, 255, 255, .8);
   width: 25px;
   height: 25px;
   line-height: 25px;
   top: 12px !important;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center
}

.alert-black {
   border-left: 4px solid #1a2035
}

.alert-black [data-notify=icon]:before {
   background: #1a2035
}

.alert-primary {
   border-left: 4px solid #0033CC
}

.alert-primary [data-notify=icon]:before {
   background: #0033CC
}

.alert-secondary {
   border-left: 4px solid #6861ce
}

.alert-secondary [data-notify=icon]:before {
   background: #6861ce
}

.alert-info {
   border-left: 4px solid #48abf7
}

.alert-info [data-notify=icon]:before {
   background: #48abf7
}

.alert-success {
   border-left: 4px solid #196c1b
}

.alert-success [data-notify=icon]:before {
   background: #196c1b
}

.alert-warning {
   border-left: 4px solid #ffad46
}

.alert-warning [data-notify=icon]:before {
   background: #ffad46
}

.alert-danger {
   border-left: 4px solid #f25961
}

.alert-danger [data-notify=icon]:before {
   background: #f25961
}

.btn {
   padding: .65rem 1.4rem;
   font-size: 1rem;
   font-weight: 500;
   opacity: 1;
   border-radius: 3px
}

.btn:focus,
.btn:hover {
   opacity: .8
}

.btn .btn-label {
   display: inline-block
}

.btn .btn-label i {
   font-size: 16px;
   vertical-align: middle;
   margin-right: 2px;
   margin-left: -2px;
   line-height: 0;
   margin-top: -2.5px
}

.btn .btn-label.just-icon i {
   margin-left: 0 !important;
   margin-right: 0 !important
}

.btn-lg {
   font-size: 15px;
   border-radius: 6px;
   padding: 12.5px 27.5px;
   font-weight: 400
}

.btn-lg .btn-label i {
   font-size: 27px;
   vertical-align: middle;
   margin-right: 2px;
   margin-left: -7px;
   line-height: 0;
   margin-top: -2.5px
}

.btn-lg .btn-label.just-icon i {
   margin-left: 0 !important;
   margin-right: 0 !important
}

.btn-sm {
   font-size: 11px;
   padding: 7px 13px
}

.btn-xs {
   font-size: 10px;
   padding: 5px 9px
}

.btn.disabled:hover,
.btn:hover:disabled {
   opacity: .65
}

.btn-icon {
   font-size: 1.25rem;
   height: 2.5125rem;
   line-height: normal;
   min-width: 2.5125rem;
   overflow: hidden;
   padding: 0;
   position: relative;
   width: 2.5125rem;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   text-decoration: none
}

.btn-icon.btn-lg {
   height: 2.75rem;
   min-width: 2.75rem;
   width: 2.75rem
}

.btn-icon.btn-sm {
   height: 2rem;
   min-width: 2rem;
   width: 2rem
}

.btn-icon.btn-xs {
   height: 1.6875rem;
   min-width: 1.6875rem;
   width: 1.6875rem
}

.btn-white {
   background: #fff !important;
   color: #1a2035 !important
}

.btn-white:disabled,
.btn-white:focus,
.btn-white:hover {
   background: #fff !important;
   color: #1a2035 !important
}

.btn-black {
   background: #1a2035 !important;
   color: #fff !important
}

.btn-black:disabled,
.btn-black:focus,
.btn-black:hover {
   background: #1a2035 !important;
   color: #fff !important
}

.btn-primary {
   background: #0033CC !important;
   border-color: #0033CC !important
}

.btn-primary:disabled,
.btn-primary:focus,
.btn-primary:hover {
   color: #fff !important;
   background: #0033CC !important;
   border-color: #0033CC !important
}

.btn-secondary {
   background: #0033CC !important;
   border-color: #0033CC !important
}

.btn-secondary:disabled,
.btn-secondary:focus,
.btn-secondary:hover {
   color: #fff !important;
   background: #0033CC !important;
   border-color: #0033CC !important
}

.btn-info {
   background: #48abf7 !important;
   border-color: #48abf7 !important;
   color: #fff !important
}

.btn-info:disabled,
.btn-info:focus,
.btn-info:hover {
   color: #fff !important;
   background: #48abf7 !important;
   border-color: #48abf7 !important
}

.btn-success {
   background: #196c1b !important;
   border-color: #196c1b !important
}

.btn-success:disabled,
.btn-success:focus,
.btn-success:hover {
   color: #fff !important;
   background: #196c1b !important;
   border-color: #196c1b !important
}

.btn-warning {
   background: #ffad46 !important;
   border-color: #ffad46 !important
}

.btn-warning:disabled,
.btn-warning:focus,
.btn-warning:hover {
   background: #ffad46 !important;
   border-color: #ffad46 !important
}

.btn-danger {
   background: #f25961 !important;
   border-color: #f25961 !important
}

.btn-danger:disabled,
.btn-danger:focus,
.btn-danger:hover {
   color: #fff !important;
   background: #f25961 !important;
   border-color: #f25961 !important
}

.btn-light {
   background: #fff !important;
   border-color: transparent
}

.btn-light:disabled,
.btn-light:focus,
.btn-light:hover {
   background: #ebecec !important;
   border-color: transparent
}

.btn-dropdown-card-header {
   padding: 0;
   background: 0 0;
   color: inherit;
   font-size: 15px
}

.btn-dropdown-card-header:after {
   display: none
}

.btn-clean {
   background: 0 0
}

.btn-clean:disabled,
.btn-clean:focus,
.btn-clean:hover {
   color: #0033CC;
   background: rgba(21, 114, 232, .05);
   opacity: 1
}

.btn-label-primary {
   background: rgba(21, 114, 232, .1);
   color: #0033CC !important;
   border-color: transparent
}

.btn-label-primary:disabled,
.btn-label-primary:focus,
.btn-label-primary:hover {
   background: #0033CC;
   color: #fff !important;
   opacity: 1
}

.btn-label-secondary {
   background: rgba(104, 97, 206, .1);
   color: #6861ce !important;
   border-color: transparent
}

.btn-label-secondary:disabled,
.btn-label-secondary:focus,
.btn-label-secondary:hover {
   background: #6861ce;
   color: #fff !important;
   opacity: 1
}

.btn-label-info {
   background: rgba(72, 171, 247, .1);
   color: #48abf7 !important;
   border-color: transparent
}

.btn-label-info:disabled,
.btn-label-info:focus,
.btn-label-info:hover {
   background: #48abf7;
   color: #fff !important;
   opacity: 1
}

.btn-label-success {
   background: rgba(49, 206, 54, .1);
   color: #196c1b !important;
   border-color: transparent
}

.btn-label-success:disabled,
.btn-label-success:focus,
.btn-label-success:hover {
   background: #196c1b;
   color: #fff !important;
   opacity: 1
}

.btn-label-warning {
   background: rgba(255, 173, 70, .1);
   color: #ffad46 !important;
   border-color: transparent
}

.btn-label-warning:disabled,
.btn-label-warning:focus,
.btn-label-warning:hover {
   background: #ffad46;
   color: #fff !important;
   opacity: 1
}

.btn-label-danger {
   background: rgba(242, 89, 97, .1);
   color: #f25961 !important;
   border-color: transparent
}

.btn-label-danger:disabled,
.btn-label-danger:focus,
.btn-label-danger:hover {
   background: #f25961;
   color: #fff !important;
   opacity: 1
}

.btn-label-light {
   background: rgba(255, 255, 255, .2);
   color: #fff
}

.btn-label-light:focus,
.btn-label-light:hover {
   background: #fff;
   color: #2a2f5b;
   opacity: 1
}

.btn-border {
   background: 0 0 !important
}

.btn-border:focus,
.btn-border:hover {
   background: 0 0 !important
}

.btn-border.btn-white {
   color: #fff !important;
   border: 1px solid #fff !important
}

.btn-border.btn-black {
   color: #1a2035 !important;
   border: 1px solid #1a2035 !important
}

.btn-border.btn-primary {
   color: #0033CC !important;
   border: 1px solid #0033CC !important
}

.btn-border.btn-secondary {
   color: #6861ce !important;
   border: 1px solid #6861ce !important
}

.btn-border.btn-info {
   color: #48abf7 !important;
   border: 1px solid #48abf7 !important
}

.btn-border.btn-success {
   color: #196c1b !important;
   border: 1px solid #196c1b !important
}

.btn-border.btn-warning {
   color: #ffad46 !important;
   border: 1px solid #ffad46 !important
}

.btn-border.btn-danger {
   color: #f25961 !important;
   border: 1px solid #f25961 !important
}

.btn-border.btn-light {
   border: 1px solid #efefef;
   background: #fff !important
}

.btn-round {
   border-radius: 100px !important
}

.btn-link {
   border: 0 !important;
   background: 0 0 !important
}

.btn-link:focus,
.btn-link:hover {
   text-decoration: underline !important;
   background: 0 0 !important;
   border: 0 !important
}

.btn-link.btn-black {
   color: #1a2035 !important
}

.btn-link.btn-black:hover {
   color: #1a2035 !important
}

.btn-link.btn-primary {
   color: #0033CC !important
}

.btn-link.btn-primary:hover {
   color: #0033CC !important
}

.btn-link.btn-secondary {
   color: #6861ce !important
}

.btn-link.btn-secondary:hover {
   color: #6861ce !important
}

.btn-link.btn-info {
   color: #48abf7 !important
}

.btn-link.btn-info:hover {
   color: #48abf7 !important
}

.btn-link.btn-success {
   color: #196c1b !important
}

.btn-link.btn-success:hover {
   color: #196c1b !important
}

.btn-link.btn-warning {
   color: #ffad46 !important
}

.btn-link.btn-warning:hover {
   color: #ffad46 !important
}

.btn-link.btn-danger {
   color: #f25961 !important
}

.btn-link.btn-danger:hover {
   color: #f25961 !important
}

.toggle-on.btn {
   color: #fff !important
}

.toggle-handle {
   background: #fff !important
}

.toggle-handle:hover {
   background: #fff !important
}

.btn-round .toggle-handle {
   border-radius: 50px
}

.btn-rounded {
   border-radius: 60px !important
}

.btn-full {
   width: 100%
}

.btn-no-radius {
   border-radius: 0
}

.nav-pills>li:first-child>.nav-link {
   border-radius: 4px 0 0 4px !important
}

.nav-pills>li:last-child>.nav-link {
   border-radius: 0 4px 4px 0 !important
}

.nav-link.disabled {
   color: #6c757d !important
}

.nav-pills .nav-link {
   padding: 10px 20px
}

.nav-pills>li>.nav-link {
   border-radius: 0 !important;
   margin-top: 5px;
   margin-bottom: 5px;
   border: 1px solid #0033CC;
   color: #585c5d
}

.nav-pills>li>.nav-link.active {
   background: #0033CC
}

.nav-pills>li>.nav-link:hover {
   background: rgba(222, 222, 222, .4)
}

.nav-pills.nav-pills-no-bd li {
   margin-left: 15px !important
}

.nav-pills.nav-pills-no-bd li .nav-link {
   border: 0 !important;
   border-radius: 50px !important;
   background: rgba(222, 222, 222, .4)
}

.nav-pills.nav-pills-no-bd li .nav-link.active {
   border-radius: 50px !important
}

.nav-pills.nav-pills-no-bd li:first-child {
   margin-left: 0 !important
}

.nav-pills.nav-pills-no-bd.nav-pills-icons .nav-link,
.nav-pills.nav-pills-no-bd.nav-pills-icons .nav-link.active {
   border-radius: 5px !important
}

.nav-pills.flex-column .nav-link {
   border-radius: 0 !important;
   border: 1px solid #0033CC;
   color: #585c5d;
   margin-top: -1px;
   text-align: center;
   word-wrap: normal;
   padding: 10px 0
}

.nav-pills.flex-column .nav-link:hover {
   background: rgba(222, 222, 222, .4)
}

.nav-pills.flex-column .nav-link.active {
   background: #0033CC
}

.nav-pills.flex-column .nav-link:first-child {
   border-radius: 4px 4px 0 0 !important
}

.nav-pills.flex-column .nav-link:last-child {
   border-radius: 0 0 4px 4px !important
}

.nav-pills.flex-column.nav-pills-no-bd .nav-link {
   border: 0 !important;
   border-radius: 50px !important;
   background: rgba(222, 222, 222, .4);
   margin-top: 5px;
   margin-bottom: 5px
}

.nav-pills.flex-column.nav-pills-no-bd .nav-link.active {
   border-radius: 50px !important
}

.nav-pills.flex-column.nav-pills-icons .nav-link,
.nav-pills.flex-column.nav-pills-icons .nav-link.active {
   border-radius: 5px !important
}

.nav-pills.nav-pills-icons .nav-link,
.nav-pills.nav-pills-icons .nav-link.active {
   border-radius: 5px !important;
   padding-top: 12px;
   padding-bottom: 12px
}

.nav-pills.nav-pills-icons i {
   display: block;
   text-align: center;
   font-size: 2em;
   line-height: 50px
}

.nav-pills.nav-black .nav-link,
.nav-pills.nav-danger .nav-link,
.nav-pills.nav-info .nav-link,
.nav-pills.nav-primary .nav-link,
.nav-pills.nav-secondary .nav-link,
.nav-pills.nav-success .nav-link,
.nav-pills.nav-warning .nav-link {
   border: 1px solid #eee
}

.nav-pills.nav-black .nav-link.active,
.nav-pills.nav-danger .nav-link.active,
.nav-pills.nav-info .nav-link.active,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-secondary .nav-link.active,
.nav-pills.nav-success .nav-link.active,
.nav-pills.nav-warning .nav-link.active {
   color: #fff !important
}

.nav-pills.nav-black .nav-link.active {
   background: #1a2035;
   border: 1px solid #1a2035
}

.nav-pills.nav-primary .nav-link.active {
   background: #0033CC;
   border: 1px solid #0033CC
}

.nav-pills.nav-secondary .nav-link.active {
   background: #6861ce;
   border: 1px solid #6861ce
}

.nav-pills.nav-info .nav-link.active {
   background: #48abf7;
   border: 1px solid #48abf7
}

.nav-pills.nav-success .nav-link.active {
   background: #196c1b;
   border: 1px solid #196c1b
}

.nav-pills.nav-warning .nav-link.active {
   background: #ffad46;
   border: 1px solid #ffad46
}

.nav-pills.nav-danger .nav-link.active {
   background: #f25961;
   border: 1px solid #f25961
}

.row-nav-line {
   margin-left: -20px;
   margin-right: -20px
}

.nav.nav-line .nav-link,
.nav.nav-simple .nav-link {
   padding: 18px 0 !important;
   margin-right: 40px;
   color: #6b6d7e;
   border-width: 0;
   font-size: 1rem;
   font-weight: 600
}

.nav.nav-line .nav-link:focus,
.nav.nav-line .nav-link:hover,
.nav.nav-simple .nav-link:focus,
.nav.nav-simple .nav-link:hover {
   color: #0033CC;
   border-width: 0
}

.nav.nav-line .nav-link.active,
.nav.nav-simple .nav-link.active {
   border-width: 0;
   background-color: transparent;
   color: #0033CC;
   border-radius: 0
}

.nav.nav-simple.nav-color-black .nav-link:focus,
.nav.nav-simple.nav-color-black .nav-link:hover {
   color: #1a2035
}

.nav.nav-simple.nav-color-black .nav-link.active {
   color: #1a2035
}

.nav.nav-simple.nav-color-primary .nav-link:focus,
.nav.nav-simple.nav-color-primary .nav-link:hover {
   color: #0033CC
}

.nav.nav-simple.nav-color-primary .nav-link.active {
   color: #0033CC
}

.nav.nav-simple.nav-color-secondary .nav-link:focus,
.nav.nav-simple.nav-color-secondary .nav-link:hover {
   color: #6861ce
}

.nav.nav-simple.nav-color-secondary .nav-link.active {
   color: #6861ce
}

.nav.nav-simple.nav-color-info .nav-link:focus,
.nav.nav-simple.nav-color-info .nav-link:hover {
   color: #48abf7
}

.nav.nav-simple.nav-color-info .nav-link.active {
   color: #48abf7
}

.nav.nav-simple.nav-color-success .nav-link:focus,
.nav.nav-simple.nav-color-success .nav-link:hover {
   color: #196c1b
}

.nav.nav-simple.nav-color-success .nav-link.active {
   color: #196c1b
}

.nav.nav-simple.nav-color-danger .nav-link:focus,
.nav.nav-simple.nav-color-danger .nav-link:hover {
   color: #f25961
}

.nav.nav-simple.nav-color-danger .nav-link.active {
   color: #f25961
}

.nav.nav-simple.nav-color-warning .nav-link:focus,
.nav.nav-simple.nav-color-warning .nav-link:hover {
   color: #ffad46
}

.nav.nav-simple.nav-color-warning .nav-link.active {
   color: #ffad46
}

.nav.nav-simple.nav-color-light .nav-link:focus,
.nav.nav-simple.nav-color-light .nav-link:hover {
   color: #fff
}

.nav.nav-simple.nav-color-light .nav-link.active {
   color: #fff
}

.nav.nav-simple.nav-color-light .nav-link {
   color: #f1f1f1
}

.nav.nav-line .nav-link.active {
   border-bottom: 3px solid #0033CC
}

.nav.nav-line.nav-color-black .nav-link:focus,
.nav.nav-line.nav-color-black .nav-link:hover {
   color: #1a2035
}

.nav.nav-line.nav-color-black .nav-link.active {
   color: #1a2035
}

.nav.nav-line.nav-color-black .nav-link.active {
   border-color: #1a2035
}

.nav.nav-line.nav-color-primary .nav-link:focus,
.nav.nav-line.nav-color-primary .nav-link:hover {
   color: #0033CC
}

.nav.nav-line.nav-color-primary .nav-link.active {
   color: #0033CC
}

.nav.nav-line.nav-color-primary .nav-link.active {
   border-color: #0033CC
}

.nav.nav-line.nav-color-secondary .nav-link:focus,
.nav.nav-line.nav-color-secondary .nav-link:hover {
   color: #6861ce
}

.nav.nav-line.nav-color-secondary .nav-link.active {
   color: #6861ce
}

.nav.nav-line.nav-color-secondary .nav-link.active {
   border-color: #6861ce
}

.nav.nav-line.nav-color-info .nav-link:focus,
.nav.nav-line.nav-color-info .nav-link:hover {
   color: #48abf7
}

.nav.nav-line.nav-color-info .nav-link.active {
   color: #48abf7
}

.nav.nav-line.nav-color-info .nav-link.active {
   border-color: #48abf7
}

.nav.nav-line.nav-color-success .nav-link:focus,
.nav.nav-line.nav-color-success .nav-link:hover {
   color: #196c1b
}

.nav.nav-line.nav-color-success .nav-link.active {
   color: #196c1b
}

.nav.nav-line.nav-color-success .nav-link.active {
   border-color: #196c1b
}

.nav.nav-line.nav-color-danger .nav-link:focus,
.nav.nav-line.nav-color-danger .nav-link:hover {
   color: #f25961
}

.nav.nav-line.nav-color-danger .nav-link.active {
   color: #f25961
}

.nav.nav-line.nav-color-danger .nav-link.active {
   border-color: #f25961
}

.nav.nav-line.nav-color-warning .nav-link:focus,
.nav.nav-line.nav-color-warning .nav-link:hover {
   color: #ffad46
}

.nav.nav-line.nav-color-warning .nav-link.active {
   color: #ffad46
}

.nav.nav-line.nav-color-warning .nav-link.active {
   border-color: #ffad46
}

.nav.nav-line.nav-color-light .nav-link:focus,
.nav.nav-line.nav-color-light .nav-link:hover {
   color: #fff
}

.nav.nav-line.nav-color-light .nav-link.active {
   color: #fff
}

.nav.nav-line.nav-color-light .nav-link.active {
   border-color: #fff
}

.nav.nav-line.nav-color-light .nav-link {
   color: #f1f1f1
}

.nav-sm .nav-link {
   font-size: 11px !important;
   padding: 8px 16px !important
}

.popover {
   max-width: 240px;
   line-height: 1.7;
   border: 0;
   box-shadow: 0 0 20px 1px rgba(69, 65, 78, .2)
}

.popover .popover-header {
   background: 0 0;
   font-size: 1rem;
   border-bottom: 0;
   text-transform: capitalize;
   margin-top: 5px;
   color: #aaa;
   font-weight: 400
}

.popover .popover-body {
   margin-bottom: 5px
}

.popover .popover-body p {
   font-size: 13px;
   margin-bottom: 1rem
}

.popover.bs-popover-bottom .arrow:before,
.popover.bs-popover-left .arrow:before,
.popover.bs-popover-right .arrow:before,
.popover.bs-popover-top .arrow:before {
   border: transparent
}

.popover.bs-popover-auto[x-placement^=right],
.popover.bs-popover-right {
   margin-left: 10px
}

.popover.bs-popover-auto[x-placement^=left],
.popover.bs-popover-left {
   margin-right: 10px
}

.popover.bs-popover-auto[x-placement^=top],
.popover.bs-popover-top {
   margin-bottom: 10px
}

.popover.bs-popover-auto[x-placement^=bottom],
.popover.bs-popover-bottom {
   margin-top: 10px
}

.progress {
   border-radius: 100px;
   height: 14px
}

.progress .progress-bar {
   border-radius: 100px
}

.progress.progress-sm {
   height: 8px
}

.progress.progress-lg {
   height: 20px
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li>a,
.pagination>li>span {
   border-radius: 100px !important;
   margin: 0 2px;
   color: #777;
   border-color: #ddd
}

.pagination>li>a {
   display: flex;
   align-items: center;
   justify-content: center
}

.pagination.pg-black>li.active:first-child>a,
.pagination.pg-black>li.active:first-child>span,
.pagination.pg-black>li.active:last-child>a,
.pagination.pg-black>li.active:last-child>span,
.pagination.pg-black>li.active>a,
.pagination.pg-black>li.active>span {
   background: #1a2035;
   border-color: #1a2035;
   color: #fff
}

.pagination.pg-primary>li.active:first-child>a,
.pagination.pg-primary>li.active:first-child>span,
.pagination.pg-primary>li.active:last-child>a,
.pagination.pg-primary>li.active:last-child>span,
.pagination.pg-primary>li.active>a,
.pagination.pg-primary>li.active>span {
   background: #0033CC;
   border-color: #0033CC;
   color: #fff
}

.pagination.pg-secondary>li.active:first-child>a,
.pagination.pg-secondary>li.active:first-child>span,
.pagination.pg-secondary>li.active:last-child>a,
.pagination.pg-secondary>li.active:last-child>span,
.pagination.pg-secondary>li.active>a,
.pagination.pg-secondary>li.active>span {
   background: #6861ce;
   border-color: #6861ce;
   color: #fff
}

.pagination.pg-info>li.active:first-child>a,
.pagination.pg-info>li.active:first-child>span,
.pagination.pg-info>li.active:last-child>a,
.pagination.pg-info>li.active:last-child>span,
.pagination.pg-info>li.active>a,
.pagination.pg-info>li.active>span {
   background: #48abf7;
   border-color: #48abf7;
   color: #fff
}

.pagination.pg-success>li.active:first-child>a,
.pagination.pg-success>li.active:first-child>span,
.pagination.pg-success>li.active:last-child>a,
.pagination.pg-success>li.active:last-child>span,
.pagination.pg-success>li.active>a,
.pagination.pg-success>li.active>span {
   background: #196c1b;
   border-color: #196c1b;
   color: #fff
}

.pagination.pg-warning>li.active:first-child>a,
.pagination.pg-warning>li.active:first-child>span,
.pagination.pg-warning>li.active:last-child>a,
.pagination.pg-warning>li.active:last-child>span,
.pagination.pg-warning>li.active>a,
.pagination.pg-warning>li.active>span {
   background: #ffad46;
   border-color: #ffad46;
   color: #fff
}

.pagination.pg-danger>li.active:first-child>a,
.pagination.pg-danger>li.active:first-child>span,
.pagination.pg-danger>li.active:last-child>a,
.pagination.pg-danger>li.active:last-child>span,
.pagination.pg-danger>li.active>a,
.pagination.pg-danger>li.active>span {
   background: #f25961;
   border-color: #f25961;
   color: #fff
}

.slider-black .ui-slider-range {
   background: #1a2035
}

.slider-primary .ui-slider-range {
   background: #0033CC
}

.slider-secondary .ui-slider-range {
   background: #6861ce
}

.slider-info .ui-slider-range {
   background: #48abf7
}

.slider-success .ui-slider-range {
   background: #196c1b
}

.slider-warning .ui-slider-range {
   background: #ffad46
}

.slider-danger .ui-slider-range {
   background: #f25961
}

.modal .bg-black .modal-title,
.modal .bg-danger .modal-title,
.modal .bg-info .modal-title,
.modal .bg-primary .modal-title,
.modal .bg-secondary .modal-title,
.modal .bg-success .modal-title,
.modal .bg-warning .modal-title {
   color: #fff !important
}

.modal-content {
   border-radius: .4rem;
   border: 0 !important
}

.rating>label {
   display: inline;
   color: #e9eaeb !important;
   line-height: 1;
   float: right;
   cursor: pointer
}

.rating>label:hover {
   color: #ffc600 !important;
   opacity: .5
}

.rating>label span {
   font-size: 18px
}

.rating input[type=checkbox],
.rating input[type=radio] {
   position: absolute;
   opacity: 0;
   z-index: -1
}

.rating input[type=checkbox]:checked~label,
.rating input[type=radio]:checked~label {
   color: #ffc600 !important
}

.activity-feed {
   padding: 15px;
   list-style: none
}

.activity-feed .feed-item {
   position: relative;
   padding-bottom: 20px;
   padding-left: 30px;
   border-left: 2px solid #e4e8eb
}

.activity-feed .feed-item:last-child {
   border-color: transparent
}

.activity-feed .feed-item::after {
   content: "";
   display: block;
   position: absolute;
   top: 0;
   left: -7px;
   width: 12px;
   height: 12px;
   border-radius: 50%;
   background: #177dff
}

.feed-item-black::after {
   background: #1a2035 !important
}

.feed-item-primary::after {
   background: #0033CC !important
}

.feed-item-secondary::after {
   background: #6861ce !important
}

.feed-item-success::after {
   background: #196c1b !important
}

.feed-item-danger::after {
   background: #f25961 !important
}

.feed-item-info::after {
   background: #48abf7 !important
}

.feed-item-warning::after {
   background: #ffad46 !important
}

.activity-feed .feed-item .date {
   display: block;
   position: relative;
   top: -5px;
   color: #8c96a3;
   text-transform: uppercase;
   font-size: 13px
}

.activity-feed .feed-item .text {
   position: relative;
   top: -3px
}

.timeline {
   list-style: none;
   padding: 20px 0 20px;
   position: relative
}

.timeline:before {
   top: 0;
   bottom: 0;
   position: absolute;
   content: " ";
   width: 3px;
   background-color: #eee;
   left: 50%;
   margin-left: -1.5px
}

.timeline>li {
   margin-bottom: 20px;
   position: relative
}

.timeline>li:before {
   content: " ";
   display: table
}

.timeline>li:after {
   content: " ";
   display: table;
   clear: both
}

.timeline>li:before {
   content: " ";
   display: table
}

.timeline>li:after {
   content: " ";
   display: table;
   clear: both
}

.timeline>li>.timeline-panel {
   width: 50%;
   float: left;
   border: 1px solid #eee;
   background: #fff;
   border-radius: 3px;
   padding: 20px;
   position: relative;
   -webkit-box-shadow: 0 1px 20px 1px rgba(69, 65, 78, .06);
   -moz-box-shadow: 0 1px 20px 1px rgba(69, 65, 78, .06);
   box-shadow: 0 1px 20px 1px rgba(69, 65, 78, .06)
}

.timeline>li.timeline-inverted+li:not(.timeline-inverted) {
   margin-top: -60px
}

.timeline>li:not(.timeline-inverted) {
   padding-right: 90px
}

.timeline>li:not(.timeline-inverted)+li.timeline-inverted {
   margin-top: -60px
}

.timeline>li.timeline-inverted {
   padding-left: 90px
}

.timeline>li.timeline-inverted>.timeline-panel {
   float: right
}

.timeline>li.timeline-inverted>.timeline-panel:before {
   border-left-width: 0;
   border-right-width: 15px;
   left: -15px;
   right: auto
}

.timeline>li.timeline-inverted>.timeline-panel:after {
   border-left-width: 0;
   border-right-width: 14px;
   left: -14px;
   right: auto
}

.timeline>li>.timeline-panel:before {
   position: absolute;
   top: 26px;
   right: -15px;
   display: inline-block;
   border-top: 15px solid transparent;
   border-left: 15px solid #eee;
   border-right: 0 solid #eee;
   border-bottom: 15px solid transparent;
   content: " "
}

.timeline>li>.timeline-panel:after {
   position: absolute;
   top: 27px;
   right: -14px;
   display: inline-block;
   border-top: 14px solid transparent;
   border-left: 14px solid #fff;
   border-right: 0 solid #fff;
   border-bottom: 14px solid transparent;
   content: " "
}

.timeline>li>.timeline-badge {
   color: #fff;
   width: 50px;
   height: 50px;
   line-height: 50px;
   font-size: 1.8em;
   text-align: center;
   position: absolute;
   top: 16px;
   left: 50%;
   margin-left: -25px;
   background-color: #999;
   z-index: 100;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center
}

.timeline>li>.timeline-badge i {
   color: #fff !important
}

.timeline>li>.timeline-badge i.fa,
.timeline>li>.timeline-badge i.fab,
.timeline>li>.timeline-badge i.fal,
.timeline>li>.timeline-badge i.far,
.timeline>li>.timeline-badge i.fas {
   font-size: .8em
}

.timeline-badge.black {
   background-color: #1a2035 !important
}

.timeline-badge.primary {
   background-color: #0033CC !important
}

.timeline-badge.secondary {
   background-color: #6861ce !important
}

.timeline-badge.success {
   background-color: #196c1b !important
}

.timeline-badge.warning {
   background-color: #ffad46 !important
}

.timeline-badge.danger {
   background-color: #f25961 !important
}

.timeline-badge.info {
   background-color: #48abf7 !important
}

.timeline-title {
   font-size: 17px;
   margin-top: 0;
   color: inherit
}

.timeline-heading i {
   font-size: 22px;
   display: inline-block;
   vertical-align: middle;
   margin-right: 5px
}

.timeline-body>p,
.timeline-body>ul {
   margin-bottom: 0
}

.timeline-body>p+p {
   margin-top: 5px
}

.full-screen-maps {
   height: 100vh !important
}

.vmap {
   width: 100%;
   min-height: 265px
}

.vmap>svg {
   margin: auto;
   display: flex;
   width: 100% !important
}

.vmap>svg>g {
   transition: all ease-in-out .2s
}

.jqvmap-label,
.jqvmap-pin {
   pointer-events: none
}

.jqvmap-label {
   position: absolute;
   display: none;
   -webkit-border-radius: 3px;
   -moz-border-radius: 3px;
   border-radius: 3px;
   background: #292929;
   color: #fff;
   font-size: smaller;
   padding: 3px
}

.jqvmap-zoomin,
.jqvmap-zoomout {
   position: absolute;
   left: 10px;
   border-radius: 4px;
   background: #fff;
   font-weight: 600;
   color: #8a95a0;
   border: 1px solid #e2dddd !important;
   cursor: pointer;
   line-height: 25px;
   text-align: center;
   font-size: 1.25rem;
   width: 25px;
   height: 25px
}

.jqvmap-zoomin {
   top: 15px
}

.jqvmap-zoomout {
   top: 45px
}

.jqvmap-region {
   cursor: pointer
}

.jqvmap-ajax_response {
   width: 100%;
   height: 500px
}

.card-invoice .invoice-header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 15px
}

.card-invoice .invoice-header .invoice-title {
   font-size: 27px;
   font-weight: 400
}

.card-invoice .invoice-header .invoice-logo {
   width: 150px;
   display: flex;
   align-items: center
}

.card-invoice .invoice-header .invoice-logo img {
   width: 100%
}

.card-invoice .sub {
   font-size: 1rem;
   margin-bottom: 8px;
   font-weight: 600
}

.card-invoice .info-invoice {
   padding-top: 15px;
   padding-bottom: 15px
}

.card-invoice .info-invoice p {
   font-size: 13px
}

.card-invoice .invoice-desc {
   text-align: right;
   font-size: 13px
}

.card-invoice .invoice-detail {
   width: 100%;
   display: block
}

.card-invoice .invoice-detail .invoice-top .title {
   font-size: 20px
}

.card-invoice .transfer-to .sub {
   font-size: 1rem;
   margin-bottom: 8px;
   font-weight: 600
}

.card-invoice .transfer-to .account-transfer>div span:first-child {
   font-weight: 600;
   font-size: 13px
}

.card-invoice .transfer-to .account-transfer>div span:last-child {
   font-size: 13px;
   float: right
}

.card-invoice .transfer-total {
   text-align: right;
   display: flex;
   flex-direction: column;
   justify-content: center
}

.card-invoice .transfer-total .sub {
   font-size: 1rem;
   margin-bottom: 8px;
   font-weight: 600
}

.card-invoice .transfer-total .price {
   font-size: 28px;
   color: #0033CC;
   padding: 7px 0;
   font-weight: 600
}

.card-invoice .transfer-total span {
   font-weight: 600;
   font-size: 13px
}

.card-invoice .card-body {
   padding: 0;
   border: 0 !important;
   width: 75%;
   margin: auto
}

.card-invoice .card-header {
   padding: 50px 0 20px;
   border: 0 !important;
   width: 75%;
   margin: auto
}

.card-invoice .card-footer {
   padding: 5px 0 50px;
   border: 0 !important;
   width: 75%;
   margin: auto
}

.list-group .list-group-header {
   font-size: 12px;
   font-weight: 600;
   padding: .75rem 1rem
}

.list-group .list-group-item {
   display: flex;
   align-items: stretch;
   border-width: 1px 0;
   border-color: #ebecec
}

.list-group .list-group-item-figure {
   align-self: start;
   display: flex;
   -ms-flex-align: center;
   align-items: center;
   color: #a9acb0
}

.list-group .list-group-item-body {
   flex: 1;
   min-width: 0;
   align-self: center;
   font-size: .875rem
}

.list-group .list-group-item-text {
   margin-bottom: 0;
   line-height: 1.25rem;
   color: #686f76
}

.list-group-file-item .list-group-item {
   padding: 0
}

.list-group-file-item .list-group-item-figure {
   padding: .75rem 1rem
}

.list-group-file-item .list-group-item-body {
   padding: .75rem 0
}

.list-group-bordered .list-group-item {
   border: 1px solid #e3ebf6
}

.list-group-bordered .list-group-item.active {
   background: #0033CC;
   border-color: #0033CC
}

.list-group-lg .list-group-item {
   padding-top: 1.5rem;
   padding-bottom: 1.5rem
}

.selectgroup {
   display: -ms-inline-flexbox;
   display: inline-flex
}

.selectgroup-item {
   -ms-flex-positive: 1;
   flex-grow: 1;
   position: relative;
   font-weight: 400 !important
}

.selectgroup-item+.selectgroup-item {
   margin-left: -1px
}

.selectgroup-item:not(:first-child) .selectgroup-button {
   border-top-left-radius: 0;
   border-bottom-left-radius: 0
}

.selectgroup-item:not(:last-child) .selectgroup-button {
   border-top-right-radius: 0;
   border-bottom-right-radius: 0
}

.selectgroup-input {
   opacity: 0;
   position: absolute;
   z-index: -1;
   top: 0;
   left: 0
}

.selectgroup-button {
   display: block;
   border: 1px solid rgba(0, 40, 100, .12);
   text-align: center;
   padding: .375rem 1rem;
   position: relative;
   cursor: pointer;
   border-radius: 3px;
   color: #9aa0ac;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   font-size: 1rem;
   line-height: 1.5rem;
   min-width: 2.375rem
}

.selectgroup-button-icon {
   padding-left: .5rem;
   padding-right: .5rem;
   font-size: 1rem
}

.selectgroup-input:checked+.selectgroup-button {
   border-color: #0033CC;
   z-index: 1;
   color: #0033CC;
   background: rgba(21, 114, 232, .15)
}

.selectgroup-input:focus+.selectgroup-button {
   border-color: #0033CC;
   z-index: 2;
   color: #0033CC;
   box-shadow: 0 0 0 2px rgba(21, 114, 232, .25)
}

.selectgroup-pills {
   -ms-flex-wrap: wrap;
   flex-wrap: wrap;
   -ms-flex-align: start;
   align-items: flex-start
}

.selectgroup-pills .selectgroup-item {
   margin-right: .5rem;
   -ms-flex-positive: 0;
   flex-grow: 0
}

.selectgroup-pills .selectgroup-button {
   border-radius: 50px !important
}

.selectgroup.selectgroup-primary .selectgroup-input:checked+.selectgroup-button {
   border-color: #0033CC;
   color: #0033CC;
   background: rgba(21, 114, 232, .15)
}

.selectgroup.selectgroup-primary .selectgroup-input:focus+.selectgroup-button {
   border-color: #0033CC;
   color: #0033CC;
   box-shadow: 0 0 0 2px rgba(21, 114, 232, .25)
}

.selectgroup.selectgroup-secondary .selectgroup-input:checked+.selectgroup-button {
   border-color: #6861ce;
   color: #6861ce;
   background: rgba(104, 97, 206, .15)
}

.selectgroup.selectgroup-secondary .selectgroup-input:focus+.selectgroup-button {
   border-color: #6861ce;
   color: #6861ce;
   box-shadow: 0 0 0 2px rgba(104, 97, 206, .25)
}

.selectgroup.selectgroup-info .selectgroup-input:checked+.selectgroup-button {
   border-color: #48abf7;
   color: #48abf7;
   background: rgba(72, 171, 247, .15)
}

.selectgroup.selectgroup-info .selectgroup-input:focus+.selectgroup-button {
   border-color: #48abf7;
   color: #48abf7;
   box-shadow: 0 0 0 2px rgba(72, 171, 247, .25)
}

.selectgroup.selectgroup-success .selectgroup-input:checked+.selectgroup-button {
   border-color: #196c1b;
   color: #196c1b;
   background: rgba(49, 206, 54, .15)
}

.selectgroup.selectgroup-success .selectgroup-input:focus+.selectgroup-button {
   border-color: #196c1b;
   color: #196c1b;
   box-shadow: 0 0 0 2px rgba(49, 206, 54, .25)
}

.selectgroup.selectgroup-warning .selectgroup-input:checked+.selectgroup-button {
   border-color: #ffad46;
   color: #ffad46;
   background: rgba(255, 173, 70, .15)
}

.selectgroup.selectgroup-warning .selectgroup-input:focus+.selectgroup-button {
   border-color: #ffad46;
   color: #ffad46;
   box-shadow: 0 0 0 2px rgba(255, 173, 70, .25)
}

.selectgroup.selectgroup-danger .selectgroup-input:checked+.selectgroup-button {
   border-color: #f25961;
   color: #f25961;
   background: rgba(242, 89, 97, .15)
}

.selectgroup.selectgroup-danger .selectgroup-input:focus+.selectgroup-button {
   border-color: #f25961;
   color: #f25961;
   box-shadow: 0 0 0 2px rgba(242, 89, 97, .25)
}

.colorinput {
   margin: 0;
   position: relative;
   cursor: pointer
}

.colorinput-input {
   position: absolute;
   z-index: -1;
   opacity: 0
}

.colorinput-color {
   display: inline-block;
   width: 1.75rem;
   height: 1.75rem;
   border-radius: 3px;
   border: 1px solid rgba(0, 40, 100, .12);
   color: #fff;
   box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .05)
}

.colorinput-color:before {
   content: '';
   opacity: 0;
   position: absolute;
   top: .25rem;
   left: .25rem;
   height: 1.25rem;
   width: 1.25rem;
   transition: .3s opacity;
   background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%
}

.colorinput-input:checked~.colorinput-color:before {
   opacity: 1
}

.colorinput-input:focus~.colorinput-color {
   border-color: #467fcf;
   box-shadow: 0 0 0 2px rgba(70, 127, 207, .25)
}

.imagecheck {
   margin: 0;
   position: relative;
   cursor: pointer
}

.imagecheck-input {
   position: absolute;
   z-index: -1;
   opacity: 0
}

.imagecheck-figure {
   border: 1px solid rgba(0, 40, 100, .12);
   border-radius: 3px;
   margin: 0;
   position: relative
}

.imagecheck-input:focus~.imagecheck-figure {
   border-color: #0033CC;
   box-shadow: 0 0 0 2px rgba(70, 127, 207, .25)
}

.imagecheck-input:checked~.imagecheck-figure {
   border-color: rgba(0, 40, 100, .24)
}

.imagecheck-figure:before {
   content: '';
   position: absolute;
   top: .25rem;
   left: .25rem;
   display: block;
   width: 1rem;
   height: 1rem;
   pointer-events: none;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   background: #0033CC url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
   color: #fff;
   z-index: 1;
   border-radius: 3px;
   opacity: 0;
   transition: .3s opacity
}

.imagecheck-input:checked~.imagecheck-figure:before {
   opacity: 1
}

.imagecheck-image {
   max-width: 100%;
   opacity: .64;
   transition: .3s opacity
}

.imagecheck-image:first-child {
   border-top-left-radius: 2px;
   border-top-right-radius: 2px
}

.imagecheck-image:last-child {
   border-bottom-left-radius: 2px;
   border-bottom-right-radius: 2px
}

.imagecheck:hover .imagecheck-image {
   opacity: 1
}

.imagecheck-input:checked~.imagecheck-figure .imagecheck-image,
.imagecheck-input:focus~.imagecheck-figure .imagecheck-image {
   opacity: 1
}

.imagecheck-caption {
   text-align: center;
   padding: .25rem .25rem;
   color: #9aa0ac;
   font-size: .875rem;
   transition: .3s color
}

.imagecheck:hover .imagecheck-caption {
   color: #495057
}

.imagecheck-input:checked~.imagecheck-figure .imagecheck-caption,
.imagecheck-input:focus~.imagecheck-figure .imagecheck-caption {
   color: #495057
}

.tab-chat {
   position: relative
}

.messages-contact {
   position: absolute;
   left: 0;
   width: 100%;
   transition: left .3s ease
}

.messages-contact .contact-list .user a {
   display: flex;
   flex-direction: row;
   align-items: center;
   padding: 11px 10px;
   border-radius: 5px
}

.messages-contact .contact-list .user a:hover {
   text-decoration: none;
   background: rgba(0, 0, 0, .05)
}

.messages-contact .contact-list .user a .user-data,
.messages-contact .contact-list .user a .user-data2 {
   margin-left: 20px;
   display: flex;
   flex-direction: column
}

.messages-contact .contact-list .user a .user-data .name,
.messages-contact .contact-list .user a .user-data2 .name {
   color: #2a2f5b;
   font-size: 13px;
   margin-bottom: 3px;
   font-weight: 600
}

.messages-contact .contact-list .user a .user-data .message,
.messages-contact .contact-list .user a .user-data2 .message {
   color: #aaa
}

.messages-contact .contact-list .user a .user-data .status,
.messages-contact .contact-list .user a .user-data2 .status {
   color: #979797
}

.messages-wrapper {
   position: absolute;
   left: calc(100% + 40px);
   transition: left .3s ease
}

.messages-wrapper .messages-title {
   width: 100%;
   display: inline-block;
   border-bottom: 1px solid #eee;
   margin-bottom: 15px
}

.messages-wrapper .messages-title .user {
   width: calc(100% - 40px);
   float: right;
   text-align: right;
   padding: 10px 0
}

.messages-wrapper .messages-title .user .name {
   display: inline-block;
   font-size: 13px;
   font-weight: 400;
   margin-bottom: 4px
}

.messages-wrapper .messages-title .user .last-active {
   display: block;
   font-size: 10px
}

.messages-wrapper .messages-title .return {
   background: 0 0;
   border: 0;
   font-size: 25px;
   padding: 10px 0;
   cursor: pointer
}

.messages-wrapper .messages-body {
   height: calc(100vh - 290px);
   display: block;
   overflow-y: auto
}

.conversations-body .message-content-wrapper,
.messages-wrapper .messages-body .message-content-wrapper {
   display: inline-block;
   width: 100%
}

.conversations-body .message,
.messages-wrapper .messages-body .message {
   display: table;
   table-layout: fixed;
   padding: 8px 0
}

.conversations-body .message-in,
.messages-wrapper .messages-body .message-in {
   margin-right: 40px;
   float: left
}

.conversations-body .message-in .message-body,
.messages-wrapper .messages-body .message-in .message-body {
   display: table-cell;
   vertical-align: top
}

.conversations-body .message-in .message-body .message-content,
.messages-wrapper .messages-body .message-in .message-body .message-content {
   background: #f7f7f7;
   padding: 12px 15px;
   border-radius: 5px;
   margin-left: 10px;
   position: relative;
   width: fit-content
}

.conversations-body .message-in .message-body .message-content:before,
.messages-wrapper .messages-body .message-in .message-body .message-content:before {
   width: 0;
   height: 0;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
   border-right: 10px solid #f7f7f7;
   content: "";
   position: absolute;
   left: -9px;
   top: 12px
}

.conversations-body .message-in .message-body .message-content .name,
.messages-wrapper .messages-body .message-in .message-body .message-content .name {
   color: #83848a;
   font-size: 11px;
   margin-bottom: 5px
}

.conversations-body .message-in .message-body .message-content .content,
.messages-wrapper .messages-body .message-in .message-body .message-content .content {
   font-size: 13px
}

.conversations-body .message-in .message-body .date,
.messages-wrapper .messages-body .message-in .message-body .date {
   margin-left: 10px;
   margin-top: 8px;
   font-size: 11px;
   color: #83848a;
   padding-left: 12px
}

.conversations-body .message-in .message-body .message-content+.message-content,
.messages-wrapper .messages-body .message-in .message-body .message-content+.message-content {
   margin-top: 10px
}

.conversations-body .message-in .message-body .message-content+.message-content:before,
.messages-wrapper .messages-body .message-in .message-body .message-content+.message-content:before {
   display: none
}

.conversations-body .message-out,
.messages-wrapper .messages-body .message-out {
   float: right;
   margin-left: 40px
}

.conversations-body .message-out .message-body,
.messages-wrapper .messages-body .message-out .message-body {
   display: table-cell;
   vertical-align: top;
   float: right
}

.conversations-body .message-out .message-body .message-content,
.messages-wrapper .messages-body .message-out .message-body .message-content {
   background: #0033CC;
   padding: 12px 15px;
   border-radius: 5px;
   margin-right: 10px;
   position: relative;
   width: fit-content
}

.conversations-body .message-out .message-body .message-content:before,
.messages-wrapper .messages-body .message-out .message-body .message-content:before {
   width: 0;
   height: 0;
   border-top: 10px solid transparent;
   border-bottom: 10px solid transparent;
   border-left: 10px solid #0033CC;
   content: "";
   position: absolute;
   right: -9px;
   top: 12px
}

.conversations-body .message-out .message-body .message-content .content,
.messages-wrapper .messages-body .message-out .message-body .message-content .content {
   font-size: 13px;
   color: #fff !important
}

.conversations-body .message-out .message-body .date,
.messages-wrapper .messages-body .message-out .message-body .date {
   margin-right: 10px;
   margin-top: 8px;
   font-size: 11px;
   color: #83848a;
   text-align: right;
   padding-right: 15px
}

.conversations-body .message-out .message-body .message-content+.message-content,
.messages-wrapper .messages-body .message-out .message-body .message-content+.message-content {
   margin-top: 10px
}

.conversations-body .message-out .message-body .message-content+.message-content:before,
.messages-wrapper .messages-body .message-out .message-body .message-content+.message-content:before {
   display: none
}

.messages-form {
   display: table;
   width: 100%;
   margin-top: 30px;
   border-top: 1px solid #eee;
   padding-top: 20px
}

.messages-form .messages-form-control {
   display: table-cell;
   padding-right: 15px
}

.messages-form .messages-form-tool {
   display: table-cell;
   text-align: right;
   width: 50px
}

.messages-form .messages-form-tool .attachment {
   height: 100%;
   line-height: 1;
   color: #888c91;
   background: #e8e8e8;
   font-size: 17px;
   padding: 10px 12px;
   border-radius: 50%;
   margin-left: auto
}

.show-chat .messages-contact {
   left: calc(-100% - 40px)
}

.show-chat .messages-wrapper {
   left: 0
}

.chat-contacts .chat-username {
   font-weight: 600
}

.chat-contacts .chat-msg {
   overflow: hidden;
   white-space: nowrap;
   max-width: 175px;
   text-overflow: ellipsis;
   margin-top: 4px;
   opacity: .8
}

.chat-contacts .list-group-item.unread {
   background: #f5f7fd
}

.list-group-messages .btn-dropdown {
   background: 0 0;
   border: 0;
   font-size: 16px;
   color: #b5b5b5;
   line-height: 1;
   cursor: pointer;
   padding: 4px 10px
}

.list-group-messages .list-group-item {
   padding-top: 1.25rem;
   padding-bottom: 1.25rem
}

.list-group-messages .list-group-item.unread {
   background: #f5f7fd
}

.list-group-messages .list-group-item.unread .list-group-item-title {
   font-weight: 600
}

.list-group-messages .list-group-item-title a {
   color: #2a2f5b
}

.conversations {
   display: flex;
   height: calc(100vh - 57px);
   flex-direction: column
}

.conversations .message-header {
   background: #fff;
   padding: .5rem;
   box-shadow: 0 1px 0 0 rgba(61, 70, 79, .075);
   z-index: 1
}

.conversations .message-title {
   width: 100%;
   display: flex;
   align-items: center;
   position: relative
}

.conversations .message-title .user {
   display: flex;
   align-items: center;
   justify-content: center
}

.conversations .message-title .user .name {
   display: block;
   font-size: 1rem;
   font-weight: 600;
   line-height: 24px;
   margin-bottom: 2px
}

.conversations .message-title .user .last-active {
   display: block;
   font-size: 11px
}

.conversations .message-title .return {
   background: 0 0;
   border: 0;
   font-size: 25px;
   cursor: pointer;
   height: 100%;
   top: 0
}

.conversations .conversations-body {
   flex: 1;
   overflow-y: auto;
   padding: 1.5rem 2rem
}

.conversations .conversations-content {
   border: 1px solid #eee;
   border-radius: 5px;
   padding: 1rem
}

.conversations .messages-form {
   margin: 0;
   padding: .7rem 1rem;
   background: #fff
}

.conversations-action {
   background: #f6f6f6;
   padding: 10px 0
}

.conversations-action .action {
   display: flex;
   padding: 1rem 1.5rem;
   background: #fff;
   box-shadow: 0 1px 3px rgba(0, 0, 0, .08);
   margin-bottom: 10px;
   cursor: pointer
}

.conversations-action .action:hover {
   background: #f4f5f5
}

.conversations-action .action span {
   font-size: 16px;
   line-height: 21px
}

.conversations-action .action i {
   font-size: 21px;
   width: 2.5rem;
   color: rgba(38, 50, 56, .5)
}

.conversations-action .action.danger i,
.conversations-action .action.danger span {
   color: #f25961
}

.conversations-action .action:last-child {
   margin-bottom: 0
}

@media screen and (max-width:991.5px) {
   .conversations {
      margin-left: -15px;
      margin-right: -15px
   }
}

.tasks-wrapper .tasks-scroll {
   height: calc(100vh - 130px);
   overflow: auto;
   margin-bottom: 15px
}

.tasks-wrapper .tasks-content {
   padding-bottom: 25px
}

.tasks-wrapper .tasks-content .tasks-list {
   padding: 0 10px;
   list-style: none
}

.tasks-wrapper .tasks-content .tasks-list li {
   position: relative;
   margin-bottom: 15px
}

.tasks-wrapper .tasks-content .tasks-list li .custom-control {
   position: unset !important
}

.tasks-wrapper .tasks-content .tasks-list li input[type=checkbox]:checked~.custom-control-label {
   text-decoration: line-through;
   color: #999
}

.tasks-wrapper .tasks-content .tasks-list li .custom-control.custom-checkbox {
   margin-right: 50px !important
}

.tasks-wrapper .tasks-content .tasks-list li .task-action {
   display: none;
   position: absolute;
   font-size: 17px;
   right: 0;
   top: 0
}

.tasks-wrapper .tasks-content .tasks-list li .task-action a.link {
   margin-left: 10px
}

.tasks-wrapper .tasks-content .tasks-list li .task-action a.link:hover {
   text-decoration: none;
   color: unset
}

.tasks-wrapper .tasks-content .tasks-list li:hover .task-action {
   display: block
}

.settings-wrapper .settings-content .settings-list {
   padding-left: 0;
   list-style: none
}

.settings-wrapper .settings-content .settings-list li {
   display: table;
   width: 100%;
   margin-bottom: 15px
}

.settings-wrapper .settings-content .settings-list li .item-label {
   display: table-cell;
   vertical-align: middle;
   font-size: 13px
}

.settings-wrapper .settings-content .settings-list li .item-control {
   display: table-cell;
   float: right;
   margin-right: 5px
}

.settings-wrapper .settings-content .settings-list li .item-control .toggle-group .toggle-off,
.settings-wrapper .settings-content .settings-list li .item-control .toggle-group .toggle-on {
   font-size: 11px !important
}

.is-loading:after,
.loader {
   display: block;
   width: 1.5rem;
   height: 1.5rem;
   background: 0 0;
   border: 3px solid #6861ce;
   border-bottom-color: transparent;
   border-radius: 50%;
   animation: 1s spin linear infinite
}

.is-loading-lg:after,
.loader-lg {
   width: 2rem;
   height: 2rem;
   border-width: 5px
}

.is-loading-sm:after,
.loader-sm {
   width: 1rem;
   height: 1rem;
   border-width: 2px
}

.is-loading {
   position: relative;
   color: transparent !important
}

.is-loading>* {
   opacity: .2 !important
}

.is-loading:after {
   position: absolute;
   top: calc(50% - 1.5rem/2);
   left: calc(50% - 1.5rem/2);
   content: ''
}

.is-loading-lg:after {
   top: calc(50% - 2rem/2);
   left: calc(50% - 2rem/2)
}

.is-loading-sm:after {
   top: calc(50% - 1rem/2);
   left: calc(50% - 1rem/2)
}

.btn-black.is-loading:after,
.btn-danger.is-loading:after,
.btn-info.is-loading:after,
.btn-primary.is-loading:after,
.btn-secondary.is-loading:after,
.btn-success.is-loading:after,
.btn-warning.is-loading:after,
.card-black.is-loading:after,
.card-danger.is-loading:after,
.card-info.is-loading:after,
.card-primary.is-loading:after,
.card-secondary.is-loading:after,
.card-success.is-loading:after,
.card-warning.is-loading:after,
.is-loading-black:after,
.is-loading-danger:after,
.is-loading-info:after,
.is-loading-primary:after,
.is-loading-secondary:after,
.is-loading-success:after,
.is-loading-warning:after,
.loader-black,
.loader-danger,
.loader-info,
.loader-primary,
.loader-secondary,
.loader-success,
.loader-warning {
   border-bottom-color: transparent !important
}

.btn-black.is-loading:after,
.btn-danger.is-loading:after,
.btn-info.is-loading:after,
.btn-primary.is-loading:after,
.btn-secondary.is-loading:after,
.btn-success.is-loading:after,
.btn-warning.is-loading:after,
.card-black.is-loading:after,
.card-danger.is-loading:after,
.card-info.is-loading:after,
.card-primary.is-loading:after,
.card-secondary.is-loading:after,
.card-success.is-loading:after,
.card-warning.is-loading:after {
   border-color: #fff
}

.is-loading-black:after,
.loader-black {
   border-color: #1a2035
}

.is-loading-primary:after,
.loader-primary {
   border-color: #0033CC
}

.is-loading-secondary:after,
.loader-secondary {
   border-color: #6861ce
}

.is-loading-info:after,
.loader-info {
   border-color: #48abf7
}

.is-loading-success:after,
.loader-success {
   border-color: #196c1b
}

.is-loading-warning:after,
.loader-warning {
   border-color: #ffad46
}

.is-loading-danger:after,
.loader-danger {
   border-color: #f25961
}

@keyframes spin {
   from {
      transform: rotate(0)
   }

   to {
      transform: rotate(360deg)
   }
}

.gg-menu {
   transform: scale(var(--ggs, 1));
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 20px;
   height: 2px;
   border-radius: 3px;
   background: currentColor
}

.gg-menu::after,
.gg-menu::before {
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 20px;
   height: 2px;
   border-radius: 3px;
   background: currentColor
}

.gg-menu::after,
.gg-menu::before {
   content: "";
   position: absolute;
   top: -6px
}

.gg-menu::after {
   top: 6px
}

.gg-menu-left {
   transform: scale(var(--ggs, 1));
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 20px;
   height: 2px;
   border-radius: 3px;
   background: currentColor
}

.gg-menu-left::after,
.gg-menu-left::before {
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 20px;
   height: 2px;
   border-radius: 3px;
   background: currentColor
}

.gg-menu-left::after,
.gg-menu-left::before {
   content: "";
   position: absolute;
   top: -6px;
   width: 10px
}

.gg-menu-left::after {
   top: 6px;
   width: 14px
}

.gg-menu-right {
   transform: scale(var(--ggs, 1));
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 20px;
   height: 2px;
   border-radius: 3px;
   background: currentColor
}

.gg-menu-right::after,
.gg-menu-right::before {
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 20px;
   height: 2px;
   border-radius: 3px;
   background: currentColor
}

.gg-menu-right::after,
.gg-menu-right::before {
   content: "";
   position: absolute;
   top: -6px;
   right: 0;
   width: 10px
}

.gg-menu-right::after {
   top: 6px;
   width: 14px
}

.gg-menu-left::after,
.gg-menu-left::before,
.gg-menu-right::after,
.gg-menu-right::before {
   transition: all .2s
}

.gg-menu-left:hover::after,
.gg-menu-left:hover::before,
.gg-menu-right:hover::after,
.gg-menu-right:hover::before {
   width: 100%
}

.gg-menu-round {
   box-sizing: border-box;
   position: relative;
   display: block;
   transform: scale(var(--ggs, 1));
   width: 22px;
   height: 22px;
   border-radius: 24px;
   border: 2px solid
}

.gg-menu-round::before {
   content: "";
   display: block;
   box-sizing: border-box;
   position: absolute;
   width: 10px;
   height: 2px;
   background: currentColor;
   border-radius: 3px;
   top: 4px;
   left: 4px;
   box-shadow: 0 4px 0, 0 8px 0
}

.gg-menu-boxed {
   box-sizing: border-box;
   position: relative;
   display: block;
   transform: scale(var(--ggs, 1));
   width: 18px;
   height: 18px;
   border-radius: 3px;
   border: 2px solid
}

.gg-menu-boxed::before {
   content: "";
   display: block;
   box-sizing: border-box;
   position: absolute;
   width: 10px;
   height: 2px;
   background: currentColor;
   border-radius: 3px;
   top: 2px;
   left: 2px;
   box-shadow: 0 4px 0, 0 8px 0
}

.gg-more-vertical-alt {
   transform: scale(var(--ggs, 1));
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 4px;
   height: 4px;
   background: currentColor;
   border-radius: 100%
}

.gg-more-vertical-alt::after,
.gg-more-vertical-alt::before {
   box-sizing: border-box;
   position: relative;
   display: block;
   width: 4px;
   height: 4px;
   background: currentColor;
   border-radius: 100%
}

.gg-more-vertical-alt::after,
.gg-more-vertical-alt::before {
   content: "";
   position: absolute
}

.gg-more-vertical-alt::after {
   left: 0;
   top: 6px
}

.gg-more-vertical-alt::before {
   top: -6px;
   right: 0
}

.gg-close {
   box-sizing: border-box;
   position: relative;
   display: block;
   transform: scale(var(--ggs, 1));
   width: 22px;
   height: 22px;
   border: 2px solid transparent;
   border-radius: 40px
}

.gg-close::after,
.gg-close::before {
   content: "";
   display: block;
   box-sizing: border-box;
   position: absolute;
   width: 16px;
   height: 2px;
   background: currentColor;
   transform: rotate(45deg);
   border-radius: 5px;
   top: 8px;
   left: 1px
}

.gg-close::after {
   transform: rotate(-45deg)
}

.gg-close-o {
   box-sizing: border-box;
   position: relative;
   display: block;
   transform: scale(var(--ggs, 1));
   width: 22px;
   height: 22px;
   border: 2px solid;
   border-radius: 40px
}

.gg-close-o::after,
.gg-close-o::before {
   content: "";
   display: block;
   box-sizing: border-box;
   position: absolute;
   width: 12px;
   height: 2px;
   background: currentColor;
   transform: rotate(45deg);
   border-radius: 5px;
   top: 8px;
   left: 3px
}

.gg-close-o::after {
   transform: rotate(-45deg)
}

.gg-close-r {
   box-sizing: border-box;
   position: relative;
   display: block;
   transform: scale(var(--ggs, 1));
   width: 22px;
   height: 22px;
   border: 2px solid;
   border-radius: 4px
}

.gg-close-r::after,
.gg-close-r::before {
   content: "";
   display: block;
   box-sizing: border-box;
   position: absolute;
   width: 12px;
   height: 2px;
   background: currentColor;
   transform: rotate(45deg);
   border-radius: 5px;
   top: 8px;
   left: 3px
}

.gg-close-r::after {
   transform: rotate(-45deg)
}

@media screen and (max-width:576px) {
   .row-card-no-pd [class*=col-] .card:before {
      width: calc(100% - 30px) !important;
      right: 15px !important;
      height: 1px !important
   }

   .row-card-no-pd [class*=col-]:first-child .card:before {
      display: none !important
   }
}

@media screen and (min-width:992px) {
   .main-header .main-header-logo {
      display: none
   }

   .main-panel>.container.container-full {
      padding: 0 !important
   }

   .main-panel.full-height>.container,
   .main-panel.full-height>.container-full {
      margin-top: 0
   }

   .main-panel.full-height .navbar-header {
      min-height: 62px
   }

   .logo-header {
      line-height: 57px
   }

   .nav-toggle .sidenav-toggler {
      display: none
   }

   .nav-toggle .toggle-sidebar {
      display: inline-block
   }

   .toggle-nav-search {
      display: none
   }

   #search-nav {
      display: block !important
   }

   .sidebar .scroll-element {
      opacity: 0;
      transition: all .2s
   }

   .sidebar:hover .scroll-element {
      opacity: 1
   }

   .sidebar[data-background-color]:before {
      background: rgba(255, 255, 255, .2) !important;
      z-index: 1000
   }
}

@media screen and (max-width:991.5px) {

   .container,
   .container-full {
      max-width: 100% !important;
      padding-left: 15px !important;
      padding-right: 15px !important
   }

   .main-header[data-background-color] .navbar-header {
      border-top: 1px solid rgba(0, 0, 0, .1)
   }

   .sidebar {
      position: fixed;
      left: 0 !important;
      right: 0;
      -webkit-transform: translate3d(-270px, 0, 0);
      -moz-transform: translate3d(-270px, 0, 0);
      -o-transform: translate3d(-270px, 0, 0);
      -ms-transform: translate3d(-270px, 0, 0);
      transform: translate3d(-270px, 0, 0) !important;
      transition: all .5s;
      margin-top: 0
   }

   .sidebar:before {
      background: 0 0
   }

   .sidebar .sidebar-logo {
      display: none
   }

   .sidebar .scrollbar-inner>.scroll-element.scroll-y {
      width: 4px
   }

   .sidebar .scrollbar-inner>.scroll-element.scroll-x {
      height: 4px
   }

   .nav_open .sidebar {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0) !important
   }

   .sidebar .sidebar-wrapper {
      padding-top: 0
   }

   .sidebar .sidebar-wrapper .sidebar-content {
      padding-top: 0 !important;
      padding-bottom: 60px !important
   }

   .sidebar .sidebar-wrapper .scroll-element.scroll-y {
      top: 0 !important
   }

   .nav_open {
      overflow: hidden !important
   }

   .nav_open .wrapper {
      overflow-x: hidden
   }

   .nav_open .main-panel {
      -webkit-transform: translate3d(265px, 0, 0);
      -moz-transform: translate3d(265px, 0, 0);
      -o-transform: translate3d(265px, 0, 0);
      -ms-transform: translate3d(265px, 0, 0);
      transform: translate3d(265px, 0, 0) !important
   }

   .quick_sidebar_open .quick-sidebar {
      max-width: 350px;
      width: 100%
   }

   .main-header {
      width: 100% !important;
      transition: all .5s
   }

   .main-header .main-header-logo {
      display: block
   }

   .dropdown-search {
      min-width: unset;
      max-width: 350px !important
   }

   #search-nav {
      margin: 0 auto !important
   }

   .main-panel {
      width: 100%;
      transition: all .5s
   }

   .main-panel>.container {
      transition: all .5s
   }

   .main-panel .page-header .dropdown-menu:after {
      right: 16px !important
   }

   .page-inner {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto
   }

   .page-sidebar {
      background: #fff
   }

   .logo-header {
      display: flex;
      width: 100% !important;
      text-align: left;
      position: relative;
      padding-left: 15px;
      padding-right: 15px
   }

   .logo-header .logo {
      position: absolute;
      left: 50%;
      transform: translateX(-50%)
   }

   .logo-header .navbar-toggler {
      height: 100%;
      margin-left: 0 !important;
      opacity: 1;
      display: block;
      order: 1
   }

   .logo-header .more {
      opacity: 1;
      color: #545454;
      cursor: pointer;
      display: inline-block;
      line-height: 56px;
      order: 3;
      width: unset;
      margin-left: auto
   }

   .logo-header .navbar-brand {
      position: unset !important;
      margin-right: 0
   }

   .topbar_open .logo-header {
      border-bottom: 2px solid rgba(255, 255, 255, .1)
   }

   .nav-search {
      width: 100%;
      margin-right: 0 !important
   }

   .navbar-header {
      position: absolute;
      width: 100%;
      transform: translate3d(0, -200px, 0) !important;
      transition: all .5s
   }

   .topbar_open .navbar-header {
      transform: translate3d(0, 70px, 0) !important;
      background: #fff
   }

   .topbar_open .navbar-header .navbar-nav>.nav-item .nav-link i {
      font-size: 16px
   }

   .topbar_open .navbar-header .navbar-nav>.nav-item:last-child .nav-link {
      padding: 0 !important
   }

   .topbar_open .navbar-header .navbar-nav>.nav-item:last-child .quick-sidebar-toggler {
      padding-left: 5px !important
   }

   .topbar_open .toggle-nav-search {
      display: list-item
   }

   .topbar_open #search-nav {
      text-align: center;
      width: 100%;
      padding: 10px 15px 0;
      order: 1
   }

   .topbar_open .main-panel>.container {
      margin-top: 123px
   }

   .topbar_open>.content {
      margin-top: 0 !important
   }

   .nav_open.topbar_open .main-panel {
      transform: translate3d(265px, 0, 0) !important
   }

   .navbar-header .navbar-nav {
      width: 100%;
      flex-direction: row;
      justify-content: center;
      margin-left: 0 !important;
      position: relative
   }

   .navbar-header .navbar-nav .dropdown {
      position: unset
   }

   .navbar-header .navbar-nav .dropdown-menu {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin: 0 auto;
      max-width: calc(100vw - 48px)
   }

   .profile-pic span {
      display: none
   }

   .nav-toggle {
      left: 15px !important;
      right: unset !important
   }

   .nav-toggle .sidenav-toggler {
      display: inline-block
   }

   .nav-toggle .toggle-sidebar {
      display: none
   }

   .page-title {
      font-size: 18px
   }

   .card .card-title {
      font-size: 18px
   }

   .mail-wrapper .mail-option .email-filters-left {
      width: 50%
   }

   .mail-wrapper .mail-option .email-filters-left .btn-group {
      margin-bottom: 10px
   }

   .dropzone {
      padding: 20px 15px !important
   }

   .dropzone .dz-message .message {
      font-size: 23px
   }

   .dropzone .dz-message .note {
      font-size: 15px
   }
}

@media screen and (min-width:856px) {
   .mail-wrapper .aside-nav {
      display: block !important
   }
}

@media screen and (max-width:856px) {
   .mail-wrapper {
      flex-direction: column
   }

   .mail-wrapper .page-aside {
      width: 100%;
      height: unset;
      min-height: unset;
      border-bottom: 1px solid #eee;
      border-left: 0;
      border-right: 0;
      background: 0 0;
      padding-top: 0;
      padding-bottom: 0
   }

   .mail-wrapper .page-aside .aside-header {
      padding-top: 25px;
      padding-bottom: 25px;
      background: #f1f1f1
   }

   .mail-wrapper .page-aside .aside-nav {
      background: #fff;
      padding-top: 15px;
      padding-bottom: 15px
   }

   .mail-wrapper .mail-content {
      width: 100%
   }

   .mail-wrapper .mail-content .inbox-head {
      flex-direction: column;
      align-items: left
   }

   .mail-wrapper .mail-content .inbox-head h3 {
      font-size: 18px
   }

   .mail-wrapper .mail-content .inbox-head form {
      margin-left: 0 !important;
      margin-top: 15px
   }

   .mail-wrapper .mail-content .email-head h3 {
      font-size: 18px
   }

   .mail-wrapper .mail-content .email-compose-fields {
      padding: 20px 15px
   }

   .mail-wrapper .mail-option {
      flex-direction: column
   }

   .mail-wrapper .mail-option .email-filters-left {
      width: 100%;
      margin-bottom: 10px
   }

   .mail-wrapper .toggle-email-nav {
      display: inline-block !important
   }

   .mail-wrapper .table-inbox tr td .badge {
      margin-top: 5px;
      float: left
   }
}

@media screen and (max-width:767px) {
   .wizard-container {
      margin-left: 15px;
      margin-right: 15px
   }

   .main-panel .page-header {
      flex-direction: column;
      align-items: normal;
      position: relative;
      min-height: 43px;
      justify-content: center
   }

   .main-panel .page-header .breadcrumbs {
      margin-left: 0;
      padding-top: 15px;
      padding-left: 5px;
      padding-bottom: 0;
      border-left: 0
   }

   .main-panel .page-header .btn-group-page-header {
      position: absolute;
      right: 0
   }

   .footer>.container,
   .footer>.container-fluid {
      flex-direction: column
   }

   .footer>.container .copyright,
   .footer>.container-fluid .copyright {
      margin-left: 0 !important;
      margin-top: 10px;
      margin-bottom: 15px
   }
}

@media screen and (max-width:576px) {

   .container,
   .container-full {
      padding: 0 !important
   }

   .main-panel>.container,
   .main-panel>.container-full {
      padding: 0 !important
   }

   #chart-container {
      min-height: 250px
   }

   .form-check-inline {
      display: flex;
      flex-direction: column;
      align-items: left
   }

   #calendar .fc-toolbar {
      display: flex;
      flex-direction: column
   }

   #calendar .fc-toolbar .fc-center,
   #calendar .fc-toolbar .fc-left,
   #calendar .fc-toolbar .fc-right {
      margin: auto;
      margin-bottom: 15px
   }

   #calendar .fc-toolbar .fc-left {
      order: 1
   }

   #calendar .fc-toolbar .fc-right {
      order: 3
   }

   #calendar .fc-toolbar .fc-center {
      order: 2
   }

   .conversations .conversations-body {
      padding: 1.5rem 1rem
   }
}

@media screen and (max-width:350px) {
   .quick_sidebar_open .quick-sidebar {
      width: 100%;
      padding: 20px
   }
}

body {
   background: #f5f7fd
}

body[data-background-color=bg1] {
   background: #f5f7fd
}

body[data-background-color=bg2] {
   background: #fff
}

body[data-background-color=bg3] {
   background: #f1f1f1
}

body[data-background-color=dark] {
   background: #1a2035
}

body[data-background-color=dark2] {
   background: #151a2b
}

body[data-background-color=dark2] .text-muted,
body[data-background-color=dark] .text-muted {
   color: #b9babf !important
}

body[data-background-color=dark2] .main-header,
body[data-background-color=dark] .main-header {
   box-shadow: 0 0 5px #121727
}

body[data-background-color=dark2] .main-panel,
body[data-background-color=dark] .main-panel {
   color: rgba(169, 175, 187, .82)
}

body[data-background-color=dark2] .main-panel .card:not(.card-primary, .card-secondary, .card-info, .card-success, .card-warning, .card-danger) .card-body,
body[data-background-color=dark2] .main-panel input[type=file],
body[data-background-color=dark2] .main-panel p,
body[data-background-color=dark] .main-panel .card:not(.card-primary, .card-secondary, .card-info, .card-success, .card-warning, .card-danger) .card-body,
body[data-background-color=dark] .main-panel input[type=file],
body[data-background-color=dark] .main-panel p {
   color: rgba(169, 175, 187, .82)
}

body[data-background-color=dark2] .main-panel .badge-count,
body[data-background-color=dark2] .main-panel .h1,
body[data-background-color=dark2] .main-panel .h2,
body[data-background-color=dark2] .main-panel .h3,
body[data-background-color=dark2] .main-panel .h4,
body[data-background-color=dark2] .main-panel .h5,
body[data-background-color=dark2] .main-panel .h6,
body[data-background-color=dark2] .main-panel h1,
body[data-background-color=dark2] .main-panel h2,
body[data-background-color=dark2] .main-panel h3,
body[data-background-color=dark2] .main-panel h4,
body[data-background-color=dark2] .main-panel h5,
body[data-background-color=dark2] .main-panel h6,
body[data-background-color=dark2] .main-panel label,
body[data-background-color=dark] .main-panel .badge-count,
body[data-background-color=dark] .main-panel .h1,
body[data-background-color=dark] .main-panel .h2,
body[data-background-color=dark] .main-panel .h3,
body[data-background-color=dark] .main-panel .h4,
body[data-background-color=dark] .main-panel .h5,
body[data-background-color=dark] .main-panel .h6,
body[data-background-color=dark] .main-panel h1,
body[data-background-color=dark] .main-panel h2,
body[data-background-color=dark] .main-panel h3,
body[data-background-color=dark] .main-panel h4,
body[data-background-color=dark] .main-panel h5,
body[data-background-color=dark] .main-panel h6,
body[data-background-color=dark] .main-panel label {
   color: #fff !important
}

body[data-background-color=dark2] .card:not(.card-primary, .card-secondary, .card-info, .card-success, .card-warning, .card-danger, .card-list-messages),
body[data-background-color=dark2] .list-group-item.unread,
body[data-background-color=dark2] .row-card-no-pd,
body[data-background-color=dark2] .timeline>li>.timeline-panel,
body[data-background-color=dark] .card:not(.card-primary, .card-secondary, .card-info, .card-success, .card-warning, .card-danger, .card-list-messages),
body[data-background-color=dark] .list-group-item.unread,
body[data-background-color=dark] .row-card-no-pd,
body[data-background-color=dark] .timeline>li>.timeline-panel {
   background: #202940 !important
}

body[data-background-color=dark2] .card-list-messages,
body[data-background-color=dark] .card-list-messages {
   background: 0 0 !important
}

body[data-background-color=dark2] .card-pricing2,
body[data-background-color=dark] .card-pricing2 {
   background: #202940 !important
}

body[data-background-color=dark2] .row-card-no-pd [class*=col] .card:before,
body[data-background-color=dark] .row-card-no-pd [class*=col] .card:before {
   background: rgba(181, 181, 181, .1) !important
}

body[data-background-color=dark2] .breadcrumbs,
body[data-background-color=dark2] .card .card-action,
body[data-background-color=dark2] .card .card-footer,
body[data-background-color=dark2] .card .card-header,
body[data-background-color=dark2] .card-profile .user-stats [class^=col],
body[data-background-color=dark2] .conversations .messages-form,
body[data-background-color=dark2] .list-group .list-group-item,
body[data-background-color=dark2] .mail-wrapper .mail-content .email-head,
body[data-background-color=dark2] .mail-wrapper .mail-content .email-sender,
body[data-background-color=dark2] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item,
body[data-background-color=dark2] .main-panel .page-divider,
body[data-background-color=dark2] .page-with-aside .page-aside,
body[data-background-color=dark2] .separator-dashed,
body[data-background-color=dark2] .separator-dot,
body[data-background-color=dark2] .separator-solid,
body[data-background-color=dark2] .table td,
body[data-background-color=dark2] .table th,
body[data-background-color=dark2] .timeline>li>.timeline-panel,
body[data-background-color=dark] .breadcrumbs,
body[data-background-color=dark] .card .card-action,
body[data-background-color=dark] .card .card-footer,
body[data-background-color=dark] .card .card-header,
body[data-background-color=dark] .card-profile .user-stats [class^=col],
body[data-background-color=dark] .conversations .messages-form,
body[data-background-color=dark] .list-group .list-group-item,
body[data-background-color=dark] .mail-wrapper .mail-content .email-head,
body[data-background-color=dark] .mail-wrapper .mail-content .email-sender,
body[data-background-color=dark] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item,
body[data-background-color=dark] .main-panel .page-divider,
body[data-background-color=dark] .page-with-aside .page-aside,
body[data-background-color=dark] .separator-dashed,
body[data-background-color=dark] .separator-dot,
body[data-background-color=dark] .separator-solid,
body[data-background-color=dark] .table td,
body[data-background-color=dark] .table th,
body[data-background-color=dark] .timeline>li>.timeline-panel {
   border-color: rgba(181, 181, 181, .1) !important;
   color: rgba(169, 175, 187, .82);
   background: 0 0
}

body[data-background-color=dark2] .card-danger i,
body[data-background-color=dark2] .card-danger input[type=file],
body[data-background-color=dark2] .card-danger p,
body[data-background-color=dark2] .card-info i,
body[data-background-color=dark2] .card-info input[type=file],
body[data-background-color=dark2] .card-info p,
body[data-background-color=dark2] .card-primary i,
body[data-background-color=dark2] .card-primary input[type=file],
body[data-background-color=dark2] .card-primary p,
body[data-background-color=dark2] .card-secondary i,
body[data-background-color=dark2] .card-secondary input[type=file],
body[data-background-color=dark2] .card-secondary p,
body[data-background-color=dark2] .card-success i,
body[data-background-color=dark2] .card-success input[type=file],
body[data-background-color=dark2] .card-success p,
body[data-background-color=dark2] .card-warning i,
body[data-background-color=dark2] .card-warning input[type=file],
body[data-background-color=dark2] .card-warning p,
body[data-background-color=dark] .card-danger i,
body[data-background-color=dark] .card-danger input[type=file],
body[data-background-color=dark] .card-danger p,
body[data-background-color=dark] .card-info i,
body[data-background-color=dark] .card-info input[type=file],
body[data-background-color=dark] .card-info p,
body[data-background-color=dark] .card-primary i,
body[data-background-color=dark] .card-primary input[type=file],
body[data-background-color=dark] .card-primary p,
body[data-background-color=dark] .card-secondary i,
body[data-background-color=dark] .card-secondary input[type=file],
body[data-background-color=dark] .card-secondary p,
body[data-background-color=dark] .card-success i,
body[data-background-color=dark] .card-success input[type=file],
body[data-background-color=dark] .card-success p,
body[data-background-color=dark] .card-warning i,
body[data-background-color=dark] .card-warning input[type=file],
body[data-background-color=dark] .card-warning p {
   color: #fff
}

body[data-background-color=dark2] .card-danger .h1,
body[data-background-color=dark2] .card-danger .h2,
body[data-background-color=dark2] .card-danger .h3,
body[data-background-color=dark2] .card-danger .h4,
body[data-background-color=dark2] .card-danger .h5,
body[data-background-color=dark2] .card-danger .h6,
body[data-background-color=dark2] .card-danger h1,
body[data-background-color=dark2] .card-danger h2,
body[data-background-color=dark2] .card-danger h3,
body[data-background-color=dark2] .card-danger h4,
body[data-background-color=dark2] .card-danger h5,
body[data-background-color=dark2] .card-danger h6,
body[data-background-color=dark2] .card-info .h1,
body[data-background-color=dark2] .card-info .h2,
body[data-background-color=dark2] .card-info .h3,
body[data-background-color=dark2] .card-info .h4,
body[data-background-color=dark2] .card-info .h5,
body[data-background-color=dark2] .card-info .h6,
body[data-background-color=dark2] .card-info h1,
body[data-background-color=dark2] .card-info h2,
body[data-background-color=dark2] .card-info h3,
body[data-background-color=dark2] .card-info h4,
body[data-background-color=dark2] .card-info h5,
body[data-background-color=dark2] .card-info h6,
body[data-background-color=dark2] .card-primary .h1,
body[data-background-color=dark2] .card-primary .h2,
body[data-background-color=dark2] .card-primary .h3,
body[data-background-color=dark2] .card-primary .h4,
body[data-background-color=dark2] .card-primary .h5,
body[data-background-color=dark2] .card-primary .h6,
body[data-background-color=dark2] .card-primary h1,
body[data-background-color=dark2] .card-primary h2,
body[data-background-color=dark2] .card-primary h3,
body[data-background-color=dark2] .card-primary h4,
body[data-background-color=dark2] .card-primary h5,
body[data-background-color=dark2] .card-primary h6,
body[data-background-color=dark2] .card-secondary .h1,
body[data-background-color=dark2] .card-secondary .h2,
body[data-background-color=dark2] .card-secondary .h3,
body[data-background-color=dark2] .card-secondary .h4,
body[data-background-color=dark2] .card-secondary .h5,
body[data-background-color=dark2] .card-secondary .h6,
body[data-background-color=dark2] .card-secondary h1,
body[data-background-color=dark2] .card-secondary h2,
body[data-background-color=dark2] .card-secondary h3,
body[data-background-color=dark2] .card-secondary h4,
body[data-background-color=dark2] .card-secondary h5,
body[data-background-color=dark2] .card-secondary h6,
body[data-background-color=dark2] .card-success .h1,
body[data-background-color=dark2] .card-success .h2,
body[data-background-color=dark2] .card-success .h3,
body[data-background-color=dark2] .card-success .h4,
body[data-background-color=dark2] .card-success .h5,
body[data-background-color=dark2] .card-success .h6,
body[data-background-color=dark2] .card-success h1,
body[data-background-color=dark2] .card-success h2,
body[data-background-color=dark2] .card-success h3,
body[data-background-color=dark2] .card-success h4,
body[data-background-color=dark2] .card-success h5,
body[data-background-color=dark2] .card-success h6,
body[data-background-color=dark2] .card-warning .h1,
body[data-background-color=dark2] .card-warning .h2,
body[data-background-color=dark2] .card-warning .h3,
body[data-background-color=dark2] .card-warning .h4,
body[data-background-color=dark2] .card-warning .h5,
body[data-background-color=dark2] .card-warning .h6,
body[data-background-color=dark2] .card-warning h1,
body[data-background-color=dark2] .card-warning h2,
body[data-background-color=dark2] .card-warning h3,
body[data-background-color=dark2] .card-warning h4,
body[data-background-color=dark2] .card-warning h5,
body[data-background-color=dark2] .card-warning h6,
body[data-background-color=dark] .card-danger .h1,
body[data-background-color=dark] .card-danger .h2,
body[data-background-color=dark] .card-danger .h3,
body[data-background-color=dark] .card-danger .h4,
body[data-background-color=dark] .card-danger .h5,
body[data-background-color=dark] .card-danger .h6,
body[data-background-color=dark] .card-danger h1,
body[data-background-color=dark] .card-danger h2,
body[data-background-color=dark] .card-danger h3,
body[data-background-color=dark] .card-danger h4,
body[data-background-color=dark] .card-danger h5,
body[data-background-color=dark] .card-danger h6,
body[data-background-color=dark] .card-info .h1,
body[data-background-color=dark] .card-info .h2,
body[data-background-color=dark] .card-info .h3,
body[data-background-color=dark] .card-info .h4,
body[data-background-color=dark] .card-info .h5,
body[data-background-color=dark] .card-info .h6,
body[data-background-color=dark] .card-info h1,
body[data-background-color=dark] .card-info h2,
body[data-background-color=dark] .card-info h3,
body[data-background-color=dark] .card-info h4,
body[data-background-color=dark] .card-info h5,
body[data-background-color=dark] .card-info h6,
body[data-background-color=dark] .card-primary .h1,
body[data-background-color=dark] .card-primary .h2,
body[data-background-color=dark] .card-primary .h3,
body[data-background-color=dark] .card-primary .h4,
body[data-background-color=dark] .card-primary .h5,
body[data-background-color=dark] .card-primary .h6,
body[data-background-color=dark] .card-primary h1,
body[data-background-color=dark] .card-primary h2,
body[data-background-color=dark] .card-primary h3,
body[data-background-color=dark] .card-primary h4,
body[data-background-color=dark] .card-primary h5,
body[data-background-color=dark] .card-primary h6,
body[data-background-color=dark] .card-secondary .h1,
body[data-background-color=dark] .card-secondary .h2,
body[data-background-color=dark] .card-secondary .h3,
body[data-background-color=dark] .card-secondary .h4,
body[data-background-color=dark] .card-secondary .h5,
body[data-background-color=dark] .card-secondary .h6,
body[data-background-color=dark] .card-secondary h1,
body[data-background-color=dark] .card-secondary h2,
body[data-background-color=dark] .card-secondary h3,
body[data-background-color=dark] .card-secondary h4,
body[data-background-color=dark] .card-secondary h5,
body[data-background-color=dark] .card-secondary h6,
body[data-background-color=dark] .card-success .h1,
body[data-background-color=dark] .card-success .h2,
body[data-background-color=dark] .card-success .h3,
body[data-background-color=dark] .card-success .h4,
body[data-background-color=dark] .card-success .h5,
body[data-background-color=dark] .card-success .h6,
body[data-background-color=dark] .card-success h1,
body[data-background-color=dark] .card-success h2,
body[data-background-color=dark] .card-success h3,
body[data-background-color=dark] .card-success h4,
body[data-background-color=dark] .card-success h5,
body[data-background-color=dark] .card-success h6,
body[data-background-color=dark] .card-warning .h1,
body[data-background-color=dark] .card-warning .h2,
body[data-background-color=dark] .card-warning .h3,
body[data-background-color=dark] .card-warning .h4,
body[data-background-color=dark] .card-warning .h5,
body[data-background-color=dark] .card-warning .h6,
body[data-background-color=dark] .card-warning h1,
body[data-background-color=dark] .card-warning h2,
body[data-background-color=dark] .card-warning h3,
body[data-background-color=dark] .card-warning h4,
body[data-background-color=dark] .card-warning h5,
body[data-background-color=dark] .card-warning h6 {
   color: #fff !important
}

body[data-background-color=dark2] .nav-line,
body[data-background-color=dark] .nav-line {
   border-color: rgba(181, 181, 181, .1) !important
}

body[data-background-color=dark2] .accordion .card,
body[data-background-color=dark] .accordion .card {
   background: #1a2035 !important
}

body[data-background-color=dark2] .accordion .card .card-body,
body[data-background-color=dark] .accordion .card .card-body {
   border-color: rgba(181, 181, 181, .1) !important
}

body[data-background-color=dark2] .timeline>li>.timeline-panel:before,
body[data-background-color=dark] .timeline>li>.timeline-panel:before {
   border-left-color: rgba(181, 181, 181, .1);
   border-right-color: rgba(181, 181, 181, .1)
}

body[data-background-color=dark2] .timeline>li>.timeline-panel:after,
body[data-background-color=dark] .timeline>li>.timeline-panel:after {
   border-left-color: #202940;
   border-right-color: #202940
}

body[data-background-color=dark2] .activity-feed .feed-item,
body[data-background-color=dark] .activity-feed .feed-item {
   border-color: rgba(181, 181, 181, .1) !important
}

body[data-background-color=dark2] .progress,
body[data-background-color=dark2] .progress-stacked,
body[data-background-color=dark2] .timeline:before,
body[data-background-color=dark] .progress,
body[data-background-color=dark] .progress-stacked,
body[data-background-color=dark] .timeline:before {
   background-color: rgba(181, 181, 181, .1) !important
}

body[data-background-color=dark2] .breadcrumbs li a,
body[data-background-color=dark2] .page-title,
body[data-background-color=dark] .breadcrumbs li a,
body[data-background-color=dark] .page-title {
   color: rgba(169, 175, 187, .82)
}

body[data-background-color=dark2] .page-category,
body[data-background-color=dark] .page-category {
   color: #828282
}

body[data-background-color=dark2] .card-title,
body[data-background-color=dark2] .card-title a,
body[data-background-color=dark2] .card-title a:focus,
body[data-background-color=dark2] .card-title a:hover,
body[data-background-color=dark] .card-title,
body[data-background-color=dark] .card-title a,
body[data-background-color=dark] .card-title a:focus,
body[data-background-color=dark] .card-title a:hover {
   color: #fff
}

body[data-background-color=dark2] .card-category,
body[data-background-color=dark] .card-category {
   color: #8b92a9
}

body[data-background-color=dark2] .card-black,
body[data-background-color=dark2] .card-danger,
body[data-background-color=dark2] .card-info,
body[data-background-color=dark2] .card-primary,
body[data-background-color=dark2] .card-secondary,
body[data-background-color=dark2] .card-success,
body[data-background-color=dark2] .card-warning,
body[data-background-color=dark] .card-black,
body[data-background-color=dark] .card-danger,
body[data-background-color=dark] .card-info,
body[data-background-color=dark] .card-primary,
body[data-background-color=dark] .card-secondary,
body[data-background-color=dark] .card-success,
body[data-background-color=dark] .card-warning {
   color: #fff !important
}

body[data-background-color=dark2] .card-black .card-category,
body[data-background-color=dark2] .card-black .card-title,
body[data-background-color=dark2] .card-danger .card-category,
body[data-background-color=dark2] .card-danger .card-title,
body[data-background-color=dark2] .card-info .card-category,
body[data-background-color=dark2] .card-info .card-title,
body[data-background-color=dark2] .card-primary .card-category,
body[data-background-color=dark2] .card-primary .card-title,
body[data-background-color=dark2] .card-secondary .card-category,
body[data-background-color=dark2] .card-secondary .card-title,
body[data-background-color=dark2] .card-success .card-category,
body[data-background-color=dark2] .card-success .card-title,
body[data-background-color=dark2] .card-warning .card-category,
body[data-background-color=dark2] .card-warning .card-title,
body[data-background-color=dark] .card-black .card-category,
body[data-background-color=dark] .card-black .card-title,
body[data-background-color=dark] .card-danger .card-category,
body[data-background-color=dark] .card-danger .card-title,
body[data-background-color=dark] .card-info .card-category,
body[data-background-color=dark] .card-info .card-title,
body[data-background-color=dark] .card-primary .card-category,
body[data-background-color=dark] .card-primary .card-title,
body[data-background-color=dark] .card-secondary .card-category,
body[data-background-color=dark] .card-secondary .card-title,
body[data-background-color=dark] .card-success .card-category,
body[data-background-color=dark] .card-success .card-title,
body[data-background-color=dark] .card-warning .card-category,
body[data-background-color=dark] .card-warning .card-title {
   color: #fff !important
}

body[data-background-color=dark2] .nav-pills .nav-link:not(.active),
body[data-background-color=dark] .nav-pills .nav-link:not(.active) {
   background: #1a2035;
   color: #a0aaac;
   border-color: #20263d
}

body[data-background-color=dark2] .card-pricing .specification-list li,
body[data-background-color=dark] .card-pricing .specification-list li {
   border-color: #373d4c
}

body[data-background-color=dark2] .input-group-text,
body[data-background-color=dark] .input-group-text {
   border-color: #2f374b !important;
   background-color: #1f283e;
   color: #fff
}

body[data-background-color=dark2] .input-solid,
body[data-background-color=dark] .input-solid {
   background: #363b4c !important;
   border-color: #363b4c !important
}

body[data-background-color=dark2] .table,
body[data-background-color=dark] .table {
   background-color: transparent
}

body[data-background-color=dark2] .list-group .list-group-item-text,
body[data-background-color=dark2] .list-group-messages .list-group-item-title a,
body[data-background-color=dark] .list-group .list-group-item-text,
body[data-background-color=dark] .list-group-messages .list-group-item-title a {
   color: inherit
}

body[data-background-color=dark2] .footer,
body[data-background-color=dark] .footer {
   border-top: 1px solid #293247;
   background: #1f283e
}

body[data-background-color=dark2] .form-control,
body[data-background-color=dark2] .form-group-default,
body[data-background-color=dark2] .form-select,
body[data-background-color=dark2] .select2-container--bootstrap .select2-selection,
body[data-background-color=dark] .form-control,
body[data-background-color=dark] .form-group-default,
body[data-background-color=dark] .form-select,
body[data-background-color=dark] .select2-container--bootstrap .select2-selection {
   background-color: #1a2035;
   color: #fff;
   border-color: #2f374b
}

body[data-background-color=dark2] select option,
body[data-background-color=dark] select option {
   background: #1a2035
}

body[data-background-color=dark2] .bootstrap-tagsinput,
body[data-background-color=dark] .bootstrap-tagsinput {
   background: 0 0
}

body[data-background-color=dark2] .selectgroup-button,
body[data-background-color=dark] .selectgroup-button {
   border: 1px solid #2f374b
}

body[data-background-color=dark2] .conversations .message-header,
body[data-background-color=dark] .conversations .message-header {
   background: #1a2035;
   box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .07)
}

body[data-background-color=dark2] .conversations .conversations-content,
body[data-background-color=dark] .conversations .conversations-content {
   color: #575962;
   border-color: #2e364a
}

body[data-background-color=dark2] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread,
body[data-background-color=dark] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread {
   background: #151a2b !important
}

body[data-background-color=dark2] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item:hover,
body[data-background-color=dark] .mail-wrapper .mail-content .inbox-body .email-list .email-list-item:hover {
   background: #171e2f !important
}

body[data-background-color=dark2] .page-with-aside .page-aside .aside-nav .nav>li.active,
body[data-background-color=dark2] .page-with-aside .page-aside .aside-nav .nav>li:focus,
body[data-background-color=dark2] .page-with-aside .page-aside .aside-nav .nav>li:hover,
body[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav>li.active,
body[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav>li:focus,
body[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav>li:hover {
   background: rgba(0, 0, 0, .03)
}

body[data-background-color=dark2] .page-with-aside .page-aside .aside-nav .nav>li.active>a,
body[data-background-color=dark] .page-with-aside .page-aside .aside-nav .nav>li.active>a {
   color: #b9babf !important
}

body[data-background-color=dark2] .board,
body[data-background-color=dark] .board {
   color: #575962
}

body[data-background-color=dark2] .dropzone:hover,
body[data-background-color=dark] .dropzone:hover {
   background: #1a2035 !important
}

body[data-background-color=dark2] .dropzone .dz-preview.dz-image-preview,
body[data-background-color=dark] .dropzone .dz-preview.dz-image-preview {
   background: 0 0 !important
}

body[data-background-color=dark2] .jvm-zoom-btn,
body[data-background-color=dark] .jvm-zoom-btn {
   background: #fff;
   color: #292929
}

.bg-transparent {
   background: 0 0 !important
}

.bg-black {
   background-color: #1a2035 !important
}

.bg-black2 {
   background-color: #151a2b !important
}

.bg-primary {
   background-color: #0033CC !important
}

.bg-primary2 {
   background-color: #1269db !important
}

.bg-secondary {
   background-color: #6861ce !important
}

.bg-secondary2 {
   background-color: #5c55bf !important
}

.bg-info {
   background-color: #48abf7 !important
}

.bg-info2 {
   background-color: #3697e1 !important
}

.bg-success {
   background-color: #196c1b !important
}

.bg-success2 {
   background-color: #2bb930 !important
}

.bg-warning {
   background-color: #ffad46 !important
}

.bg-warning2 {
   background-color: #ff9e27 !important
}

.bg-danger {
   background-color: #f25961 !important
}

.bg-danger2 {
   background-color: #ea4d56 !important
}

.bg-gray1 {
   background: #f5f7fd !important
}

.bg-gray2 {
   background: #f1f1f1 !important
}

.bg-black-gradient {
   background: #151a2b !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #0a0b11, #151a2b) !important;
   background: linear-gradient(-45deg, #0a0b11, #151a2b) !important
}

.bg-primary-gradient {
   background: #0033CC !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #06418e, #0033CC) !important;
   background: linear-gradient(-45deg, #06418e, #0033CC) !important
}

.bg-secondary-gradient {
   background: #6861ce !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #2a20ac, #6861ce) !important;
   background: linear-gradient(-45deg, #2a20ac, #6861ce) !important
}

.bg-info-gradient {
   background: #48abf7 !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #0a5a97, #48abf7) !important;
   background: linear-gradient(-45deg, #0a5a97, #48abf7) !important
}

.bg-success-gradient {
   background: #196c1b !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #179d08, #196c1b) !important;
   background: linear-gradient(-45deg, #179d08, #196c1b) !important
}

.bg-warning-gradient {
   background: #ffad46 !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #e1810b, #ffad46) !important;
   background: linear-gradient(-45deg, #e1810b, #ffad46) !important
}

.bg-danger-gradient {
   background: #f25961 !important;
   background: -webkit-linear-gradient(legacy-direction(-45deg), #e80a15, #f25961) !important;
   background: linear-gradient(-45deg, #e80a15, #f25961) !important
}

.mail-wrapper .toggle-email-nav {
   margin-top: 10px;
   display: none
}

.mail-wrapper .mail-content .email-head,
.mail-wrapper .mail-content .inbox-head {
   padding: 35px 25px 20px
}

.mail-wrapper .mail-content .email-head h3,
.mail-wrapper .mail-content .inbox-head h3 {
   font-size: 22px;
   font-weight: 300;
   margin: 0
}

.mail-wrapper .mail-content .email-head {
   padding: 35px 25px;
   border-bottom: 1px solid #f1f1f1
}

.mail-wrapper .mail-content .email-head .favorite {
   color: #eee;
   margin-right: 5px
}

.mail-wrapper .mail-content .email-head .favorite.active {
   color: #ffc600
}

.mail-wrapper .mail-content .email-head .controls {
   margin-left: auto
}

.mail-wrapper .mail-content .email-head .controls>a {
   color: #9c9c9c;
   font-size: 18px;
   padding: 0 5px
}

.mail-wrapper .mail-content .email-head .controls>a:hover {
   text-decoration: none;
   opacity: .8
}

.mail-wrapper .mail-content .email-head .controls>a:last-child {
   padding-right: 0
}

.mail-wrapper .mail-content .email-sender {
   padding: 14px 25px;
   display: flex;
   align-items: center;
   border-bottom: 1px solid #f1f1f1
}

.mail-wrapper .mail-content .email-sender .avatar {
   padding-right: 12px
}

.mail-wrapper .mail-content .email-sender .avatar img {
   max-width: 40px;
   max-height: 40px;
   border-radius: 50%
}

.mail-wrapper .mail-content .email-sender .date {
   margin-left: auto
}

.mail-wrapper .mail-content .email-sender .sender .action {
   display: inline-block
}

.mail-wrapper .mail-content .email-sender .sender .action>a {
   cursor: pointer
}

.mail-wrapper .mail-content .email-body {
   padding: 30px 28px
}

.mail-wrapper .mail-content .email-attachments {
   padding: 25px 28px;
   border-top: 1px solid #f1f1f1
}

.mail-wrapper .mail-content .email-attachments .title {
   font-weight: 400;
   margin-bottom: 10px
}

.mail-wrapper .mail-content .email-attachments .title span {
   font-weight: 400
}

.mail-wrapper .mail-content .email-attachments ul {
   padding-left: 0;
   list-style: none
}

.mail-wrapper .mail-content .email-attachments ul li {
   padding: 6px 0
}

.mail-wrapper .mail-content .email-attachments ul li a {
   font-weight: 400
}

.mail-wrapper .mail-content .email-attachments ul li a:hover {
   text-decoration: none
}

.mail-wrapper .mail-content .email-attachments ul li a i {
   font-size: 20px;
   display: inline-block;
   vertical-align: middle
}

.mail-wrapper .mail-content .email-attachments ul li a span {
   font-weight: 400
}

.mail-wrapper .mail-content .inbox-body {
   padding: 20px 0
}

.mail-wrapper .mail-content .inbox-body .mail-option {
   padding: 0 20px;
   margin-bottom: 20px;
   display: flex
}

.mail-wrapper .mail-content .inbox-body .mail-option .chk-all {
   display: inline-block
}

.mail-wrapper .mail-content .inbox-body .mail-option .btn-option {
   color: #555 !important;
   border: 1px solid #ebedf2 !important;
   font-weight: 600;
   background: #fff !important;
   box-shadow: 2px 2px 3px 0 #f2f1f1 !important
}

.mail-wrapper .mail-content .inbox-body .mail-option .form-check {
   padding: 0
}

.mail-wrapper .mail-content .inbox-body .mail-option .form-check .form-check-sign:before {
   border: 1px solid #eee;
   background: #eee
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item {
   padding: 14px 20px;
   display: table;
   cursor: pointer;
   position: relative;
   font-size: 12px;
   width: 100%;
   border-top: 1px solid #f1f1f1
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item:hover {
   background: #f6f5f5
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions,
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail {
   vertical-align: top;
   display: table-cell
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions {
   width: 50px
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .custom-checkbox {
   margin-right: 0
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite {
   color: #eee;
   font-size: 18px
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite:hover {
   text-decoration: none;
   color: #969696
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite.active,
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-actions .favorite.active:hover {
   color: #ffc600
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .msg,
.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail p {
   font-size: 12px
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .msg {
   margin-bottom: 0;
   margin-top: 8px
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .from {
   font-size: 13px
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .date {
   font-size: 12px;
   display: flex;
   align-items: center
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item .email-list-detail .date .paperclip {
   font-size: 16px;
   padding-right: 4px
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread {
   font-weight: 400;
   background: #fbfbfb
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread:after {
   content: '';
   display: block;
   position: absolute;
   width: 3px;
   background: #0033CC;
   top: -1px;
   left: 0;
   bottom: -1px;
   height: calc(100% + 2px)
}

.mail-wrapper .mail-content .inbox-body .email-list .email-list-item.unread .email-list-detail .from {
   font-weight: 600
}

.mail-wrapper .mail-content .email-compose-fields,
.mail-wrapper .mail-content .email-editor {
   padding: 20px 25px
}

.mail-wrapper .mail-content .email-compose-fields {
   padding: 20px 25px;
   border-bottom: 1px solid #f1f1f1
}

.mail-wrapper .mail-content .email-action {
   text-align: right;
   margin-bottom: 15px
}

.mail-wrapper .mail-content .email-action>.btn {
   margin-right: 7px
}

.mail-wrapper .mail-content .email-action>.btn:last-child {
   margin-right: 0
}

.login {
   background: #efefee
}

.login .wrapper.wrapper-login {
   display: flex;
   justify-content: center;
   align-items: center;
   height: unset;
   padding: 15px
}

.login .wrapper.wrapper-login .container-login,
.login .wrapper.wrapper-login .container-signup {
   width: 400px;
   padding: 60px 22px;
   border-radius: 5px
}

.login .wrapper.wrapper-login .container-login:not(.container-transparent),
.login .wrapper.wrapper-login .container-signup:not(.container-transparent) {
   background: #fff;
   -webkit-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
   -moz-box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
   box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03);
   border: 1px solid #ebecec
}

.login .wrapper.wrapper-login .container-login h3,
.login .wrapper.wrapper-login .container-signup h3 {
   font-size: 20px;
   font-weight: 600;
   margin-bottom: 24px
}

.login .wrapper.wrapper-login .container-login .form-sub,
.login .wrapper.wrapper-login .container-signup .form-sub {
   align-items: center;
   justify-content: space-between;
   padding: 8px 10px
}

.login .wrapper.wrapper-login .container-login .btn-login,
.login .wrapper.wrapper-login .container-signup .btn-login {
   padding: 15px 0;
   min-width: 135px
}

.login .wrapper.wrapper-login .container-login .form-action,
.login .wrapper.wrapper-login .container-signup .form-action {
   text-align: center;
   padding: 25px 10px 0
}

.login .wrapper.wrapper-login .container-login .form-action-d-flex,
.login .wrapper.wrapper-login .container-signup .form-action-d-flex {
   display: flex;
   align-items: center;
   justify-content: space-between
}

.login .wrapper.wrapper-login .container-login .login-account,
.login .wrapper.wrapper-login .container-signup .login-account {
   padding-top: 16px;
   font-size: 14px;
   text-align: center
}

.login .wrapper.wrapper-login .container-signup .form-action {
   display: flex;
   justify-content: space-between
}

.login .wrapper.wrapper-login-full {
   justify-content: unset;
   align-items: unset;
   padding: 0 !important
}

.login .login-aside {
   padding: 25px
}

.login .login-aside .title {
   font-size: 36px
}

.login .login-aside .subtitle {
   font-size: 18px
}

.login .show-password {
   position: absolute;
   right: 20px;
   top: 50%;
   transform: translateY(-50%);
   font-size: 20px;
   cursor: pointer
}

.login .custom-control-label {
   white-space: nowrap
}

@media screen and (max-width:576px) {
   .form-action-d-flex {
      flex-direction: column;
      align-items: start !important
   }

   .login .wrapper-login-full {
      flex-direction: column
   }

   .login .login-aside {
      width: 100% !important
   }

   .login .login-aside .title {
      font-size: 24px
   }

   .login .login-aside .subtitle {
      font-size: 16px
   }
}

@media screen and (max-width:399px) {
   .wrapper-login {
      padding: 15px !important
   }

   .container-login {
      width: 100% !important;
      padding: 60px 15px !important
   }
}

.page-not-found {
   background-image: url(../img/bg-404.jpeg);
   background-size: cover;
   background-position: center;
   image-rendering: pixelated
}

.page-not-found .wrapper.not-found {
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   color: #fff;
   background: rgba(0, 0, 0, .61)
}

.page-not-found .wrapper.not-found h1 {
   font-size: 100px;
   letter-spacing: .15em;
   font-weight: 600;
   animation-delay: .5s
}

.page-not-found .wrapper.not-found .desc {
   font-size: 27px;
   text-align: center;
   line-height: 50px;
   animation-delay: 1.5s;
   letter-spacing: 2px
}

.page-not-found .wrapper.not-found .desc span {
   font-weight: 600;
   font-size: 30px
}

.page-not-found .wrapper.not-found .btn-back-home {
   border-radius: 50px;
   padding: 13px 25px;
   animation-delay: 2.5s
}

@media screen and (max-width:576px) {
   .wrapper.not-found h1 {
      font-size: 65px !important
   }

   .wrapper.not-found .desc {
      font-size: 18px !important
   }
}